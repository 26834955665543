import PhotoCheckIcon from "@mui/icons-material/ImageAspectRatio";


import { PhotoCheckClass } from "../types";
import { DataProvider, GetManyReferenceParams } from "react-admin";
import { ParseDataProvider } from "src/data-provider";
import PhotoCheckList from "./PhotoCheckList";
import PhotoCheckEdit from "./PhotoCheckEdit";

const resource = {
  list: PhotoCheckList,
  edit: PhotoCheckEdit,
  icon: PhotoCheckIcon,
};

export const photoCheckLifecycleCallbacks = {
  resource: "PhotoCheck",

  afterRead: async (record: any, dataProvider: ParseDataProvider) => {

    return {
      ...record,
      author: record.author?.id,
    };
  },

  beforeSave: async (data: any, dataProvider: ParseDataProvider) => {
    if (Array.isArray(data.file) && data.file.length > 1) {
      const files = [...data.file];
      const firstFile = files.shift();

      const promises = files.map(async (file: any) => {
        const uploadedFile = await dataProvider.uploadFile(file.rawFile);
        return await dataProvider.create("PhotoCheck", {
          data: { ...data, file: uploadedFile },
        });
      });

      await Promise.all(promises);
      const uploadedFile = await dataProvider.uploadFile(firstFile.rawFile);
      return { ...data, file: uploadedFile };
    } else {
      if (data?.file[0]?.rawFile instanceof File) {
        const uploadedFile = await dataProvider.uploadFile(
          data.file[0].rawFile
        );
        return { ...data, file: uploadedFile };
      } else {
        delete data.file;
        return { ...data };
      }
    }
  },
};

export default resource;
