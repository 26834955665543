import CarIcon from "@mui/icons-material/CarRental";

import CarTypeList from "./CarTypeList";
import CarTypeEdit from "./CarTypeEdit";
import CarTypeCreate from "./CarTypeCreate";
import { ParsePointer, CarType } from "../types";
import { DataProvider, GetManyParams, GetManyReferenceParams, GetOneParams } from "react-admin";
import { ParseDataProvider } from "../data-provider";

const resource = {
  list: CarTypeList,
  edit: CarTypeEdit,
  create: CarTypeCreate,
  icon: CarIcon,
  recordRepresentation: (record: CarType) =>
        `${record.name}`,
};

export const carTypesLifecycleCallbacks = {
  resource: "CarType",

   afterRead: async (record: CarType, dataProvider: ParseDataProvider) => {
    return {
      ...record,
      author: record.author?.id,
    };
  },

  beforeSave: async (data: any, dataProvider: ParseDataProvider) => {
    return data;
  },

};

export default resource;