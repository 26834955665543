import {
  AutocompleteInput,
  DateTimeInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { transactionDirectionChoises } from "../shared/select";
import { TransactionType } from "src/types";

export const TransactionEdit = () => {
  const translate = useTranslate();
  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm>
        <DateTimeInput source="date" validate={[required()]} />
        <ReferenceInput source="type" reference="TransactionType">
          <AutocompleteInput
            optionText="name"
            validate={required()}
            fullWidth
            groupBy={(option: TransactionType) =>
              translate(`custom.transactionDirection.${option.direction}`)
            }
          />
        </ReferenceInput>
        <ReferenceInput source="account" reference="TransactionAccount">
          <SelectInput optionText="name" validate={required()} fullWidth />
        </ReferenceInput>
        <NumberInput
          source="amount"
          validate={[required()]}
          fullWidth
          min={1}
          max={10000000}
        />
        <TextInput source="description" fullWidth />
      </SimpleForm>
    </Edit>
  );
};

export default TransactionEdit;
