import * as React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import {
  Edit,
  SimpleForm,
  TextInput,
  Title,
  required,
  useAuthState,
  useEditController,
  TextField,
  SaveContextProvider,
  useDataProvider,
  useNotify,
  useGetIdentity,
  useTranslate,
  PasswordInput,
  email,
  ImageInput,
  ImageField,
  Error,
  useAuthenticated,
  SelectArrayInput,
} from "react-admin";

import { ParseDataProvider } from "../data-provider";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import { userNotificationTypeChoises } from "../shared/select";

type IProfileContextType = {
  profileVersion: number;
  refreshProfile: () => void;
};

const ProfileContext = createContext<IProfileContextType | null>(null);

export const ProfileProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [profileVersion, setProfileVersion] = useState(0);
  const context = useMemo(
    () => ({
      profileVersion,
      refreshProfile: () =>
        setProfileVersion((currentVersion) => currentVersion + 1),
    }),
    [profileVersion]
  );

  return (
    <ProfileContext.Provider value={context}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () =>
  useContext(ProfileContext) as IProfileContextType;

export const validateForm = (
  values: Record<string, any>
): Record<string, any> => {
  const errors = {} as any;
  if (!values.firstname) {
    errors.firstname = "ra.validation.required";
  }
  if (!values.lastname) {
    errors.lastname = "ra.validation.required";
  }
  if (!values.email) {
    errors.email = "ra.validation.required";
  } else {
    const error = email()(values.email);
    if (error) {
      errors.email = error;
    }
  }
  if (values.password && values.password !== values.passwordConfirm) {
    errors.passwordConfirm = "resources._User.errors.password_mismatch";
  }
  return errors;
};

const Profile = ({ ...props }) => {
  useAuthenticated();
  const dataProvider = useDataProvider<ParseDataProvider>();
  const notify = useNotify();
  const [saving, setSaving] = useState<boolean>();
  const { isLoading, data, error, refetch } = useGetIdentity();
  const { refreshProfile } = useProfile();
  const translate = useTranslate();
  useDefineAppLocation("Profile");
  const handleSave = useCallback(
    (values: any) => {
      setSaving(true);
      dataProvider
        .updateProfile(values)
        .then(({ data }) => {
          setSaving(false);
          notify("resources.Profile.messages.profile_updated_successful", {
            type: "success",
            undoable: true,
          });
          if (refetch) {
            refetch();
          }
          refreshProfile();
        })
        .catch((e) => {
          setSaving(false);
          notify(
            e.message ?? "resources.Profile.messages.profile_update_error",
            { type: "error", undoable: true }
          );
        });
    },
    [dataProvider, notify, refreshProfile, refetch]
  );

  const saveContext = useMemo(
    () => ({
      save: handleSave,
      saving,
    }),
    [saving, handleSave]
  );

  if (!data) {
    return null;
  }

  return (
    <>
      <Card sx={{ mt: 1 }}>
        <SaveContextProvider value={saveContext}>
          <SimpleForm
            onSubmit={handleSave}
            record={data ? data : {}}
            validate={validateForm}
          >
            <Grid container width={{ xs: "100%" }} spacing={2}>
              <Grid item xs={12} md={8}>
                <Typography variant="h6" gutterBottom>
                  {translate("resources.Profile.fieldGroups.identity")}
                </Typography>

                <TextInput
                  source="username"
                  disabled
                  label="resources.Profile.fields.username"
                  fullWidth
                />
                <Box display={{ xs: "block", sm: "flex" }}>
                  <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                      source="firstname"
                      validate={required()}
                      label="resources.Profile.fields.lastname"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                    <TextInput
                      source="lastname"
                      validate={required()}
                      label="resources.Profile.fields.firstname"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1}>
                    <TextInput
                      source="email"
                      validate={required()}
                      label="resources.Profile.fields.email"
                      fullWidth
                    />
                  </Box>
                </Box>

                <Box mt="1em" />

                <Typography variant="h6" gutterBottom>
                  {translate("resources.Profile.fieldGroups.password")}
                </Typography>
                <Box display={{ xs: "block", sm: "flex" }}>
                  <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                    <PasswordInput
                      source="password"
                      label="resources.Profile.fields.password"
                      autoComplete="new-password"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1}>
                    <PasswordInput
                      source="passwordConfirm"
                      label="resources.Profile.fields.passwordConfirm"
                      autoComplete="new-password"
                      fullWidth
                    />
                  </Box>
                </Box>
                <Typography variant="h6" gutterBottom>
                  {translate("resources.Profile.fieldGroups.notifications")}
                </Typography>
                <Box display={{ xs: "block", sm: "flex" }}>
                  <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                    <SelectArrayInput
                      fullWidth
                      source="notificationsOptions"
                      label="resources.Profile.fields.notificationsOptions"
                      choices={userNotificationTypeChoises}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" gutterBottom>
                  {translate("resources.Profile.fieldGroups.avatar")}
                </Typography>
                <ImageField
                  source="avatar"
                  sx={{
                    "& img": {
                      height: 250,
                      width: 250,
                      objectFit: "cover",
                    },
                  }}
                />
                <ImageInput source="avatar" maxSize={10000000}>
                  <ImageField source="avatar" title="title" />
                </ImageInput>
              </Grid>
            </Grid>
          </SimpleForm>
        </SaveContextProvider>
      </Card>
    </>
  );
};

export default Profile;
