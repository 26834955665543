import {
  AutocompleteInput,
  Button,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  DateTimeInput,
  DeleteButton,
  Edit,
  FormDataConsumer,
  List,
  ListButton,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SaveButton,
  SelectField,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  WithRecord,
  minValue,
  required,
  useRecordContext,
  useRedirect,
  useTranslate,
} from "react-admin";
import { Customer, OrderTypeEnum, TransactionType } from "../types";
import { Box, Grid, Typography } from "@mui/material";
import InfoICon from "@mui/icons-material/Info";
import { useState } from "react";
import { CustomerSearchInput } from "../shared/customer/CustomerSearchInput";
import { PaymentsEmbededList } from "../shared/payments/PaymentsEmbededList";
import { StartEndSourceInput } from "../shared/startEndSource/startEndSourceInput";
import { AuthorInput } from "../shared/AuthorInput";
import { AdditionalServicesInput } from "../shared/additionalServices/AdditionalServicesInput";
import { OrderSummary } from "./OrderSummary";
import { CarInput } from "../shared/CarInput";
import ThumbUp from "@mui/icons-material/ThumbUp";
import {
  CreateInDialogButton,
  EditInDialogButton,
} from "@react-admin/ra-form-layout/esm/src";
import { OrderCloseSummary } from "./OrderCloseSummary";
import { AuthorField } from "../shared/AuthorField";
import { RUBFormat } from "../shared/formatUtils";
import { CreateActButton } from "../act/CreateActButton";
import { actTypeChoises, orderTypeChoises } from "../shared/select";
import { TariffSelect } from "./TariffSelect";
import EditAside from "./EditAside";

const customerOptionRenderer = (choice: Customer) =>
  `${choice.fullname} (${choice.phone})`;

export const OrderEditToolbar = ({ record }: any) => {
  const translate = useTranslate();
  return (
    <Toolbar>
      <SaveButton sx={{ mr: 2 }} />
      <FormDataConsumer<{ type: string }>>
        {({ formData }) => {
          return (
            formData.type === OrderTypeEnum.ORDER && (
              <EditInDialogButton
                label="resources.Order.action.closeOrder"
                title={
                  translate("resources.Order.action.closeOrder") +
                  ` № ${record.numStr}`
                }
                fullWidth
                maxWidth="md"
                mutationMode="pessimistic"
                ButtonProps={{
                  color: "secondary",
                  variant: "contained",
                  size: "medium",
                  disabled: record.isClosed,
                  startIcon: <ThumbUp />,
                }}
              >
                <SimpleForm
                  toolbar={
                    <Toolbar sx={{ justifyContent: "space-between" }}>
                      <SaveButton label="resources.Order.action.closeOrder" />
                    </Toolbar>
                  }
                >
                  <DateTimeInput
                    source="closedAt"
                    fullWidth
                    validate={[required()]}
                  />
                  <Box width={{ xs: "100%" }}>
                    <OrderCloseSummary fullWidth />
                  </Box>
                </SimpleForm>
              </EditInDialogButton>
            )
          );
        }}
      </FormDataConsumer>

      <Box sx={{ ml: "auto" }}></Box>
      {!record.isClosed && <DeleteButton />}
    </Toolbar>
  );
};

export const OrderEdit = (props: any) => {
  const redirect = useRedirect();
  const [showAside, setShowAside] = useState(false);
  const translate = useTranslate();

  const handleShowInfo = () => {
    setShowAside(!showAside);
  };

  return (
    <Edit
      mutationMode="pessimistic"
      aside={showAside ? <EditAside /> : undefined}
    >
      <WithRecord
        render={(record) => {
          return (
            <TabbedForm toolbar={<OrderEditToolbar record={record} />}>
              <TabbedForm.Tab label="resources.Order.tabs.identity" >
                <Grid container width={{ xs: "100%" }} spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h6" gutterBottom>
                      {translate("resources.Order.fieldGroups.identity")}
                    </Typography>
                    <SelectInput
                      source="type"
                      choices={orderTypeChoises}
                      validate={required()}
                      readOnly={record.isClosed}
                    />
                    <StartEndSourceInput
                      checkEndDate={false}
                      readOnly={record.isClosed}
                    />
                    <Box display={{ xs: "block", sm: "flex" }}>
                      <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
                        <CustomerSearchInput readOnly={record.isClosed} />
                      </Box>
                      <Box sx={{ paddingY: 2 }}>
                        <FormDataConsumer<{ customer: string }>>
                          {({ formData, ...rest }) => {
                            return (
                              formData.customer && (
                                <Button
                                  onClick={handleShowInfo}
                                  color="primary"
                                  // disabled={loading}
                                  fullWidth
                                  size="large"
                                  startIcon={<InfoICon />}
                                />
                              )
                            );
                          }}
                        </FormDataConsumer>
                      </Box>
                    </Box>

                    <Box display={{ xs: "block", sm: "flex" }}>
                      <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
                        <CarInput
                          source="car"
                          isRequired
                          readOnly={record.isClosed}
                        />
                      </Box>
                      <Box flex={1}>
                        <FormDataConsumer<{ car: string }>>
                          {({ formData, ...rest }) => {
                            return (
                              formData.car && (
                                <TariffSelect
                                  car={formData.car}
                                  readOnly={record.isClosed}
                                />
                              )
                            );
                          }}
                        </FormDataConsumer>
                      </Box>
                    </Box>

                    <Box display={{ xs: "block", sm: "flex" }}>
                      <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
                        <TextInput
                          source="deliveryAddress"
                          fullWidth
                          readOnly={record.isClosed}
                        />
                      </Box>
                      <Box flex={1}>
                        <NumberInput
                          source="delivery"
                          fullWidth
                          defaultValue={0}
                          min={0}
                          validate={[minValue(0), required()]}
                          readOnly={record.isClosed}
                        />
                      </Box>
                    </Box>
                    <AdditionalServicesInput readOnly={record.isClosed} />
                    <OrderSummary checkEndDate={false} />
                    <Box mt="1em" />
                    <TextInput
                      source="description"
                      fullWidth
                      multiline
                      readOnly={record.isClosed}
                    />
                    <AuthorInput
                      isRequired={true}
                      source="manager"
                      readOnly={record.isClosed}
                    />
                  </Grid>
                </Grid>
                <Box mt="4em" />
              </TabbedForm.Tab>
              <TabbedForm.Tab label="resources.Order.tabs.payments">
                <Grid container width={{ xs: "100%" }} spacing={2}>
                  <Grid item xs={12} md={12}>
                    <PaymentsEmbededList type={"order"} />
                  </Grid>
                </Grid>
              </TabbedForm.Tab>
              <TabbedForm.Tab label="resources.Order.tabs.acts">
                <Grid container width={{ xs: "100%" }} spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h6" gutterBottom>
                      {translate("resources.Order.fieldGroups.acts")}
                    </Typography>
                    <CreateActButton />
                    <List
                      filter={{ orderId: record.id }}
                      exporter={false}
                      resource="Act"
                      hasCreate={false}
                      title={" "}
                    >
                      <Datagrid bulkActionButtons={false} rowClick={"edit"}>
                        <TextField source="numStr" />
                        <DateField source="date" showTime />
                        <SelectField source="type" choices={actTypeChoises} />
                        <NumberField source="mileage" />
                        <AuthorField source="author" />
                      </Datagrid>
                    </List>
                  </Grid>
                </Grid>
              </TabbedForm.Tab>
            </TabbedForm>
          );
        }}
      />
    </Edit>
  );
};

export default OrderEdit;
