import DocIcon from "@mui/icons-material/DocumentScanner";

import DocList from "./DocList";
import DocEdit from "./DocEdit";
import { DocClass } from "../types";
import { ParseDataProvider } from "src/data-provider";

const resource = {
  list: DocList,
  edit: DocEdit,
  icon: DocIcon,
};

export const documentssLifecycleCallbacks = {
  resource: "Doc",

  afterRead: async (record: any, dataProvider: ParseDataProvider) => {

    return {
      ...record,
      author: record.author?.id,
    };
  },

  beforeSave: async (data: any, dataProvider: ParseDataProvider) => {
    if (data.file.rawFile) {
      const uploadedFile = await dataProvider.uploadFile(data.file.rawFile);
      return { ...data, file: uploadedFile };
    } else {
      return data;
    }
    
  },
};

export default resource;
