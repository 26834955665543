import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import {
  BooleanInput,
  ChipField,
  ComponentPropType,
  DeleteButton,
  ImageField,
  Link,
  RaRecord,
  SaveButton,
  SimpleForm,
  SingleFieldList,
  Toolbar,
  useCreatePath,
  useListContext,
  useResourceContext,
} from "react-admin";
import { ImageClass } from "../types";
import { ComponentType, HtmlHTMLAttributes } from "react";
import PropTypes from "prop-types";
import { EditInDialogButton } from "@react-admin/ra-form-layout";
import ImagePoster from "../image/ImagePoster";

export const CarImagesField = (props: CarImagesFieldProps) => {
  const { data, isLoading } = useListContext(props);
  const resource = useResourceContext(props);
  const createPath = useCreatePath();
  const theme = useTheme();

  if (isLoading) return null;
  return (
    <ImageList
      // rowHeight={100}
      cols={2}
      sx={{ m: 0 }}
      variant="quilted"
      gap={parseInt(theme.spacing(1))}
    >
      {data.map((record) => (
        <ImageListItem
          key={record.id}
          cols={record.main ? 2 : 1}
          rows={record.main ? 2 : 1}
          // component={Link}
          // to={createPath({
          //   resource: "Image",
          //   id: record.id,
          //   type: "edit",
          // })}
        >
          <img
            src={record.file.url()}
            alt={record.file.name()}
            loading="lazy"
          />

          <ImageListItemBar
            title={
              <EditInDialogButton
                fullScreen
                id={record.id}
                mutationMode="optimistic"
                ButtonProps={{
                  size: "small",
                  variant: "contained",
                  color: "primary",
                  sx: { mt: 1, p: 1 },
                }}
              >
                <SimpleForm
                  toolbar={
                    <Toolbar sx={{ justifyContent: "space-between" }}>
                      <SaveButton />
                      <DeleteButton redirect={false} />
                    </Toolbar>
                  }
                >
                  <ImagePoster />
                  <BooleanInput
                    source="main"
                    label={"resources.Image.fields.main"}
                  />
                </SimpleForm>
              </EditInDialogButton>
            }
            sx={{
              background:
                "linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)",
            }}
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

CarImagesField.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  component: ComponentPropType,
  data: PropTypes.any,
  ids: PropTypes.array,
  // @ts-ignore
  linkType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  resource: PropTypes.string,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

interface CarImagesFieldProps<RecordType extends RaRecord = any>
  extends HtmlHTMLAttributes<HTMLDivElement> {
  className?: string;
  component?: string | ComponentType<any>;
  linkType?: string | false;
  // can be injected when using the component without context
  data?: RecordType[];
  total?: number;
  loaded?: boolean;
  sx?: SxProps;
}
