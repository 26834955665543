import { CreateButton, TopToolbar } from "react-admin";
import { AddMaintenanceButton } from "./AddMaintenanceButton";

export const MaintenanceListActions = (props: any) => {
  return (
    <TopToolbar>
      <AddMaintenanceButton/>
    </TopToolbar>
  );
};
