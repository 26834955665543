import { Grid, Typography, Box } from "@mui/material";
import {
  ArrayInput,
  Create,
  DateInput,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  required,
  useCreatePath,
  useRedirect,
  useTranslate,
} from "react-admin";
import { CreateEdit } from "./createEdit";
import { CarEquipment } from "../types";
import { useDefineAppLocation } from "@react-admin/ra-navigation";
import { useLocation } from "react-router-dom";

export const CarEquipmentCreate = (props: any) => {
  const redirect = useRedirect();

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const onSuccess = (data: CarEquipment) => {
    if (query.get("car_id")) {
      redirect(`/PartnerCar/${query.get("car_id")}/4`);
    }
  };

  return (
    <Create mutationOptions={{ onSuccess }}>
      <CreateEdit edit={false} defaultValues={{ car: query.get("car_id") }} />
    </Create>
  );
};

export default CarEquipmentCreate;
