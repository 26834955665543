import { AutocompleteInput, ReferenceInput, required } from "react-admin";
import { customerOptionRenderer } from "./utils";
import { CreateNewCustomer } from "./CreateCustomer";

export const CustomerSearchInput = (props: any) => {
  return (
    <ReferenceInput
      source="customer"
      reference="Customer"
      sort={{ field: "lastname", order: "ASC" }}
      {...props}
    >
      <AutocompleteInput
        optionText={customerOptionRenderer}
        optionValue="id"
        validate={required()}
        filterToQuery={(searchText: string) => ({
          searchString: searchText,
        })}
        create={<CreateNewCustomer />}
        {...props}
      />
    </ReferenceInput>
  );
};
