import { Grid, Box } from "@mui/material";
import {
  Create,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import { CreateEdit } from "./CreateEdit";

export const AdditionalServiceCreate = () => (
  <Create redirect="list">
    <CreateEdit edit={false} />
  </Create>
);
