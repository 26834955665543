import {
  ReferenceInput,
  AutocompleteInput,
  useTranslate,
  ReferenceInputProps,
  required,
} from "react-admin";
import { customerOptionRenderer } from "./customer/utils";

type CusomerInputProps = Omit<ReferenceInputProps, "reference"> & {
  isRequired?: boolean;
  source: string;
};

export const CustomerReferenceInput = (props: CusomerInputProps) => {
  const { isRequired, ...rest } = props;
  return (
    <ReferenceInput {...rest} reference="Customer">
      <AutocompleteInput
        optionText={customerOptionRenderer}
        optionValue="id"
        validate={isRequired ? required() : undefined}
        filterToQuery={(searchText: string) => ({
          searchString: searchText,
        })}
        sx={{ minWidth: 400 }}
      />
    </ReferenceInput>
  );
};
