import * as React from "react";
import { Card, CardContent } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import InsuranceIcon from "@mui/icons-material/AccountBalance";
import {
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  SavedQueriesList,
} from "react-admin";
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
  endOfToday,
  startOfToday,
  add,
  sub,
  startOfDay,
} from "date-fns";

const Aside = () => (
  <Card
    sx={{
      display: {
        xs: "none",
        md: "block",
      },
      order: -1,
      flex: "0 0 20em",
      mr: 2,
      mt: 6,
      alignSelf: "flex-start",
    }}
  >
    <CardContent sx={{ pt: 1 }}>
      <FilterLiveSearch source="name" />
      <SavedQueriesList />
      <FilterList
        label="resources.PartnerCar.filters.insurance_date"
        icon={<AccessTimeIcon />}
      >
        <FilterListItem
          label="resources.PartnerCar.filters.osago_out"
          value={{
            osagoDate_gte: undefined,
            osagoDate_lte: startOfToday().toISOString(),
            kaskoDate_gte: undefined,
            kaskoDate_lte: undefined,
          }}
        />
        <FilterListItem
          label="resources.PartnerCar.filters.osago_will_out"
          value={{
            osagoDate_gte: startOfToday().toISOString(),
            osagoDate_lte: startOfDay(
              add(new Date(), { weeks: 1 })
            ).toISOString(),
            kaskoDate_gte: undefined,
            kaskoDate_lte: undefined,
          }}
        />
        <FilterListItem
          label="resources.PartnerCar.filters.kasko_out"
          value={{
            osagoDate_gte: undefined,
            osagoDate_lte: undefined,
            kaskoDate_gte: undefined,
            kaskoDate_lte: startOfToday().toISOString(),
          }}
        />

        <FilterListItem
          label="resources.PartnerCar.filters.kasko_will_out"
          value={{
            osagoDate_gte: undefined,
            osagoDate_lte: undefined,
            kaskoDate_gte: startOfToday().toISOString(),
            kaskoDate_lte: startOfDay(
              add(new Date(), { weeks: 1 })
            ).toISOString(),
          }}
        />
      </FilterList>

      <FilterList
        label="resources.PartnerCar.filters.has_osago"
        icon={<InsuranceIcon />}
      >
        <FilterListItem
          label="ra.boolean.true"
          value={{ osagoDate_has: true }}
        />
        <FilterListItem
          label="ra.boolean.false"
          value={{ osagoDate_has: false }}
        />
      </FilterList>

      <FilterList
        label="resources.PartnerCar.filters.has_kasko"
        icon={<InsuranceIcon />}
      >
        <FilterListItem
          label="ra.boolean.true"
          value={{ kaskoDate_has: true }}
        />
        <FilterListItem
          label="ra.boolean.false"
          value={{ kaskoDate_has: false }}
        />
      </FilterList>
    </CardContent>
  </Card>
);

export default Aside;
