import * as React from "react";
import MoneyIcon from "@mui/icons-material/TrendingUp";
import { useTranslate } from "react-admin";
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  subMonths,
  endOfToday,
  startOfToday,
  add,
  sub,
  startOfDay,
} from "date-fns";
import CardWithIcon from "./CardWithIcon";
import { formatNumberAsRUB } from "../shared/formatUtils";
import querystring from "query-string";
import { TransactionDirectionEnum } from "../types";

export const todayTransactionFilter = querystring.stringify({
  filter: JSON.stringify({
    date_gte: startOfToday().toISOString(),
    direction: TransactionDirectionEnum.INCOME,
  }),
});

interface Props {
  value?: number;
}

const TodayIncome = (props: Props) => {
  const { value } = props;
  const translate = useTranslate();
  const today = startOfToday().toISOString();
  return (
    <CardWithIcon
      to={`/Transaction?${todayTransactionFilter}`}
      icon={MoneyIcon}
      title={translate("pos.dashboard.today_income")}
      subtitle={!!value ? formatNumberAsRUB(value, 2) : "-"}
    />
  );
};

export default TodayIncome;
