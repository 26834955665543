import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  SelectField,
  TextField,
  useTranslate,
} from "react-admin";
import {
  AdditionalServiceRewardTypeEnum,
  AdditionalServiceTypeEnum,
  AdditionalService,
} from "../types";
import { additionalServiceRewardTypeChoises, additionalServiceTypeChoises } from "../shared/select";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import { AuthorField } from "../shared/AuthorField";
import { RUBFormat } from "../shared/formatUtils";

export const AdditionalserviceList = () => {
  const translate = useTranslate();
  useDefineAppLocation("sales.additional_service");
  return (
    <List exporter={false}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="name" />
        <SelectField source="type" choices={additionalServiceTypeChoises} />
        <NumberField
          source="price"
          options={RUBFormat()}
        />
        <SelectField
          source="rewardType"
          choices={additionalServiceRewardTypeChoises}
        />
        <FunctionField
          label={translate("resources.AdditionalService.fields.rewardAmount")}
          render={(record: AdditionalService) =>
            record.rewardType == AdditionalServiceRewardTypeEnum.FIX
              ? `${record.rewardAmount} ₽`
              : `${record.rewardAmount} %`
          }
        />
        <AuthorField source="author" />
      </Datagrid>
    </List>
  );
};
