import green from "@mui/material/colors/green";
import type { SxProps } from "@mui/material";
import orange from "@mui/material/colors/orange";
import red from "@mui/material/colors/red";
import yellow from "@mui/material/colors/yellow";
import { Identifier } from "react-admin";

import { CarStatusEnum, PartnerCar } from "./../types";

const rowSx =
  (selectedRow?: Identifier) =>
  (record: PartnerCar): SxProps => {
    let style = {};
    if (!record) {
      return style;
    }
    if (selectedRow && selectedRow === record.id) {
      style = {
        ...style,
        backgroundColor: "action.selected",
      };
    }
    if (record.currentState === CarStatusEnum.FREE)
      return {
        ...style,
        borderLeftColor: green[500],
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
      };
    if (record.currentState === CarStatusEnum.MAINTENANCE)
      return {
        ...style,
        borderLeftColor: orange[500],
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
      };
    if (record.currentState === CarStatusEnum.CHECK)
      return {
        ...style,
        borderLeftColor: yellow[500],
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
      };
    if (record.currentState === CarStatusEnum.RENT)
      return {
        ...style,
        borderLeftColor: red[500],
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
      };
    if (record.currentState === CarStatusEnum.BOOKED)
      return {
        ...style,
        borderLeftColor: red[700],
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
      };
    return style;
  };

export default rowSx;
