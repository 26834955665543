import * as React from "react";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { useCreatePath, useTranslate } from "react-admin";
import { Link } from "react-router-dom";
import OrderIcon from "@mui/icons-material/Handshake";
import CustomerIcon from "@mui/icons-material/PersonAdd";
const ActionPanel = (props: any) => {
  const translate = useTranslate();
  const createPath = useCreatePath();

  return (
    <Card sx={{ flex: 1 }}>
      <CardHeader title={translate("pos.dashboard.quick_actions")} />
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        <ListItem>
          <Button
            variant="contained"
            component={Link}
            fullWidth
            to={createPath({ resource: "Order", type: "create" })}
            startIcon={<OrderIcon />}
          >
            {translate("pos.dashboard.create_order")}
          </Button>
        </ListItem>
        <Divider />
        <ListItem>
          <Button
            variant="contained"
            component={Link}
            fullWidth
            to={createPath({ resource: "Customer", type: "create" })}
            startIcon={<CustomerIcon />}
          >
            {translate("pos.dashboard.create_customer")}
          </Button>
        </ListItem>
      </List>
    </Card>
  );
};

export default ActionPanel;
