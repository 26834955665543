import React, { SyntheticEvent, useState } from "react";
import {
  SimpleForm,
  TextInput,
  useCreateSuggestionContext,
  useTranslate,
} from "react-admin";
import { CreateDialog } from "@react-admin/ra-form-layout/esm/src";

export const CreateCarEquipmentItem = () => {
  const translate = useTranslate();
  const [open, setOpen] = useState(true);
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const onSuccess = (data: any) => {
    onCreate(data);
  };

  const handleClose = () => {
    setOpen(false);
    onCancel();
  };

  return (
    <CreateDialog
      resource="CarEquipmentItem"
      isOpen={open}
      close={handleClose}
      fullWidth
      mutationOptions={{ onSuccess }}
    >
      <SimpleForm defaultValues={{ name: filter }}>
        <TextInput
          source="name"
          label={translate("resources.CarEquipmentItem.fields.name")}
          fullWidth
        />
      </SimpleForm>
    </CreateDialog>
  );

};
