import { Button, useRecordContext, useTranslate } from "react-admin";
import { Link } from "react-router-dom";
import { PartnerCar } from "../types";
import AddIcon from "@mui/icons-material/Add";

export const AddEquipmentButton = (props: any) => {
  const record = useRecordContext<PartnerCar>();
  const tranlate = useTranslate();
  return (
    <Button
      to={`/CarEquipment/create?car_id=${record.id}&redirect=PartnerCar`}
      label={tranlate("ra.action.create")}
      component={Link}
      sx={{ marginY: 2 }}
    >
      <AddIcon />
    </Button>
  );
};
