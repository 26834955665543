import AdditionalServicesIcon from "@mui/icons-material/PlaylistAdd";

import { AdditionalService, ParsePointer } from "../types";
import {
  DataProvider,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetOneResult,
  RaRecord,
  ResourceCallbacks,
  UpdateParams,
} from "react-admin";
import { AdditionalserviceList } from "./AddServiceList";
import { AdditionalServiceEdit } from "./AddServiceEdit";
import { AdditionalServiceCreate } from "./AddServiceCreate";

const resource = {
  list: AdditionalserviceList,
  edit: AdditionalServiceEdit,
  create: AdditionalServiceCreate,
  icon: AdditionalServicesIcon,
  recordRepresentation: 'name',
};

export const additionalServicesLifecycleCallbacks = {
  resource: "AdditionalService",

  afterRead: async (record: AdditionalService, dataProvider: DataProvider) => {
    return {
      ...record,
      author: record.author?.id,
    };
  },

  beforeSave: async (data: any, dataProvider: DataProvider) => {
    return data;
  },


};

export default resource;
