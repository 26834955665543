import {
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  DateField,
  Filter,
  Identifier,
  List,
  NullableBooleanInput,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";

import {
  bookingSourceChoises,
  carColorsChoises,
  carStatusChoises,
  fuelTypeChoises,
  orderTypeChoises,
  transmissionTypeChoises,
  wheelDriveChoises,
} from "../shared/select";
import { SxProps } from "@mui/material";
import { green, orange } from "@mui/material/colors";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import { AuthorField } from "../shared/AuthorField";
import { RUBFormat } from "../shared/formatUtils";
import { CustomerReferenceInput } from "../shared/CustomerReferenceInput";
import { CarInput } from "../shared/CarInput";
import { AuthorInput } from "../shared/AuthorInput";
import { Order } from "../types";

const rowSx =
  (selectedRow?: Identifier) =>
  (record: Order): SxProps => {
    let style = {};
    if (!record) {
      return style;
    }
    if (selectedRow && selectedRow === record.id) {
      style = {
        ...style,
        backgroundColor: "action.selected",
      };
    }
    if (record.isClosed === true) {
      return {
        ...style,
        borderLeftColor: green[500],
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
      };
    } else {
      return {
        ...style,
        borderLeftColor: orange[500],
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
      };
    }

    return style;
  };

const BookinfFilters = (props: any) => (
  <Filter {...props}>
    <SelectInput source="source" choices={bookingSourceChoises} alwaysOn />
    <NullableBooleanInput source="isClosed" alwaysOn />
    <CustomerReferenceInput source="customer" alwaysOn/>
    <CarInput source="car" alwaysOn/>
    <AuthorInput source="author" alwaysOn/>
  </Filter>
);

export const OrderList = () => {
  useDefineAppLocation("sales.order");
  return (
    <List
      exporter={false}
      sort={{ field: "start", order: "DESC" }}
      filters={<BookinfFilters />}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false} rowSx={rowSx()}>
        <TextField source="numStr" />
        <SelectField source="type" choices={orderTypeChoises} />
        <SelectField source="source" choices={bookingSourceChoises} />
        <DateField source="start" />
        <DateField source="end" />
        <ReferenceField source="car" reference="PartnerCar">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="customer" reference="Customer">
          <TextField source="fullname" />
        </ReferenceField>
        <NumberField
          source="total"
          options={RUBFormat()}
        />
        <AuthorField source="author" />
      </Datagrid>
    </List>
  );
};

export default OrderList;
