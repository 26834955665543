import { Box, Grid } from "@mui/material";
import {
  BooleanInput,
  Create,
  DateInput,
  Edit,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

import { unitTypeChoises } from "../shared/select";
import { CreateEdit } from "./CreateEdit";

export const MaintenanceItemCreate = () => (
  <Create redirect="list">
    <CreateEdit edit={false} />
  </Create>
);
