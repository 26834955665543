import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import {
  Edit,
  SimpleForm,
  TextInput,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { CreateEdit } from "./createEdit";

export const CustomerSegmentEdit = () => {
  return (
    <Edit mutationMode="pessimistic">
      <CreateEdit edit={true} />
    </Edit>
  );
};

export default CustomerSegmentEdit;
