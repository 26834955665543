import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  DateField,
  Filter,
  FormDataConsumer,
  List,
  NullableBooleanInput,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  useTranslate,
} from "react-admin";
import { AuthorField } from "../shared/AuthorField";
import { maintenanceItemTypeChoises } from "../shared/select";

export const MaintenanceItemsFilter = ({ ...props }) => {
  return (
    <Filter {...props}>
      <SearchInput source="searchString" alwaysOn />
      <SelectInput
        source="type"
        choices={maintenanceItemTypeChoises}
        alwaysOn
      />
      <ReferenceInput
        alwaysOn
        source="brand"
        reference="AutoBrand"
        sort={{ field: "name", order: "ASC" }}
      >
        <AutocompleteInput
          optionText="name"
          optionValue="id"
          filterToQuery={(searchText: string) => ({ name: searchText })}
        />
      </ReferenceInput>
      <TextInput source="body" alwaysOn />
      <FormDataConsumer source="model" alwaysOn>
        {({ formData, ...restOfTheProps }) =>
          formData.brand && (
            <ReferenceInput
              key={formData.brand}
              source="model"
              alwaysOn
              reference="AutoModel"
              filter={{ brand: formData.brand }}
              sort={{ field: "name", order: "ASC" }}
            >
              <AutocompleteInput
                optionText="name"
                optionValue="id"
                filterToQuery={(searchText: string) => ({ name: searchText })}
              />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>
    </Filter>
  );
};

export const MaintenanceItemList = () => {
  useDefineAppLocation("maintenance.maintenance_item");
  return (
    <List exporter={false} filters={<MaintenanceItemsFilter />}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="name" />
        <SelectField source="type" choices={maintenanceItemTypeChoises} />
        <ReferenceField source="brand" reference="AutoBrand">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="model" reference="AutoModel">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="body" />
        <TextField source="sku" />
        <TextField source="manufacturer" />
        <AuthorField source="author" />
      </Datagrid>
    </List>
  );
};
