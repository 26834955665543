import React from 'react'
import ReactDOM from 'react-dom'
import App from './App.tsx'
import * as Sentry from "@sentry/react";
import Parse from "./parse/parse";

Sentry.init({
  dsn: "https://4937c8dffe0a53b6ee9c3b57c0f4d090@o4506156182667264.ingest.sentry.io/4506156184436736",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/crm.p-and-a\.ru/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Parse.initialize("panda-cars");
Parse.serverURL = import.meta.env.VITE_API_URL ?? "https://api.p-and-a.ru/v1";



ReactDOM.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  document.getElementById('root')
);
