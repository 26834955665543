import {
  AutocompleteArrayInput,
  ChipField,
  Datagrid,
  DateField,
  List,
  NullableBooleanInput,
  NumberField,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectArrayInput,
  SelectField,
  SelectInput,
  SingleFieldList,
  TextField,
  TextInput,
  UrlField,
} from "react-admin";

const filterToQuery = (searchText: string) => ({ name: searchText });

export const EquipmentList = () => (
  <List exporter={false} sort={{ field: "createdAt", order: "DESC" }}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <DateField source="date" />
      <ReferenceField source="car" reference="PartnerCar">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default EquipmentList;
