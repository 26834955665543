import { AuthProvider } from "react-admin";
import Parse from "../parse/parse";


const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const phone = username.replace(/\D/g, "");
    const user = await Parse.User.logIn(phone, password);
    return Promise.resolve(user);
  },
  logout: async () => {
    await Parse.User.logOut();
    return Promise.resolve();
  },
  checkError: async (error: Parse.Error) => {
    if (error.code == Parse.Error.INVALID_SESSION_TOKEN) {
      await Parse.User.logOut();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    const user = Parse.User.current();
    return user?.authenticated() ? Promise.resolve() : Promise.reject();
  },

  getPermissions: () => {
    const user = Parse.User.current();
    const authenticated = user?.authenticated();
    const roles = user?.get('roleNames');
    if (roles && roles.includes("master") && authenticated) {
      return Promise.resolve([{ action: "*", resource: "*" }]);
    } else if (roles && roles.includes("admin") && authenticated) {
      return Promise.resolve([{ action: "*", resource: "*" }]);
    } else if (roles && roles.includes("manager") && authenticated) {
      return Promise.resolve([{ action: "*", resource: "*" }]);
    } else if (roles && roles.includes("technician") && authenticated) {
      return Promise.resolve([{ action: "*", resource: "*" }]);
    } else if (roles && roles.includes("techdirector") && authenticated) {
      return Promise.resolve([{ action: "*", resource: "*" }]);
    } else {
      return Promise.resolve([{ type: 'deny', action: "*", resource: "*" }]);
    }

    throw new Error(Parse.Error.INVALID_ROLE_NAME.toString());
  },
  getIdentity: async () => {
    try {
      const user = Parse.User.current();
      const authenticated = user?.authenticated();
      if (!user || user?.authenticated() == false) {
        return Promise.reject();
      }
      await user.fetch();
      const avatar = user.get("avatar");
      const userData = {
        id: user.id,
        ...user.attributes,
        avatar: avatar ? avatar.url() : null,
      };
      return Promise.resolve(userData);
    } catch (error) {
      if (
        error instanceof Parse.Error &&
        error.code == Parse.Error.INVALID_SESSION_TOKEN
      ) {
        await Parse.User.logOut();
        return Promise.reject();
      }
      return Promise.reject(error);
    }
  },
};

export default authProvider;
