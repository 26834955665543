import { IconButton, Tooltip, useTheme } from "@mui/material";
import { useState } from "react";
import {
  Button,
  useDataProvider,
  useRecordContext,
  useRefresh,
  Confirm,
  useTranslate,
} from "react-admin";
import { useMutation } from "react-query";
import { ParseDataProvider } from "src/data-provider";
import DeleteIcon from "@mui/icons-material/Delete";

export const RemoveUserButton = (props: any) => {
  const record = useRecordContext();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const dataProvider = useDataProvider<ParseDataProvider>();
  const refresh = useRefresh();
  const { mutate, isLoading } = useMutation(() =>
    dataProvider
      .removeUserFromPartner({ userId: record.id })
      .then(() => refresh())
  );

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    mutate();
    setOpen(false);
  };

  const { inline, ButtonProps, ...editDialogProps } = props;

  return (
    <>
      <Button
        label={translate("ra.action.delete")}
        {...ButtonProps}
        color="error"
        onClick={() => handleClick()}
      >
        {<DeleteIcon />}
      </Button>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={translate("resources._User.actions.removeTitle")}
        content={translate("resources._User.actions.removeConfirm")}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        confirm="ra.action.delete"
      />
    </>
  );
};
