import { Grid, Box, Typography } from "@mui/material";
import {
  ArrayInput,
  AutocompleteInput,
  Create,
  DateTimeInput,
  FormDataConsumer,
  Loading,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  minValue,
  required,
  useGetList,
  useTranslate,
} from "react-admin";
import { actTypeChoises, equipmentConditionChoises } from "../shared/select";
import { CarInput } from "../shared/CarInput";
import { CustomerSearchInput } from "../shared/customer/CustomerSearchInput";
import { useLocation } from "react-router";
import { AuthorInput } from "../shared/AuthorInput";
import { CreateCarEquipmentItem } from "../equipment/CreateEquipmentItem";
import { ActTypeEnum, IAct } from "../types";

export const ActCreate = (props: any) => {
  const location = useLocation();
  const translate = useTranslate();
  const { orderId, car, customer } = location.state?.record ?? {};

  const { isLoading, data: previousActs } = useGetList<IAct>("Act", {
    filter: { orderId },
    sort: { field: "date", order: "DESC" },
    pagination: { page: 1, perPage: 3 },
  });

  const transferAct = previousActs?.find(
    (act) => act.type === ActTypeEnum.TRANSFER
  );
  const hasTransferAct =
    previousActs?.some((act) => act.type === ActTypeEnum.TRANSFER) ?? false;
  const hasFinalAct =
    previousActs?.some((act) => act.type === ActTypeEnum.FINAL) ?? false;
  const mileage = (previousActs && previousActs.length && previousActs[0].mileage) ?? 0;

  if (isLoading) return <Loading />;
  const equipment = transferAct?.equipment ?? [];
  console.log("data", equipment);
  console.log(location.state);
  const actDefaultValue = () =>
    location.state?.record
      ? { orderId, car, customer, equipment, mileage }
      : {};
  return (
    <Create {...props} >
      <TabbedForm defaultValues={actDefaultValue} syncWithLocation={false} >
        <TabbedForm.Tab label="resources.Act.tabs.identity">
          <Grid container width={{ xs: "100%", xl: "100%" }} spacing={2}>
            <Grid item xs={12} md={12}>
              <FormDataConsumer<{ type: string }>>
                {({ formData }) => {
                  if (!formData.type)
                    return (
                      <Typography variant="h6" gutterBottom>
                        {translate("resources.Act.name", { smart_count: 1 })}
                      </Typography>
                    );
                  return (
                    <Typography variant="h6" gutterBottom>
                      {translate("custom.actType." + formData.type)}
                    </Typography>
                  );
                }}
              </FormDataConsumer>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box mr={{ xs: 0, sm: "0.5em" }}>
                  <DateTimeInput
                    source="date"
                    fullWidth
                    validate={required()}
                    defaultValue={new Date()}
                  />
                </Box>
                <Box mr={{ xs: 0, sm: "0.5em" }}>
                  <SelectInput
                    source="type"
                    validate={required()}
                    fullWidth
                    choices={
                      hasTransferAct && !hasFinalAct
                        ? actTypeChoises.filter(
                            (a) => a.id !== ActTypeEnum.TRANSFER
                          )
                        : hasFinalAct
                        ? actTypeChoises.filter(
                            (a) => a.id !== ActTypeEnum.FINAL
                          )
                        : actTypeChoises
                    }
                    defaultValue={
                      hasTransferAct
                        ? ActTypeEnum.INTERMEDIATE
                        : ActTypeEnum.TRANSFER
                    }
                  />
                </Box>
              </Box>
              <CarInput source="car" isRequired disabled />
              <CustomerSearchInput disabled />
              <NumberInput
                source="mileage"
                validate={[required(), minValue(mileage)]}
                defaultValue={mileage}
              />
              <Box mt="1em" />
              <TextInput source="description" fullWidth multiline />
              <AuthorInput source="manager" isRequired={true} />
            </Grid>
          </Grid>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="resources.Act.tabs.equipment">
          <Grid container width={{ xs: "100%", xl: "100%" }} spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                {translate("resources.Act.fieldGroups.equipment")}
              </Typography>
              <ArrayInput source="equipment" defaultValue={equipment}>
                <SimpleFormIterator
                  inline
                  disableClear
                  disableReordering
                  fullWidth
                  getItemLabel={(index) => `${index + 1}`}
                >
                  <ReferenceInput
                    source="item"
                    label={translate("resources.CarEquipmentItem.fields.name")}
                    reference="CarEquipmentItem"
                    sort={{ field: "name", order: "ASC" }}
                  >
                    <AutocompleteInput
                      optionText="name"
                      optionValue="id"
                      validate={required()}
                      label={translate(
                        "resources.CarEquipmentItem.fields.name"
                      )}
                      filterToQuery={(searchText) => ({ name: searchText })}
                      sx={{ display: "flex", flex: 1 }}
                      create={<CreateCarEquipmentItem />}
                    />
                  </ReferenceInput>
                  <NumberInput
                    source="amount"
                    label={translate(
                      "resources.CarEquipmentItem.fields.amount"
                    )}
                    validate={required()}
                  />
                  <SelectInput
                    source="condition"
                    label={translate(
                      "resources.CarEquipmentItem.fields.condition"
                    )}
                    choices={equipmentConditionChoises}
                    validate={required()}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          </Grid>
        </TabbedForm.Tab>
      </TabbedForm>
    </Create>
  );
};
