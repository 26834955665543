import { Box, Grid } from "@mui/material";
import {
  BooleanInput,
  DateInput,
  Edit,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import { UnitTypeEnum } from "../types";
import { unitTypeChoises } from "../shared/select";
import { CreateEdit } from "./CreateEdit";

export const MaintenanceItemEdit = () => (
  <Edit mutationMode="pessimistic">
    <CreateEdit edit={true} />
  </Edit>
);
