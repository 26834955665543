import {
  Create,
  FormDataConsumer,
  NumberInput,
  SelectInput,
  TabbedForm,
  TextInput,
  minValue,
  required,
  useGetIdentity,
  useRecordContext,
  useTranslate,
} from "react-admin";

import { Grid, Typography, Box } from "@mui/material";

import { BookingSourceEnum, Customer } from "../types";

import { useState } from "react";
import { CustomerSearchInput } from "../shared/customer/CustomerSearchInput";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import { AuthorInput } from "../shared/AuthorInput";
import { AdditionalServicesInput } from "../shared/additionalServices/AdditionalServicesInput";
import { StartEndSourceInput } from "../shared/startEndSource/startEndSourceInput";
import { OrderSummary } from "./OrderSummary";
import { CarInput } from "../shared/CarInput";
import EditAside from "./EditAside";
import { TariffSelect } from "./TariffSelect";
import { orderTypeChoises } from "../shared/select";

const customerOptionRenderer = (choice: Customer) =>
  `${choice.fullname} (${choice.phone})`;

export const OrderCreate = (props: any) => {
  const translate = useTranslate();
  const { isLoading, data: userData, error } = useGetIdentity();
  const [showAside, setShowAside] = useState(false);

  const handleShowInfo = () => {
    setShowAside(!showAside);
  };

  const record = useRecordContext();
  useDefineAppLocation("sales.order.create");

  return (
    <Create aside={showAside ? <EditAside /> : undefined}>
      <TabbedForm
        defaultValues={{
          manager: userData?.id,
          source: BookingSourceEnum.OFFICE,
        }}
      >
        <TabbedForm.Tab label="resources.Order.tabs.identity">
          <Grid container width={{ xs: "100%" }} spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                {translate("resources.Order.fieldGroups.identity")}
              </Typography>
              <SelectInput
                source="type"
                choices={orderTypeChoises}
                validate={required()}
              />
              <StartEndSourceInput checkEndDate={false} />
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
                  <CustomerSearchInput />
                </Box>
                <Box sx={{ paddingY: 2 }}>
                  {/* <FormDataConsumer<{ customer: string }>>
                    {({ formData, ...rest }) => {
                      return (
                        formData.customer && (
                          <Button
                            onClick={handleShowInfo}
                            color="primary"
                            // disabled={loading}
                            fullWidth
                            size="large"
                            startIcon={<InfoICon />}
                          />
                        )
                      );
                    }}
                  </FormDataConsumer> */}
                </Box>
              </Box>

              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
                  <CarInput source="car" isRequired />
                </Box>
                <Box flex={1}>
                  <FormDataConsumer<{ car: string }>>
                    {({ formData, ...rest }) => {
                      return (
                        formData.car && <TariffSelect car={formData.car} />
                      );
                    }}
                  </FormDataConsumer>
                </Box>
              </Box>

              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="deliveryAddress" fullWidth />
                </Box>
                <Box flex={1}>
                  <NumberInput
                    source="delivery"
                    fullWidth
                    defaultValue={0.0}
                    validate={minValue(0)}
                  />
                </Box>
              </Box>

              <AdditionalServicesInput />
              <OrderSummary />
              <Box mt="1em" />
              <TextInput source="description" fullWidth multiline />
              <AuthorInput isRequired={true} source="manager" />
            </Grid>
          </Grid>
          <Box mt="4em" />
        </TabbedForm.Tab>
      </TabbedForm>
    </Create>
  );
};

export default OrderCreate;
