import CarEquipmentIcon from "@mui/icons-material/ElectricCar";

import CarEquipmentList from "./CarEquipmentList";
import CarEquipmentEdit from "./CarEquipmentEdit";
import CarEquipmentCreate from "./CarEquipmentCreate";
import { CarEquipment, ParsePointer } from "../types";
import { DataProvider, GetManyParams, GetManyReferenceParams, GetOneParams } from "react-admin";
import { ParseDataProvider } from "../data-provider";

const resource = {
  list: CarEquipmentList,
  edit: CarEquipmentEdit,
  create: CarEquipmentCreate,
  icon: CarEquipmentIcon,
};

export const carEquipmentsLifecycleCallbacks = {
  resource: "CarEquipment",

  afterRead: async (record: CarEquipment, dataProvider: ParseDataProvider) => {

    return {
      ...record,
      car: record.car?.id,
      author: record.author?.id,
    };
  },

  beforeSave: async (data: any, dataProvider: ParseDataProvider) => {
    data.car = new ParsePointer("PartnerCar", data.car);
    if (data.date) {
      data.date = new Date(data.date);
    }
    return data;
  },


};

export default resource;