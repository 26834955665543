import TariffIcon from "@mui/icons-material/CurrencyRuble";

import TariffList from "./TariffList";
import TariffEdit from "./TariffEdit";
import TariffCreate from "./TariffCreate";
import { ParsePointer, Tariff } from "../types";
import { DataProvider, GetManyParams, GetManyReferenceParams, GetOneParams } from "react-admin";

const resource = {
  list: TariffList,
  edit: TariffEdit,
  create: TariffCreate,
  icon: TariffIcon,
  recordRepresentation: "name"
};

export const tariffsLifecycleCallbacks = {
  resource: "Tariff",

  afterRead: async (record: Tariff, dataProvider: DataProvider) => {
    return {
      ...record,
      city: record.city?.id,
      carType: record.carType?.id,
      author: record.author?.id,
    };
  },

  beforeSave: async (data: any, dataProvider: DataProvider) => {
    data.city = new ParsePointer("City", data.city);
    data.carType = new ParsePointer("CarType", data.carType);
    return data;
  },


};

export default resource;