import ActIcon from "@mui/icons-material/FactCheck";

import { IAct, ParsePointer } from "../types";
import {
  DataProvider,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetOneResult,
  RaRecord,
  ResourceCallbacks,
  UpdateParams,
} from "react-admin";
import { ActList } from "./ActList";
import { ActEdit } from "./ActEdit";
import { ActCreate } from "./ActCreate";

const resource = {
  list: ActList,
  edit: ActEdit,
  create: ActCreate,
  icon: ActIcon,
  recordRepresentation: "numStr",
};

export const actLifecycleCallbacks = {
  resource: "Act",

  afterRead: async (record: IAct, dataProvider: DataProvider) => {
    return {
      ...record,
      author: record.author?.id,
      car: record.car?.id,
      customer: record.customer?.id,
      manager: record.manager?.id,
    };
  },

  beforeSave: async (data: any, dataProvider: DataProvider) => {
    data.car = new ParsePointer("PartnerCar", data.car);
    data.customer = new ParsePointer("Customer", data.customer);
    data.manager = new ParsePointer("_User", data.manager);
    return data;
  },
};

export default resource;
