import {
  FormDataConsumer,
  Loading,
  useDataProvider,
  useRecordContext,
  useTranslate,
} from "react-admin";
import {
  AdditionalService,
  Tariff,
  ICalculateBookingSummaryRequest,
  ICalculateOrderSummaryRequest,
  ICalculateOrderSummaryResponse,
  Order,
} from "../types";
import { Card, CardContent, Skeleton, Typography } from "@mui/material";
import { ParseDataProvider } from "src/data-provider";
import { useQuery } from "react-query";

export const OrderSummary = (props: any) => {
  const record = useRecordContext<Order>();

  if (record && record.chargeInfo) {
    return <OrderSummaryField {...record.chargeInfo} />;
  }

  return (
    <FormDataConsumer<{
      tarriff: Tariff;
      additionalData: AdditionalService[];
      tariff: string;
      start: string;
      end: string;
      delivery: number;
    }>>
      {({ formData, ...rest }) => {
        return (
          formData.tariff &&
          formData.start && (
            <OrderSummaryContent
              start={formData.start}
              end={formData.end}
              tariff={formData.tariff}
              additionalData={formData.additionalData}
              delivery={formData.delivery}
              {...props}
            />
          )
        );
      }}
    </FormDataConsumer>
  );
};

export const OrderSummaryContent = (props: {
  start: string;
  end: string;
  tariff: string;
  additionalData: AdditionalService[];
  delivery: number;
}) => {
  const translate = useTranslate();
  const dataProvider = useDataProvider<ParseDataProvider>();
  const { data, isLoading, error } = useQuery(
    [
      "calculateOrder",
      {
        id: props.tariff,
        start: props.start,
        end: props.end,
        total: props.additionalData?.reduce((acc, a) => acc + a.price, 0) ?? 0,
        delivery: props.delivery,
      },
    ],
    () => {
      const services = props.additionalData?.map((a) => {
        return { item: a.item as string, price: a.price };
      });
      const payload: ICalculateOrderSummaryRequest = {
        tariffId: props.tariff.toString(),
        start: new Date(props.start).toISOString(),
        end: props.end ? new Date(props.end).toISOString() : undefined,
        additionalData: services ?? [],
        delivery: props.delivery ?? 0,
      };
      return dataProvider.calculateOrderSummary({ ...payload });
    },
    { retry: false, staleTime: 1000 }
  );
  if (error) {
    return (
      <Card sx={{ p: 2, maxHeight: 300 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Ошибка при расчете
          </Typography>
          {error.toString()}
        </CardContent>
      </Card>
    );
  }

  if (isLoading) {
    return (
      <Card sx={{ maxHeight: 300 }}>
        <CardContent>
          <Loading />
        </CardContent>
      </Card>
    );
  }

  return <OrderSummaryField {...data} />;
};

export const OrderSummaryField = (data: ICalculateOrderSummaryResponse) => {
  const translate = useTranslate();
  return (
    <>
      <Card sx={{ maxHeight: 300 }} variant="elevation">
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {translate("resources.Order.fieldGroups.pre_summary")} {data?.total}{" "}
            ₽
          </Typography>
          {data?.deposit !== 0 && (
            <Typography variant="subtitle1" gutterBottom>
              Залог - {data?.deposit} ₽
            </Typography>
          )}
          {data?.charge !== 0 && (
            <Typography variant="subtitle1" gutterBottom>
              Оплата за аренду от {data?.charge} ₽ за ({data?.days} дней x{" "}
              {data?.dayPrice} ₽)
            </Typography>
          )}
          {data?.overrun !== 0 && (
            <Typography variant="subtitle1" gutterBottom>
              Цена за перепробег - {data?.overrun} ₽
            </Typography>
          )}
          {data?.additionalServices !== 0 && (
            <Typography variant="subtitle1" gutterBottom>
              Дополнительные услуги - {data?.additionalServices} ₽
            </Typography>
          )}
          {data?.delivery !== 0 && (
            <Typography variant="subtitle1" gutterBottom>
              Подача авто - {data?.delivery} ₽
            </Typography>
          )}
        </CardContent>
      </Card>
    </>
  );
};
