import {
  BooleanField,
  Datagrid,
  DateField,
  EmailField,
  List,
  NullableBooleanInput,
  SearchInput,
  TextField,
  TextInput,
} from "react-admin";
import { _User } from "src/types";
import UserNameField from "./UserNameField";


const usersFilter = [
  <SearchInput source="q" alwaysOn />,
  <NullableBooleanInput source="banned" alwaysOn />,
  <NullableBooleanInput source="confirmed" alwaysOn />,
  <TextInput source="email" />,
];

const UserList = () => (
  <List filters={usersFilter} exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <DateField source="createdAt" />
      <UserNameField source="username"/>
      <TextField source="firstname" />
      <TextField source="lastname" />
      <EmailField source="email" />
      <BooleanField source="confirmed" />
      <BooleanField source="banned" />
    </Datagrid>
  </List>
);


export default UserList;

