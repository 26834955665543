import {
  List,
  Datagrid,
  TextField,
  SearchInput,
} from "react-admin";

const brandFilter = [
  <SearchInput source="name" alwaysOn />,
];


export const AutoBrandList = () => (
<List exporter={false} filters={brandFilter}>
  <Datagrid rowClick="edit" bulkActionButtons={false}>
    <TextField source="name" />
  </Datagrid>
</List>
);

export default AutoBrandList;