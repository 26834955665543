import {
  FormDataConsumer,
  Loading,
  useDataProvider,
  useRecordContext,
  useTranslate,
} from "react-admin";
import {
  AdditionalService,
  Tariff,
  ICalculateBookingSummaryRequest,
  ICalculateOrderSummaryRequest,
  ICalculateOrderSummaryResponse,
  Order,
  ICalculateCloseOrderSummaryRequest,
  ICloseOrderInfoResponse,
} from "../types";
import { Card, CardContent, Skeleton, Typography } from "@mui/material";
import { ParseDataProvider } from "src/data-provider";
import { useQuery } from "react-query";

export const OrderCloseSummary = (props: any) => {
  const record = useRecordContext<Order>();

  return (
    <FormDataConsumer<{
      closedAt: string;
    }>>
      {({ formData, ...rest }) => {
        return (
          formData.closedAt && (
            <OrderCloseSummaryContent
              closedAt={formData.closedAt}
              orderId={record.id}
              {...props}
            />
          )
        );
      }}
    </FormDataConsumer>
  );
};

export const OrderCloseSummaryContent = (props: {
  closedAt: string;
  orderId: string;
}) => {
  const dataProvider = useDataProvider<ParseDataProvider>();
  const { data, isLoading, error } = useQuery(
    [
      "calculateCloseOrder",
      {
        closeAt: props.closedAt,
        orderId: props.orderId,
      },
    ],
    () => {
      const payload: ICalculateCloseOrderSummaryRequest = {
        closeAt: new Date(props.closedAt).toISOString(),
        orderId: props.orderId,
      };
      return dataProvider.calculateCloseOrderSummary({ ...payload });
    },
    { staleTime: 1000 }
  );
  if (error) {
    return (
      <Card sx={{ p: 2, maxHeight: 300 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Ошибка при расчете
          </Typography>
          {error.toString()}
        </CardContent>
      </Card>
    );
  }

  if (!data) {
    return (
      <Card sx={{ p: 2, maxHeight: 300 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Нет данных для расчета
          </Typography>
        </CardContent>
      </Card>
    );
  }

  if (isLoading) {
    return (
      <Card sx={{ maxHeight: 300 }}>
        <CardContent>
          <Loading />
        </CardContent>
      </Card>
    );
  }

  return <CloseOrderSummaryField {...data} />;
};

export const CloseOrderSummaryField = (data: ICloseOrderInfoResponse) => {
  const translate = useTranslate();
  return (
    <>
      <Card sx={{ maxHeight: 300 }} variant="elevation">
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {translate("resources.Order.fieldGroups.final_summary")}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Дней аренды {data.days}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Начисления по аренде {data.charged} ₽ ({data.days} дней x{" "}
            {data.dayPrice} ₽)
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Начисления по доп. услугам {data.servicesCharged} ₽
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Подача авто {data.deliveryPrice} ₽
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Депозит {data.deposit} ₽
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Оплата всего {data.payments} ₽
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Итого {data.total} ₽
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};
