import PartnerIcon from "@mui/icons-material/Handshake";

import PartnerList from "./PartnerList";
import PartnerEdit from "./PartnerEdit";
import PartnerCreate from "./PartnerCreate";
import { ParsePointer, Partner } from "../types";
import {
  DataProvider,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
} from "react-admin";
import { ParseDataProvider } from "src/data-provider";

const resource = {
  list: PartnerList,
  edit: PartnerEdit,
  create: PartnerCreate,
  icon: PartnerIcon,
  recordRepresentation: (record: Partner) => `${record.name}`,
};

export const partnersLifecycleCallbacks = {
  resource: "Partner",

  afterRead: async (record: Partner, dataProvider: ParseDataProvider) => {
    const users = await dataProvider.getPartnerUsers({ partnerId: record.id });
    return {
      ...record,
      city: record.city?.id,
      bankCity: record.bankCity?.id,
      users: users,
    };
  },

  beforeSave: async (data: any, dataProvider: DataProvider) => {
    data.city = new ParsePointer("City", data.city);
    data.bankCity = new ParsePointer("City", data.bankCity);
    delete data.users;
    return data;
  },
};

export default resource;
