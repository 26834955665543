import { Grid, Typography, Box, Stack } from "@mui/material";
import {
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  useTranslate,
  ReferenceManyField,
  Datagrid,
  DeleteButton,
  FileField,
  FileInput,
  TextField,
  WithRecord,
  BooleanInput,
  CheckboxGroupInput,
  ReferenceArrayInput,
  ArrayField,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  SelectArrayInput,
  PasswordInput,
  EditButton,
  SaveButton,
  Toolbar,
  useRefresh,
  useNotify,
  AutocompleteInput,
  WrapperField,
  FunctionField,
  List,
} from "react-admin";
import { FormTab, TabbedForm } from "@react-admin/ra-rbac";
import { ContactTypeEnum, DocTypeEnum, Partner, _User } from "../types";
import { CreateEditProps } from "../interface";
import {
  CreateInDialogButton,
  EditInDialogButton,
} from "@react-admin/ra-form-layout";
import DocsReferenceInput from "../shared/DocsReferenceInput";
import { contactTypeChoises } from "../shared/select";
import { RemoveUserButton } from "./RemoveUserButton";
import { AddUSerButton } from "./AddUserButton";

export const CustomToolbar = () => (
  <Toolbar sx={{ justifyContent: "space-between" }}>
    <SaveButton />
  </Toolbar>
);

export const CreateEdit = (props: CreateEditProps) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  return (
    <TabbedForm>
      <FormTab label="resources.Partner.tabs.identity" name="identity">
        <Grid container width={{ xs: "100%" }} spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              {translate("resources.Partner.fieldGroups.identity")}
            </Typography>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="name" validate={required()} fullWidth />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="shortName" validate={required()} fullWidth />
              </Box>
            </Box>
            <TextInput source="fullName" validate={[required()]} fullWidth />
            <TextInput
              source="positionOfHead"
              validate={[required()]}
              fullWidth
            />
            <TextInput
              source="positionReason"
              validate={[required()]}
              fullWidth
            />
            <TextInput
              source="lastnameAndInitials"
              validate={[required()]}
              fullWidth
            />

            <Box mt="1em" />

            <Typography variant="h6" gutterBottom>
              {translate("resources.Partner.fieldGroups.accountInfo")}
            </Typography>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0 }}>
                <TextInput
                  source="inn"
                  label={translate("resources.AccountInfo.fields.inn")}
                  validate={required()}
                  fullWidth
                />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  source="kpp"
                  label={translate("resources.AccountInfo.fields.kpp")}
                  validate={required()}
                  fullWidth
                />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  source="ogrn"
                  label={translate("resources.AccountInfo.fields.ogrn")}
                  validate={required()}
                  fullWidth
                />
              </Box>
            </Box>

            <Box mt="1em" />

            <Typography variant="h6" gutterBottom>
              {translate("resources.Partner.fieldGroups.bankInfo")}
            </Typography>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0 }}>
                <TextInput
                  source="bik"
                  label={translate("resources.BankInfo.fields.bik")}
                  validate={required()}
                  fullWidth
                />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  source="bankName"
                  label={translate("resources.BankInfo.fields.bankName")}
                  validate={required()}
                  fullWidth
                />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                <ReferenceInput source="bankCity" reference="City">
                  <SelectInput
                    optionText="name"
                    label={translate("resources.BankInfo.fields.bankCity")}
                    fullWidth
                    validate={required()}
                  />
                </ReferenceInput>
              </Box>
            </Box>

            <TextInput
              source="bankAccount"
              label={translate("resources.BankInfo.fields.bankAccount")}
              validate={[required()]}
              fullWidth
            />
            <TextInput
              source="korrAccount"
              label={translate("resources.BankInfo.fields.korrAccount")}
              validate={[required()]}
              fullWidth
            />

            <Box mt="1em" />

            <Typography variant="h6" gutterBottom>
              {translate("resources.Partner.fieldGroups.contacts")}
            </Typography>
            <ArrayInput source="contacts" label={false}>
              <SimpleFormIterator
                inline
                disableClear
                fullWidth
                disableReordering
              >
                <SelectInput
                  source="type"
                  label={translate("resources.Partner.fields.type")}
                  validate={required()}
                  choices={contactTypeChoises}
                  sx={{ display: "flex", flex: 1 }}
                />
                <TextInput
                  source="value"
                  validate={required()}
                  sx={{ display: "flex", flex: 1 }}
                  label={translate("resources.Partner.fields.value")}
                />
                <TextInput
                  source="description"
                  validate={required()}
                  sx={{ display: "flex", flex: 1 }}
                  label={translate("resources.Partner.fields.description")}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              {translate("resources.Partner.fieldGroups.addressInfo")}
            </Typography>
            <ReferenceInput source="city" reference="City">
              <SelectInput optionText="name" fullWidth validate={required()} />
            </ReferenceInput>
            <TextInput source="legalAddress" validate={required()} fullWidth />
            <TextInput source="actualAddress" validate={required()} fullWidth />
          </Grid>
        </Grid>
      </FormTab>
      {props.edit && (
        <FormTab label="resources.Partner.tabs.files" name="files">
          <Grid container width={{ xs: "100%" }} spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                {translate("resources.Partner.fieldGroups.files")}
              </Typography>

              <DocsReferenceInput
                source={DocTypeEnum.PARTNER}
                target="partnerId"
              />
            </Grid>
          </Grid>
        </FormTab>
      )}
      {props.edit && (
        <FormTab label="resources.Partner.tabs.users" name="users">
          <Grid container width={{ xs: "100%" }} spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                {translate("resources.Partner.fieldGroups.users")}
              </Typography>
              <ArrayField source="users">
                <Datagrid bulkActionButtons={false} sx={{ mb: 2 }}>
                  <ReferenceField
                    source="id"
                    reference="_User"
                    label="Номер"
                    link={false}
                  >
                    <TextField source="username" />
                  </ReferenceField>
                  <ReferenceField
                    source="id"
                    reference="_User"
                    label="Имя"
                    link={false}
                  >
                    <FunctionField
                      label="Имя"
                      render={(record: _User) =>
                        `${record.firstname} ${record.lastname}`
                      }
                    />
                  </ReferenceField>
                  <ReferenceArrayField
                    label="Роли"
                    reference="_Role"
                    source="roles"
                  >
                    <SingleFieldList linkType={false}>
                      <ChipField source="title" />
                    </SingleFieldList>
                  </ReferenceArrayField>
                  <RemoveUserButton />
                  <EditInDialogButton
                    fullWidth
                    maxWidth="md"
                    resource="_User"
                    title="resources._User.actions.editUserRolesTitle"
                    mutationOptions={{
                      onSettled: () => {
                        refresh();
                      },
                    }}
                  >
                    <SimpleForm toolbar={<CustomToolbar />}>
                      <TextInput
                        source="username"
                        disabled
                        fullWidth
                        label="resources._User.fields.username"
                      />
                      <ReferenceArrayInput source="roles" reference="_Role">
                        <SelectArrayInput
                          optionText="title"
                          label="resources._User.tabs.roles"
                          fullWidth
                        />
                      </ReferenceArrayInput>
                    </SimpleForm>
                  </EditInDialogButton>
                </Datagrid>
                <AddUSerButton />
              </ArrayField>
            </Grid>
          </Grid>
        </FormTab>
      )}
      {props.edit && (
        <FormTab
          label="resources.Partner.tabs.doc_templates"
          name="doc_templates"
        >
          <Grid container width={{ xs: "100%" }} spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                {translate("resources.Partner.fieldGroups.doc_templates")}
              </Typography>
              <WithRecord
                render={(record: Partner) => (
                  <>
                    <List
                      resource="DocTemplate"
                      exporter={false}
                      sort={{ field: "createdAt", order: "DESC" }}
                      empty={false}
                    >
                      <Datagrid bulkActionButtons={false}>
                        <TextField source="name" />
                        <FileField
                          source="file"
                          src="file._url"
                          title="file._name"
                        />
                        <EditInDialogButton>
                          <SimpleForm
                            toolbar={
                              <Toolbar sx={{ justifyContent: "space-between" }}>
                                <SaveButton />
                                <DeleteButton redirect={false} />
                              </Toolbar>
                            }
                          >
                            <TextInput
                              source="name"
                              validate={required()}
                              fullWidth
                              label={translate(
                                "resources.DocTemplate.fields.name"
                              )}
                            />
                            <FileInput
                              source="file"
                              validate={required()}
                              fullWidth
                              accept=".doc,.docx"
                              label={translate(
                                "resources.DocTemplate.fields.file"
                              )}
                            >
                              <FileField
                                source="_url"
                                src="_url"
                                title="_name"
                                target="_blank"
                              />
                            </FileInput>
                            <Grid container width={{ xs: "100%" }} spacing={2}>
                              <Grid item xs={12} md={12}>
                                <Box display={{ xs: "block", sm: "flex" }}>
                                  <Box mr={{ xs: 0, sm: "0.5em" }} flex={1}>
                                    <BooleanInput
                                      source="isFromOrganizationToOrganization"
                                      label={translate(
                                        "resources.DocTemplate.fields.isFromOrganizationToOrganization"
                                      )}
                                    />
                                  </Box>
                                  <Box flex={1}>
                                    <BooleanInput
                                      source="isFromOrganizationToIndividual"
                                      label={translate(
                                        "resources.DocTemplate.fields.isFromOrganizationToIndividual"
                                      )}
                                    />
                                  </Box>
                                </Box>
                                <Box display={{ xs: "block", sm: "flex" }}>
                                  <Box mr={{ xs: 0, sm: "0.5em" }} flex={1}>
                                    <BooleanInput
                                      source="isFromIPToOrganization"
                                      label={translate(
                                        "resources.DocTemplate.fields.isFromIPToOrganization"
                                      )}
                                    />
                                  </Box>
                                  <Box flex={1}>
                                    <BooleanInput
                                      source="isFromIPToIndividual"
                                      label={translate(
                                        "resources.DocTemplate.fields.isFromIPToIndividual"
                                      )}
                                    />
                                  </Box>
                                </Box>
                                <Box display={{ xs: "block", sm: "flex" }}>
                                  <Box mr={{ xs: 0, sm: "0.5em" }} flex={1}>
                                    <BooleanInput
                                      source="isIndividualToOrganization"
                                      label={translate(
                                        "resources.DocTemplate.fields.isIndividualToOrganization"
                                      )}
                                    />
                                  </Box>
                                  <Box flex={1}>
                                    <BooleanInput
                                      source="isIndividualToIndividual"
                                      label={translate(
                                        "resources.DocTemplate.fields.isIndividualToIndividual"
                                      )}
                                    />
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>

                            <BooleanInput
                              source="isReport"
                              label={translate(
                                "resources.DocTemplate.fields.isReport"
                              )}
                            />
                          </SimpleForm>
                        </EditInDialogButton>
                      </Datagrid>
                    </List>
                    <CreateInDialogButton resource="DocTemplate" fullWidth>
                      <SimpleForm resource="DocTemplate">
                        <TextInput
                          source="name"
                          validate={required()}
                          fullWidth
                          label={translate("resources.DocTemplate.fields.name")}
                        />
                        <FileInput
                          source="file"
                          validate={required()}
                          fullWidth
                          accept=".doc,.docx"
                          label={translate("resources.DocTemplate.fields.file")}
                        >
                          <FileField source="src" title="title" />
                        </FileInput>
                        <BooleanInput
                          source="isFromOrganizationToOrganization"
                          label={translate(
                            "resources.DocTemplate.fields.isFromOrganizationToOrganization"
                          )}
                        />
                        <BooleanInput
                          source="isFromOrganizationToIndividual"
                          label={translate(
                            "resources.DocTemplate.fields.isFromOrganizationToIndividual"
                          )}
                        />
                        <BooleanInput
                          source="isFromIPToOrganization"
                          label={translate(
                            "resources.DocTemplate.fields.isFromIPToOrganization"
                          )}
                        />
                        <BooleanInput
                          source="isFromIPToIndividual"
                          label={translate(
                            "resources.DocTemplate.fields.isFromIPToIndividual"
                          )}
                        />
                        <BooleanInput
                          source="isIndividualToOrganization"
                          label={translate(
                            "resources.DocTemplate.fields.isIndividualToOrganization"
                          )}
                        />
                        <BooleanInput
                          source="isIndividualToIndividual"
                          label={translate(
                            "resources.DocTemplate.fields.isIndividualToIndividual"
                          )}
                        />
                        <BooleanInput
                          source="isReport"
                          label={translate(
                            "resources.DocTemplate.fields.isReport"
                          )}
                        />
                      </SimpleForm>
                    </CreateInDialogButton>
                  </>
                )}
              />
              <Box mt="6em" />
            </Grid>
          </Grid>
        </FormTab>
      )}
    </TabbedForm>
  );
};
