import { Box } from "@mui/material";
import { DateTimeInput, required, SelectInput } from "react-admin";
import { bookingSourceChoises } from "../select";
import { checkDatesValidator } from "../validators";

type Props = {
  checkEndDate?: boolean;
  readOnly?: boolean;
};

export const StartEndSourceInput = ({
  checkEndDate = true,
  readOnly,
}: Props) => {
  return (
    <Box display={{ xs: "block", sm: "flex" }}>
      <Box mr={{ xs: 0, sm: "0.5em" }}>
        <DateTimeInput
          source="start"
          validate={[required(), checkDatesValidator()]}
          readOnly={readOnly}
        />
      </Box>
      <Box mr={{ xs: 0, sm: "0.5em" }}>
        <DateTimeInput
          source="end"
          readOnly={readOnly}
          validate={
            checkEndDate
              ? [required(), checkDatesValidator()]
              : checkDatesValidator()
          }
        />
      </Box>
      <Box>
        <SelectInput
          source="source"
          validate={required()}
          choices={bookingSourceChoises}
          readOnly={readOnly}
        />
      </Box>
    </Box>
  );
};
