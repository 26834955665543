import { Grid, Typography, Box } from "@mui/material";
import {
  TabbedForm,
  SelectInput,
  required,
  TextInput,
  DateInput,
  ArrayInput,
  SimpleFormIterator,
  useTranslate,
  ReferenceArrayInput,
  SelectArrayInput,
  AutocompleteArrayInput,
  useRecordContext,
  FormDataConsumer,
  BooleanInput,
  AutocompleteInput,
} from "react-admin";
import {
  DocTypeEnum,
} from "../types";
import { CarImagesField } from "../cars/CarImagesField";
import { CreateEditProps } from "../interface";
import DocsReferenceInput from "../shared/DocsReferenceInput";
import {
  contactTypeChoises,
  customerTypeChoises,
  extraDocTypeChoises,
  genderChoises,
  relationshipChoises,
} from "../shared/select";
import ImageReferenceInput from "../shared/ImageReferenceInput";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import { PhoneInput } from "../shared/PhoneInput";
import { CountryInput } from "../shared/CountryInput";

const filterToQuery = (searchText: string) => ({ name: searchText });

export const CreateEdit = (props: CreateEditProps) => {
  const translate = useTranslate();
  const record = useRecordContext();
  if (props.edit) {
    useDefineAppLocation("sales.customers.edit", { record });
  } else {
    useDefineAppLocation("sales.customers.create");
  }

  return (
    <TabbedForm>
      <TabbedForm.Tab label="resources.Customer.tabs.summary">
        <Grid container width={{ xs: "100%" }} spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              {translate("resources.Customer.fieldGroups.identity")}
            </Typography>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <SelectInput
                  source="type"
                  validate={required()}
                  fullWidth
                  choices={customerTypeChoises}
                />
              </Box>
              <Box flex={2}>
                {/* <TextInput source="phone" validate={required()} fullWidth /> */}
                <PhoneInput
                  source="phone"
                  fullWidth
                  validate={required()}
                  format={(value: string) => value && value.replace(/\D/g, "")}
                />
              </Box>
            </Box>

            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="lastname" validate={required()} fullWidth />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="firstname" validate={required()} fullWidth />
              </Box>
              <Box flex={1}>
                <TextInput source="surname" fullWidth />
              </Box>
            </Box>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <SelectInput
                  source="gender"
                  validate={required()}
                  fullWidth
                  choices={genderChoises}
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <DateInput
                  source="birthDate"
                  fullWidth
                  helperText={false}
                  validate={required()}
                />
              </Box>
              <Box flex={1}>
                <TextInput source="birthPlace" fullWidth helperText={false} />
              </Box>
            </Box>

            <ReferenceArrayInput source="segments" reference="CustomerSegment">
              <AutocompleteArrayInput
                label="resources.Customer.fields.segments"
                filterToQuery={filterToQuery}
                optionText="name"
                optionValue="id"
              />
            </ReferenceArrayInput>

            <BooleanInput source="married" />

            <FormDataConsumer<{ married: boolean }>>
              {({
                formData, // The whole form data
                scopedFormData, // The data for this item of the ArrayInput
              }) => formData.married && <TextInput source="spouse" fullWidth />}
            </FormDataConsumer>

            <BooleanInput source="criminalRecord" />

            <FormDataConsumer<{ criminalRecord: boolean }>>
              {({
                formData, // The whole form data
                scopedFormData, // The data for this item of the ArrayInput
              }) =>
                formData.criminalRecord && (
                  <TextInput source="criminalRecordStr" fullWidth />
                )
              }
            </FormDataConsumer>

            <Box mt="1em" />

            <Typography variant="h6" gutterBottom>
              {translate("resources.Partner.fieldGroups.contacts")}
            </Typography>
            <ArrayInput source="contacts" label={false}>
              <SimpleFormIterator
                inline
                disableClear
                disableReordering
                fullWidth
              >
                <SelectInput
                  source="type"
                  label={translate("resources.Contact.fields.type")}
                  validate={required()}
                  sx={{ display: "flex", flex: 1 }}
                  choices={contactTypeChoises}
                />
                <TextInput
                  source="value"
                  sx={{ display: "flex", flex: 2 }}
                  validate={required()}
                  label={translate("resources.Contact.fields.value")}
                />

                <TextInput
                  source="description"
                  sx={{ display: "flex", flex: 3 }}
                  label={translate("resources.Contact.fields.description")}
                />
                <FormDataConsumer>
                  {({
                    formData, // The whole form data
                    scopedFormData, // The data for this item of the ArrayInput
                    getSource, // A function to get the valid source inside an ArrayInput
                    ...rest
                  }) =>
                    scopedFormData && scopedFormData.type === "PHONE" ? (
                      <AutocompleteInput
                        source={getSource("relationship")}
                        choices={relationshipChoises}
                        sx={{ display: "flex", flex: 1 }}
                        label={translate(
                          "resources.Contact.fields.relationship"
                        )}
                      />
                    ) : null
                  }
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
          {props.edit && (
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                {translate("resources.Customer.fieldGroups.photo")}
              </Typography>
              <ImageReferenceInput
                target="customerId"
                iterator={<CarImagesField />}
              />

              <Box mb="6em" />
            </Grid>
          )}

          <Box mt="2em" />
        </Grid>
      </TabbedForm.Tab>
      <TabbedForm.Tab label="resources.Customer.tabs.passport">
        <Grid container width={{ xs: "100%" }} spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" gutterBottom>
              {translate("resources.Customer.fieldGroups.passport")}
            </Typography>
            <CountryInput source="passportCountry" reference="Country" />
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  source="passportSerie"
                  validate={required()}
                  fullWidth
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  source="passportNum"
                  validate={required()}
                  fullWidth
                />
              </Box>
              <Box flex={1}>
                <DateInput
                  source="passportDate"
                  validate={required()}
                  fullWidth
                />
              </Box>
            </Box>

            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="passportDepartment" fullWidth />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="passportDepartmentCode" fullWidth />
              </Box>
            </Box>
            <TextInput source="legalAddress" validate={required()} fullWidth />
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={6} mr={{ xs: 0, sm: "0.5em" }}>
                <FormDataConsumer<{ sameAddresses: boolean }>>
                  {({
                    formData, // The whole form data
                    scopedFormData, // The data for this item of the ArrayInput
                  }) =>
                    !formData.sameAddresses && (
                      <TextInput
                        source="actualAddress"
                        validate={required()}
                        fullWidth
                      />
                    )
                  }
                </FormDataConsumer>
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <BooleanInput source="sameAddresses" />
              </Box>
            </Box>

            <Box mt="2em" />

            <Typography variant="h6" gutterBottom>
              {translate("resources.Customer.fieldGroups.extra")}
            </Typography>

            <ArrayInput source="extraDocs" label={false}>
              <SimpleFormIterator
                inline
                disableClear
                disableReordering
                fullWidth
              >
                <SelectInput
                  source="type"
                  label={translate("resources.ExtraDoc.fields.type")}
                  validate={required()}
                  sx={{ display: "flex", flex: 1 }}
                  choices={extraDocTypeChoises}
                />
                <TextInput
                  source="serie"
                  validate={required()}
                  sx={{ display: "flex", flex: 2 }}
                  label={translate("resources.ExtraDoc.fields.serie")}
                />
                <TextInput
                  source="number"
                  validate={required()}
                  sx={{ display: "flex", flex: 2 }}
                  label={translate("resources.ExtraDoc.fields.number")}
                />
                <DateInput
                  source="date"
                  validate={required()}
                  sx={{ display: "flex", flex: 2 }}
                  label={translate("resources.ExtraDoc.fields.date")}
                />
              </SimpleFormIterator>
            </ArrayInput>

            <Box mb="2em" />
          </Grid>
        </Grid>
      </TabbedForm.Tab>
      <TabbedForm.Tab label="resources.Customer.tabs.license">
        <Grid container width={{ xs: "100%" }} spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" gutterBottom>
              {translate("resources.Customer.fieldGroups.license")}
            </Typography>

            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  source="drivingLicenseNum"
                  validate={required()}
                  fullWidth
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <DateInput
                  source="drivingLicenseDate"
                  validate={required()}
                  fullWidth
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <DateInput
                  source="drivingLicenseDateUntil"
                  validate={required()}
                  fullWidth
                />
              </Box>
            </Box>

            <TextInput
              source="drivingLicenseDepartment"
              validate={required()}
              fullWidth
            />

            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <SelectArrayInput
                  fullWidth
                  source="allowedCategories"
                  choices={[
                    { id: "A", name: "A" },
                    { id: "A1", name: "A1" },
                    { id: "B", name: "B" },
                    { id: "B1", name: "B1" },
                    { id: "C", name: "C" },
                    { id: "C1", name: "C1" },
                    { id: "D", name: "D" },
                    { id: "D1", name: "D1" },
                    { id: "BE", name: "BE" },
                    { id: "CE", name: "CE" },
                    { id: "C1E", name: "C1E" },
                    { id: "DE", name: "DE" },
                    { id: "D1E", name: "D1E" },
                    { id: "M", name: "M" },
                    { id: "Tm", name: "Tm" },
                    { id: "Tb", name: "Tb" },
                  ]}
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="drivingExperience" fullWidth disabled />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </TabbedForm.Tab>
      <TabbedForm.Tab label="resources.Customer.tabs.files">
        <Grid container width={{ xs: "100%" }} spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" gutterBottom>
              {translate("resources.Customer.fieldGroups.files")}
            </Typography>

            <DocsReferenceInput
              source={DocTypeEnum.CUSTOMER}
              target="customerId"
            />

            <Box mb="6em" />
          </Grid>
        </Grid>
      </TabbedForm.Tab>
    </TabbedForm>
  );
};
