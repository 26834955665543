import { Box, Grid } from "@mui/material";
import {
  DateInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import { AdditionalServiceRewardTypeEnum, AdditionalServiceTypeEnum } from "../types";
import { additionalServiceRewardTypeChoises, additionalServiceTypeChoises } from "../shared/select";
import { CreateEdit } from "./CreateEdit";

export const AdditionalServiceEdit = () => (
  <Edit>
    <CreateEdit edit/>
  </Edit>
);
