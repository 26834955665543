import {
  Edit,
  TabbedForm,
  TextInput,
  required,
  BooleanInput,
  FormDataConsumer,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import UserTitle from "./UserTitle";

const UserEdit = () => (
  <Edit title={<UserTitle />}>
    <TabbedForm>
      <TabbedForm.Tab label="resources._User.tabs.summary">
        <TextInput source="firstname" validate={[required()]} fullWidth />
        <TextInput source="lastname" validate={[required()]} fullWidth />
        <TextInput
          source="email"
          type="email"
          validate={[required()]}
          fullWidth
        />
        <BooleanInput source="confirmed" />
        <BooleanInput source="banned" />
        <FormDataConsumer<{ banned: boolean }>>
          {({ formData, ...rest }) =>
            formData.banned && (
              <TextInput
                source="banReason"
                {...rest}
                multiline
                fullWidth
                validate={[required()]}
              />
            )
          }
        </FormDataConsumer>
        <TextInput
          source="position"
          validate={[required()]}
          fullWidth
        />
      </TabbedForm.Tab>
      <TabbedForm.Tab label="resources._User.tabs.roles">
        
        <ReferenceArrayInput
          source="roles"
          reference="_Role"
        >
          <SelectArrayInput optionText="title" label="resources._User.tabs.roles" fullWidth/>
        </ReferenceArrayInput>
      </TabbedForm.Tab>
    </TabbedForm>
  </Edit>
);

export default UserEdit;
