import { Edit, SimpleForm, TextInput, useRecordContext, useTranslate } from "react-admin";
import { City } from "src/types";

export const CityEdit = () => (
  <Edit title={<CityTitle />} mutationMode="pessimistic">
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

const CityTitle = () => {
  const record = useRecordContext<City>();
  const translate = useTranslate();
  return (
    <span>
      {translate("resources.City.name", { smart_count: 1 })}{" "}
      {record ? `"${record.name}"` : ""}
    </span>
  );
};

export default CityEdit;