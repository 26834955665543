import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  SelectField,
  ReferenceField,
  NumberField,
  UrlField,
  useTranslate,
} from "react-admin";
import { AuthorField } from "../shared/AuthorField";
import { RUBFormat } from "../shared/formatUtils";
import { transactionDirectionChoises } from "../shared/select";
import DocUrlField from "./LinkToOrder";

export const TransactionShow = (props: any) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <DateField source="date" showTime />
        <SelectField source="direction" choices={transactionDirectionChoises} />
        <ReferenceField source="type" reference="TransactionType">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="account" reference="TransactionAccount">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="amount" options={RUBFormat()} />
        <TextField source="description" />
        <DocUrlField />
        <AuthorField source="author" />
      </SimpleShowLayout>
    </Show>
  );
};
