import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import {
  List,
  Datagrid,
  TextField,
  SearchInput,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { AuthorField } from "../shared/AuthorField";

export const CarTypeList = () => {
  useDefineAppLocation("sales.car_type");
  return (
    <List exporter={false}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="name" />
        <AuthorField source="author" />
      </Datagrid>
    </List>
  );
};

export default CarTypeList;
