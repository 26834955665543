import {
  Button,
  Create,
  CreateBase,
  Form,
  PasswordInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  required,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from "react-admin";
import AddIcon from "@mui/icons-material/PersonAdd";
import { useId, useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ParseDataProvider } from "src/data-provider";
import { useMutation } from "react-query";
import { TextMaskCustom } from "../shared/phoneMaskInput";
import { _User } from "../types";

export const AddUSerButton = (props: any) => {
  const id = useId();
  const record = useRecordContext();
  const notify = useNotify();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const [searching, setSearching] = useState(false);
  const [creating, setCreating] = useState(false);
  const [user, setUser] = useState<_User | undefined>(undefined);
  const [phone, setPhone] = useState<string | undefined>(undefined);
  const [needNew, setNeedNew] = useState(false);
  const theme = useTheme();
  const dataProvider = useDataProvider<ParseDataProvider>();
  const refresh = useRefresh();
  const { mutate, isLoading } = useMutation(() =>
    dataProvider
      .removeUserFromPartner({ userId: record.id })
      .then(() => refresh())
  );

  const handleSearch = async (values: FormValues) => {
    setSearching(true);
    setNeedNew(false);
    setPhone(values.newUserPhone!.replace(/\D/g, ""));
    dataProvider
      .findUser({ phone: values.newUserPhone!.replace(/\D/g, "") })
      .then((results) => {
        setSearching(false);
        if (results == null) {
          setNeedNew(true);
          setUser(undefined);
        } else {
          setUser(results);
          setNeedNew(false);
        }
      })
      .catch((error: Error) => {
        setSearching(false);
        setNeedNew(false);
        setUser(undefined);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          {
            type: "error",
            messageArgs: {
              _:
                typeof error === "string"
                  ? error
                  : error && error.message
                  ? error.message
                  : undefined,
            },
          }
        );
      });
  };

  const handleClick = () => {
    setCreating(false);
    setNeedNew(false);
    setPhone(undefined);
    setSearching(false);
    setUser(undefined);
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);
  const handleAddNewUser = (data: any) => {
    setCreating(true);
    dataProvider
      .addNewUserToPartner(data)
      .then((newUser) => {
        setCreating(false);
        setNeedNew(false);
        setPhone(undefined);
        setSearching(false);
        setUser(undefined);
        setOpen(false);
        refresh();
      })
      .catch((error: Parse.Error) => {
        setCreating(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          {
            type: "error",
            messageArgs: {
              _:
                typeof error === "string"
                  ? error
                  : error && error.message
                  ? error.message
                  : undefined,
            },
          }
        );
      });
  };

  const handleAddUser = (data: any) => {
    setCreating(true);
    dataProvider
      .addUserToPartner({ userId: user?.id, roles: data.roles })
      .then((newUser) => {
        setCreating(false);
        setNeedNew(false);
        setPhone(undefined);
        setSearching(false);
        setUser(undefined);
        setOpen(false);
        refresh();
      })
      .catch((error: Parse.Error) => {
        setCreating(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          {
            type: "error",
            messageArgs: {
              _:
                typeof error === "string"
                  ? error
                  : error && error.message
                  ? error.message
                  : undefined,
            },
          }
        );
      });
  };

  const { inline, ButtonProps, ...editDialogProps } = props;

  return (
    <>
      <Button
        label={translate("ra.action.add")}
        {...ButtonProps}
        onClick={() => handleClick()}
      >
        {<AddIcon />}
      </Button>

      <Dialog
        open={open}
        onClose={handleDialogClose}
        sx={{
          "& .MuiDialog-paper": { minWidth: { md: "50%" } },
        }}
      >
        <DialogTitle>
          {translate("resources._User.actions.addUserTitle")}
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Form id={id} onSubmit={handleSearch} noValidate>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={4} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  autoFocus
                  source="newUserPhone"
                  label={translate("custom.auth.phone")}
                  disabled={searching}
                  validate={required()}
                  InputProps={{
                    inputComponent: TextMaskCustom as any,
                    endAdornment: (
                      <IconButton type="submit">
                        {searching && (
                          <CircularProgress size={25} thickness={2} />
                        )}
                        <AddIcon />
                      </IconButton>
                    ),
                  }}
                  fullWidth
                />
              </Box>
            </Box>
          </Form>
          {user && (
            <>
              <Typography variant="h6" gutterBottom>
                Пользователь найден - {user.firstname} {user.lastname}
              </Typography>

              <CreateBase resource="_User" redirect={false}>
                <SimpleForm
                  sx={{ p: 0 }}
                  defaultValues={{ userId: user.id }}
                  onSubmit={handleAddUser}
                >
                  <ReferenceArrayInput source="roles" reference="_Role">
                    <SelectArrayInput
                      optionText="title"
                      label="resources._User.tabs.roles"
                      fullWidth
                    />
                  </ReferenceArrayInput>
                </SimpleForm>
              </CreateBase>
            </>
          )}
          {needNew && (
            <>
              <Create resource="_User" redirect={false}>
                <SimpleForm
                  defaultValues={{ username: phone }}
                  onSubmit={handleAddNewUser}
                >
                  <Grid container width={{ xs: "100%" }} spacing={2}>
                    <Grid item>
                      <TextInput
                        source="username"
                        label="resources._User.fields.username"
                        validate={[required()]}
                        fullWidth
                      />
                      <TextInput
                        source="firstname"
                        label="resources._User.fields.firstname"
                        validate={[required()]}
                        fullWidth
                      />
                      <TextInput
                        source="lastname"
                        label="resources._User.fields.lastname"
                        validate={[required()]}
                        fullWidth
                      />
                      <TextInput
                        source="email"
                        type="email"
                        validate={[required()]}
                        fullWidth
                      />
                      <TextInput
                        source="position"
                        label="resources._User.fields.position"
                        validate={[required()]}
                        fullWidth
                      />

                      <Box mt="1em" />

                      <Box display={{ xs: "block", sm: "flex" }}>
                        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                          <PasswordInput
                            source="password"
                            label="resources.Profile.fields.password"
                            fullWidth
                          />
                        </Box>
                        <Box flex={1}>
                          <PasswordInput
                            source="passwordConfirm"
                            label="resources.Profile.fields.passwordConfirm"
                            fullWidth
                          />
                        </Box>
                      </Box>
                      <ReferenceArrayInput source="roles" reference="_Role">
                        <SelectArrayInput
                          optionText="title"
                          label="resources._User.tabs.roles"
                          fullWidth
                        />
                      </ReferenceArrayInput>
                    </Grid>
                  </Grid>
                </SimpleForm>
              </Create>
            </>
          )}
        </DialogContent>
        {/* <SaveContextProvider value={saveContext}>
          {children}
        </SaveContextProvider> */}
      </Dialog>
    </>
  );
};

interface FormValues {
  newUserPhone?: string;
}
