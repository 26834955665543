import { DataProvider, fetchUtils } from "ra-core";
import { IParseFindResults } from "../interface";
import {
  DeleteResult,
  GetListResult,
  GetManyReferenceResult,
  withLifecycleCallbacks,
  GetOneParams,
  GetOneResult,
  GetManyReferenceParams,
  GetListParams,
  UpdateParams,
  UpdateResult,
  UpdateManyParams,
  UpdateManyResult,
  CreateResult,
  DeleteManyResult,
} from "react-admin";
import Parse from "../parse/parse";
import { usersLifecycleCallbacks } from "../users";
import { carsLifecycleCallbacks } from "../cars";
import { partnersLifecycleCallbacks } from "../partners";
import { carBrandLifecycleCallbacks } from "../auto-brands";
import { customersLifecycleCallbacks } from "../customers";
import { autoModelLifecycleCallbacks } from "../auto-models";
import {
  AddToRelationParams,
  CarStatusEnum,
  DocClass,
  ICalculateBookingSummaryRequest,
  ICalculateBookingSummaryResponse,
  ICalculateCloseOrderSummaryRequest,
  ICalculateOrderSummaryRequest,
  ICalculateOrderSummaryResponse,
  ICloseOrderInfoResponse,
  ImageClass,
  ParsePointer,
  _User,
} from "../types";
import { documentssLifecycleCallbacks } from "../document";
import { imagesLifecycleCallbacks } from "../image";
import { object, string } from "prop-types";
import cyrillicToTranslit from "cyrillic-to-translit-js";
import { tariffsLifecycleCallbacks } from "../tariff";
import { carEquipmentsLifecycleCallbacks } from "../equipment";
import { docTemplatesLifecycleCallbacks } from "../doc-template";
import { maintenanceLifecycleCallbacks } from "../maintenance";
import { orderLifecycleCallbacks } from "../orders";
import { maintenanceItemLifecycleCallbacks } from "../maintenance-item";
import { customerSegmentsLifecycleCallbacks } from "../customer-segments";
import { carTypesLifecycleCallbacks } from "../car-type";
import { additionalServicesLifecycleCallbacks } from "../additional-services";
import { transactionLifecycleCallbacks } from "../transaction";
import { transactionAccountLifecycleCallbacks } from "../transaction-account";
import { actLifecycleCallbacks } from "../act";
import { photoCheckLifecycleCallbacks } from "../photo-check";

export interface ParseDataProvider extends DataProvider {
  getRelation: (
    resource: string,
    params: GetManyReferenceParams,
    source: string
  ) => Promise<GetListResult<ImageClass>>;
  addToRelation: (
    resource: string,
    params: AddToRelationParams,
    source: string
  ) => Promise<UpdateResult>;
  removeFromRelation: (
    resource: string,
    params: AddToRelationParams,
    source: string
  ) => Promise<UpdateResult>;
  uploadFile: (file: File) => Promise<Parse.File>;
  updateProfile: (params: any) => Promise<UpdateResult>;
  getPartnerUsers: (params: any) => Promise<GetListResult<_User>>;
  removeUserFromPartner: (params: any) => Promise<void>;
  addUserToPartner: (params: any) => Promise<void>;
  addNewUserToPartner: (params: any) => Promise<_User>;
  findUser: (params: any) => Promise<_User | null>;
  calculateBookingSummary: (
    params: ICalculateBookingSummaryRequest
  ) => Promise<ICalculateBookingSummaryResponse>;
  calculateOrderSummary: (
    params: ICalculateOrderSummaryRequest
  ) => Promise<ICalculateOrderSummaryResponse>;
  calculateCloseOrderSummary: (
    params: ICalculateCloseOrderSummaryRequest
  ) => Promise<ICloseOrderInfoResponse>;
}

const provider = (): ParseDataProvider => ({
  async getList(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<any>> {
    const resourceObj = Parse.Object.extend(resource);
    const query = new Parse.Query(resourceObj);
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const { filter, meta } = params;

    query.limit(perPage);
    query.skip((page - 1) * perPage);

    const operators = {
      _gte: "gte",
      _lte: "lte",
      _neq: "neq",
      _has: "has",
    };

    if (order === "DESC") query.descending(field);
    else if (order === "ASC") query.ascending(field);
    if (resource === "Maintenance" && meta === "containsItems") {
      if (filter.car) {
        query.equalTo("car", {
          __type: "Pointer",
          className: "PartnerCar",
          objectId: filter.car,
        });
      }
      query.containedIn("data.item", [filter.item]);
    } else {
      Object.keys(filter).map((f) => {
        if (typeof filter[f] === "string") {
          const operator = operators[f.slice(-4) as keyof typeof operators];
          if (operator) {
            switch (operator) {
              case "gte":
                query.greaterThanOrEqualTo(f.slice(0, -4), new Date(filter[f]));
                break;
              case "lte":
                query.lessThanOrEqualTo(f.slice(0, -4), new Date(filter[f]));
                break;
              case "neq":
                query.notEqualTo(f.slice(0, -4), filter[f]);
                break;
              case "has":
                if (filter[f] === true) query.exists(f.slice(0, -4));
                else query.doesNotExist(f.slice(0, -4));
                break;
            }
          } else if (filter[f].substring(0, 1) === "+") {
            const sanitized = filter[f].substring(1);
            console.log(sanitized);
            query.matches(f, sanitized, "i");
          } else {
            query.matches(f, filter[f], "i");
          }
        } else if (filter[f] instanceof ParsePointer) {
          let pointerObject = new Parse.Object(filter[f].className);
          pointerObject.id = filter[f].id;
          query.equalTo(f, pointerObject);
        } else if (filter[f] instanceof Array) {
          query.containedIn(f, filter[f]);
        } else {
          const operator = operators[f.slice(-4) as keyof typeof operators];
          if (operator) {
            switch (operator) {
              case "neq":
                query.notEqualTo(f.slice(0, -4), filter[f]);
                break;
              case "has":
                if (filter[f] === true) query.exists(f.slice(0, -4));
                else query.doesNotExist(f.slice(0, -4));
                break;
            }
          } else {
            query.equalTo(f, filter[f]);
          }
        }
      });
    }

    if (meta && meta.include) {
      query.include(meta.include);
    }

    query.withCount(true);
    return query
      .find()
      .then((res) => {
        const results = res as unknown as IParseFindResults;
        return {
          total: results.count,
          data: results.results.map((o) => {
            return { id: o.id, ...o.attributes };
          }),
        };
      })
      .catch((e) => {
        console.log(e);
        throw e.message;
      });
  },

  async getOne(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<any>> {
    const resourceObj = Parse.Object.extend(resource);
    const query = new Parse.Query(resourceObj);
    const result = await query.get(params.id);
    return {
      data: { id: result.id, ...result.attributes },
    };
  },

  async getMany(resource, params): Promise<GetListResult<any>> {
    try {
      const resourceObj = Parse.Object.extend(resource);
      const query = new Parse.Query(resourceObj);
      query.containedIn("objectId", params.ids);
      query.withCount(true);
      const data = (await query.find()) as unknown as IParseFindResults;
      return {
        total: data.count,
        data: data.results.map((o) => {
          return { id: o.id, ...o.attributes };
        }),
      };
    } catch (error: any) {
      console.log(resource, params);
      throw error.message;
    }
  },

  async getManyReference(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<any>> {
    const resourceObj = Parse.Object.extend(resource);
    const query = new Parse.Query(resourceObj);

    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const { filter, meta } = params;
    if (params.target === "car") {
      // TODO
      query.equalTo("car", {
        __type: "Pointer",
        className: "PartnerCar",
        objectId: params.id,
      });
    } else {
      query.equalTo(params.target, params.id);
    }

    query.withCount();
    query.limit(perPage);
    query.skip((page - 1) * perPage);
    if (order === "DESC") query.descending(field);
    else if (order === "ASC") query.ascending(field);

    Object.keys(filter).map((f) => {
      if (typeof filter[f] === "string") {
        query.matches(f, filter[f], "i");
      } else if (filter[f] instanceof Array) {
        query.containsAll(f, filter[f]);
      } else {
        query.equalTo(f, filter[f]);
      }
    });

    return query
      .find()
      .then((res) => {
        const results = res as unknown as IParseFindResults;
        return {
          total: results.count,
          data: results.results.map((o) => {
            return { id: o.id, ...o.attributes };
          }),
        };
      })
      .catch((e) => {
        console.log(e);
        throw e.message;
      });
  },

  async update(resource: string, params: UpdateParams): Promise<UpdateResult> {
    const resourceObj = new Parse.Object(resource, { id: params.id });
    const keys = Object.keys(params.data).filter((o) =>
      o == "id" ||
      o == "createdAt" ||
      o == "updatedAt" ||
      o == "confirmed" ||
      o == "banned" ||
      o == "ACL" ||
      o == "sessionToken" ||
      o == "passwordConfirm" ||
      o == "username"
        ? false
        : true
    );
    const data = keys.reduce((r, f, i) => {
      // @ts-ignore
      r[f] = params.data[f];
      return r;
    }, {});

    for (const field in data) {
      // @ts-ignore
      if (data[field] instanceof ParsePointer) {
        const pointer = params.data[field] as ParsePointer;
        let obj = new Parse.Object(pointer.className, { id: pointer.id });
        // @ts-ignore
        data[field] = obj;
      }
    }

    const result = await resourceObj.save(data);
    return { data: { ...result.attributes, id: result.id } };
  },

  async updateMany(
    resource: string,
    params: UpdateManyParams
  ): Promise<UpdateManyResult> {
    const resourceObj = Parse.Object.extend(resource);
    try {
      const qs = await Promise.all(
        params.ids.map((id) => new Parse.Query(resourceObj).get(id.toString()))
      );
      qs.map((q) => q.save(params.data));
      return { data: params.ids };
    } catch (error: any) {
      throw error.message;
    }
  },

  async create(resource: string, params): Promise<CreateResult> {
    const resourceObj = new Parse.Object(resource);
    try {
      for (const field in params.data) {
        if (params.data[field] instanceof ParsePointer) {
          const pointer = params.data[field] as ParsePointer;
          let obj = new Parse.Object(pointer.className);
          obj.id = pointer.id;
          params.data[field] = obj;
        }
      }

      const r = await resourceObj.save(params.data);
      return { data: { id: r.id, ...r.attributes } };
    } catch (error: any) {
      throw error.message;
    }
  },

  async delete(resource: string, params): Promise<DeleteResult<any>> {
    const resourceObj = Parse.Object.extend(resource);
    const query = new Parse.Query(resourceObj);
    const obj = await query.get(params.id.toString());
    const data = { data: { id: obj.id, ...obj.attributes } };
    await obj.destroy();
    return data;
  },

  async deleteMany(resource: string, params): Promise<DeleteManyResult> {
    const resourceObj = Parse.Object.extend(resource);
    const qs = await Promise.all(
      params.ids.map((id) => new Parse.Query(resourceObj).get(id.toString()))
    );
    await Promise.all(qs.map((obj) => obj.destroy()));
    return { data: params.ids };
  },

  async getRelation(
    resource: string,
    params: GetManyReferenceParams,
    source: string
  ): Promise<any> {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const resourceObj = Parse.Object.extend(resource);
    const query = new Parse.Query(resourceObj);
    const obj = await query.get(params.id.toString());
    const relationSource = obj.get(source) as Parse.Relation;
    const relationQuery = relationSource.query();
    relationQuery.limit(perPage);
    relationQuery.skip((page - 1) * perPage);
    if (order === "DESC") relationQuery.descending(field);
    else if (order === "ASC") relationQuery.ascending(field);
    relationQuery.withCount();
    return relationQuery
      .find()
      .then((res) => {
        const results = res as unknown as IParseFindResults;
        return {
          total: results.count,
          data: results.results.map((o) => {
            return { id: o.id, ...o.attributes };
          }),
        };
      })
      .catch((e) => {
        console.log(e);
        throw e.message;
      });
  },

  async addToRelation(
    resource: string,
    params: AddToRelationParams,
    source: string
  ): Promise<UpdateResult> {
    const resourceObj = Parse.Object.extend(resource);
    const query = new Parse.Query(resourceObj);
    const obj = await query.get(params.id.toString());
    const relationSource = obj.get(source) as Parse.Relation;
    const relations = params.relationObjects.map((id) => {
      const objClass = Parse.Object.extend(params.target);
      const relObj = new objClass();
      relObj.id = id.id.toString();
      return relObj;
    });
    relationSource.add(relations);
    const r = await obj.save();
    return { data: { id: r.id, ...r.attributes } };
  },

  async removeFromRelation(
    resource: string,
    params: AddToRelationParams,
    source: string
  ): Promise<UpdateResult> {
    const resourceObj = Parse.Object.extend(resource);
    const query = new Parse.Query(resourceObj);
    const obj = await query.get(params.id.toString());
    const relationSource = obj.get(source) as Parse.Relation;
    const relations = params.relationObjects.map((id) => {
      const objClass = Parse.Object.extend(params.target);
      const relObj = new objClass();
      relObj.id = id.id.toString();
      return relObj;
    });
    relationSource.remove(relations);
    const r = await obj.save();
    return { data: { id: r.id, ...r.attributes } };
  },

  async uploadFile(file: File): Promise<Parse.File> {
    if (["image/heic", "image/heif"].includes(file.type)) {
      console.log("GOT IT");
    }
    return convertFileToBase64(file)
      .then((picture64) => {
        let parseFile = new Parse.File(
          cyrillicToTranslit().transform(
            file.name.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
          ),
          { base64: picture64 }
        );
        return parseFile.save();
      })
      .catch((e) => {
        console.log(e);
        throw e;
      });
  },

  async updateProfile(params: any): Promise<UpdateResult> {
    const user = Parse.User.current();
    if (!user) {
      throw "Unauthorized";
    }
    const keys = Object.keys(params).filter((o) =>
      o == "id" ||
      o == "createdAt" ||
      o == "updatedAt" ||
      o == "confirmed" ||
      o == "banned" ||
      o == "ACL" ||
      o == "sessionToken" ||
      o == "passwordConfirm" ||
      o == "username"
        ? false
        : true
    );
    const data = keys.reduce((r, f, i) => {
      // @ts-ignore
      r[f] = params[f];
      return r;
    }, {});

    // @ts-ignore
    if (data.avatar && data.avatar.rawFile instanceof File) {
      // @ts-ignore
      let file = await this.uploadFile(data.avatar.rawFile);
      // @ts-ignore
      delete data.avatar;
      // @ts-ignore
      data.avatar = file;
    } else {
      // @ts-ignore
      delete data.avatar;
    }

    const result = await user.save(data);

    return { data: { id: result.id, ...result.attributes } };
  },
  async getPartnerUsers(params: any): Promise<GetListResult<_User>> {
    const result = await Parse.Cloud.run("getPartnerUsers", params);
    // @ts-ignore
    return result.map((o) => {
      return { id: o.id, roles: o.roles };
    });
  },
  async removeUserFromPartner(params: any): Promise<void> {
    const result = await Parse.Cloud.run("removeUserFromPartner", params);
  },
  async addNewUserToPartner(params: any): Promise<_User> {
    const result = (await Parse.Cloud.run(
      "addNewUserToPartner",
      params
    )) as _User;
    return result;
  },
  async addUserToPartner(params: any): Promise<void> {
    const result = await Parse.Cloud.run("addUserToPartner", params);
  },
  async findUser(params: any): Promise<_User | null> {
    const result = (await Parse.Cloud.run("findUser", params)) as Parse.User;
    return result ? ({ id: result.id, ...result.attributes } as _User) : null;
  },
  async calculateBookingSummary(
    params: ICalculateBookingSummaryRequest
  ): Promise<ICalculateBookingSummaryResponse> {
    try {
      const result = await Parse.Cloud.run("calculateBookingSummary", params);
      return result;
    } catch (error) {
      throw error;
    }
  },
  async calculateOrderSummary(
    params: ICalculateOrderSummaryRequest
  ): Promise<ICalculateOrderSummaryResponse> {
    console.log(params);
    try {
      const result = await Parse.Cloud.run("calculateOrderSummary", params);
      return result;
    } catch (error) {
      throw error;
    }
  },
  async calculateCloseOrderSummary(
    params: ICalculateCloseOrderSummaryRequest
  ): Promise<ICloseOrderInfoResponse> {
    console.log(params);
    try {
      const result = await Parse.Cloud.run(
        "calculateCloseOrderSummary",
        params
      );
      return result;
    } catch (error) {
      throw error;
    }
  },
});

const convertFileToBase64 = (file: File): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result?.toString() || "");
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

const dataProvider = provider();
export default withLifecycleCallbacks(dataProvider, [
  additionalServicesLifecycleCallbacks,
  usersLifecycleCallbacks,
  carsLifecycleCallbacks,
  carEquipmentsLifecycleCallbacks,
  carTypesLifecycleCallbacks,
  partnersLifecycleCallbacks,
  carBrandLifecycleCallbacks,
  customersLifecycleCallbacks,
  customerSegmentsLifecycleCallbacks,
  autoModelLifecycleCallbacks,
  documentssLifecycleCallbacks,
  imagesLifecycleCallbacks,
  tariffsLifecycleCallbacks,
  docTemplatesLifecycleCallbacks,
  maintenanceLifecycleCallbacks,
  maintenanceItemLifecycleCallbacks,
  orderLifecycleCallbacks,
  transactionLifecycleCallbacks,
  transactionAccountLifecycleCallbacks,
  actLifecycleCallbacks,
  photoCheckLifecycleCallbacks,
]);
