import { Box, Grid, Typography } from "@mui/material";
import {
  BooleanInput,
  DateInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
} from "react-admin";
import { UnitTypeEnum } from "../types";
import { CreateEdit } from "./CreateEdit";

const TariffEdit = () => {
  const translate = useTranslate();
  return (
    <Edit>
      <CreateEdit edit={true}/>
    </Edit>
  );
};

export default TariffEdit;
