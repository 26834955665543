import { Box, Grid, Typography } from "@mui/material";
import {
  BooleanInput,
  Create,
  DateInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
} from "react-admin";
import { CreateEdit } from "./CreateEdit";

const TariffCreate = () => {
  const translate = useTranslate();
  return (
    <Create redirect={"list"}>
      <CreateEdit edit={false} />
    </Create>
  );
};

export default TariffCreate;
