import {
  List,
  Datagrid,
  TextField,
  SearchInput,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";

const filterToQuery = (searchText: string) => ({ name: searchText });

const modelFilter = [
  <SearchInput source="name" alwaysOn />,
  <ReferenceInput
    source="brand"
    reference="AutoBrand"
    sort={{ field: "name", order: "ASC" }}
    alwaysOn
  >
    <AutocompleteInput
      optionText="name"
      optionValue="id"
      filterToQuery={filterToQuery}
    />
  </ReferenceInput>,
];

export const AutoModelList = () => (
  <List exporter={false} filters={modelFilter}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <ReferenceField source="brand" reference="AutoBrand">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
    </Datagrid>
  </List>
);

export default AutoModelList;
