import { Grid, Typography, Box } from "@mui/material";
import { ArrayInput, Create, DateInput, ImageField, ImageInput, NumberInput, ReferenceInput, SelectInput, SimpleForm, SimpleFormIterator, TabbedForm, TextInput, required, useTranslate } from "react-admin";
import { CreateEdit } from "./createEdit";

export const CustomerCreate = () => {
  return (
  <Create redirect="list">
    <CreateEdit edit={false}/>
  </Create>
)};

export default CustomerCreate;