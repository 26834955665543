import CarIcon from '@mui/icons-material/DirectionsCar';

import AutoBrandList from './AutoBrandList';
import AutoBrandCreate from './AutoBrandCreate';
import AutoBrandEdit from './AutoBrandEdit';
import { DataProvider, GetManyParams, GetManyResult } from 'react-admin';
import { AutoBrand } from '../types';

const resource = {
    list: AutoBrandList,
    create: AutoBrandCreate,
    edit: AutoBrandEdit,
    icon: CarIcon,
};

export const carBrandLifecycleCallbacks = {
    resource: "AutoBrand",
  

  };

export default resource;