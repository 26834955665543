import * as React from "react";
import CarIcon from "@mui/icons-material/CarRental";
import { useTranslate } from "react-admin";

import CardWithIcon from "./CardWithIcon";

interface Props {
  value?: number;
}

const OverallFreeCars = (props: Props) => {
  const { value } = props;
  const translate = useTranslate();
  const createPath = "/PartnerCar?filter={\"currentState\":\"FREE\"}";
  return (
    <CardWithIcon
      to={createPath}
      icon={CarIcon}
      title={translate("pos.dashboard.total_free_cars")}
      subtitle={value}
    />
  );
};

export default OverallFreeCars;
