import {
  ArrayInput,
  required,
  minLength,
  SimpleFormIterator,
  NumberInput,
  minValue,
  maxValue,
  useTranslate,
  ValidationErrorMessage,
  ArrayInputProps,
} from "react-admin";
import { RUBInputProps } from "../shared/RUBInput";
import { TTariffDataRow } from "../types";

export const TariffInput = (props: any) => {
  const translate = useTranslate();
  const checkData =
    () =>
    (value: any, allValues: any): ValidationErrorMessage | undefined | null => {
      const hasDaysFromOne = allValues.data.some(
        (item: TTariffDataRow) => item.daysFrom === 1
      );
      if (!hasDaysFromOne) {
        return {
          message: translate(
            "custom.validationsMessages.tariff.daysFromOneRequired"
          ),
          args: [],
        };
      }
    };

  const checkUniqueDaysFrom =
    () =>
    (value: any, allValues: any): ValidationErrorMessage | undefined | null => {
      const daysFromSet = new Set();
      const duplicateDaysFrom = allValues.data.find((item: TTariffDataRow) => {
        if (daysFromSet.has(item.daysFrom)) {
          return true;
        }
        daysFromSet.add(item.daysFrom);
        return false;
      });
      if (duplicateDaysFrom) {
        return {
          message: translate(
            "custom.validationsMessages.tariff.uniqueDaysFrom"
          ),
          args: [],
        };
      }
    };

  return (
    <ArrayInput
      fullWidth
      validate={[required(), minLength(1), checkData(), checkUniqueDaysFrom()]}
      {...props}
    >
      <SimpleFormIterator inline>
        <NumberInput
          source="daysFrom"
          label="resources.Tariff.fields.daysFrom"
          helperText={false}
          min={1}
          validate={[required(), minValue(1), maxValue(365)]}
        />
        <NumberInput
          source="price"
          label="resources.Tariff.fields.price"
          helperText={false}
          min={1}
          validate={[required(), minValue(1)]}
          InputProps={RUBInputProps}
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
