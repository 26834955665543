export const formatNumberAsRUB = (
  value: number,
  minimumFractionDigits = 0
): string =>
  new Intl.NumberFormat(undefined, RUBFormat(minimumFractionDigits)).format(
      value
  );

export const RUBFormat = (minimumFractionDigits = 0) => ({
  style: 'currency',
  currency: 'RUB',
  currencyDisplay: 'narrowSymbol',
  minimumFractionDigits,
});