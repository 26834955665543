import {
  AutocompleteInput,
  Create,
  DateTimeInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
} from "react-admin";
import { TransactionType } from "../types";
import { RUBInputProps } from "../shared/RUBInput";

export const TransactionCreate = () => {
  const translate = useTranslate();
  return (
    <Create redirect="list">
      <SimpleForm>
        <DateTimeInput source="date" validate={[required()]} />
        <FormDataConsumer<{ payload: any }>>
          {({ formData }) => {
            if (formData.payload && formData.payload.orderId)
              return (
                <ReferenceInput
                  source="type"
                  reference="TransactionType"
                  filter={{ isForAccounting: true }}
                >
                  <AutocompleteInput
                    optionText="name"
                    validate={required()}
                    fullWidth
                    groupBy={(option: TransactionType) =>
                      translate(
                        `custom.transactionDirection.${option.direction}`
                      )
                    }
                  />
                </ReferenceInput>
              );
            return (
              <ReferenceInput
                source="type"
                reference="TransactionType"
                filter={{ isForAccounting: false }}
              >
                <AutocompleteInput
                  optionText="name"
                  validate={required()}
                  fullWidth
                  groupBy={(option: TransactionType) =>
                    translate(`custom.transactionDirection.${option.direction}`)
                  }
                />
              </ReferenceInput>
            );
          }}
        </FormDataConsumer>

        <ReferenceInput source="account" reference="TransactionAccount">
          <SelectInput optionText="name" validate={required()} fullWidth />
        </ReferenceInput>
        <NumberInput
          source="amount"
          validate={[required()]}
          fullWidth
          min={1}
          max={10000000}
          InputProps={RUBInputProps}
        />
        <TextInput source="description" fullWidth />
      </SimpleForm>
    </Create>
  );
};

export default TransactionCreate;
