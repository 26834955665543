import CustomerSegmentIcon from '@mui/icons-material/LocalOfferOutlined';
import CustomerSegmentList from './CustomerSegmentList';
import CustomerSegmentCreate from './CustomerSegmentCreate';
import CustomerSegmentEdit from './CustomerSegmentEdit';
import { GetManyParams } from 'react-admin';
import { ParseDataProvider } from '../data-provider';
import { Customer, ParsePointer } from '../types';



const resource = {
    list: CustomerSegmentList,
    create: CustomerSegmentCreate,
    edit: CustomerSegmentEdit,
    icon: CustomerSegmentIcon,
    recordRepresentation: "name"
};

export const customerSegmentsLifecycleCallbacks = {
    resource: "CustomerSegment",
  
 
    afterRead: async (record: Customer, dataProvider: ParseDataProvider) => {
  
      return {
        ...record,
        author: record.author?.id,
      };
    },
  
    beforeSave: async (data: any, dataProvider: ParseDataProvider) => {
      return data;
    },
  
  
  };


export default resource;