import * as React from "react";
import BookingIcon from '@mui/icons-material/EventAvailable';
import { useTranslate } from "react-admin";
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  subMonths,
  endOfToday,
  startOfToday,
  add,
  sub,
  startOfDay,
} from "date-fns";
import CardWithIcon from "./CardWithIcon";

import querystring from "query-string";

export const todayFilter = querystring.stringify({
  filter: JSON.stringify({
    start_gte: startOfToday().toISOString(),
  }),
});

interface Props {
  value?: number;
}

const TodayBooking = (props: Props) => {
  const { value } = props;
  const translate = useTranslate();
  const today = startOfToday().toISOString();
  return (
    <CardWithIcon
      to={`/Order?${todayFilter}`}
      icon={BookingIcon}
      title={translate("pos.dashboard.today_bookings")}
      subtitle={value?.toString()}
    />
  );
};

export default TodayBooking;
