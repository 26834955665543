import TransactionIcon from '@mui/icons-material/AddCard';

import TransactionList from './TransactionList';
import TransactionCreate from './TransactionCreate';
import TransactionEdit from './TransactionEdit';
import { ParsePointer, Transaction } from '../types';
import { DataProvider } from 'react-admin';
import { TransactionShow } from './TransactionShow';

const resource = {
    list: TransactionList,
    create: TransactionCreate,
    edit: TransactionEdit,
    show: TransactionShow,
    icon: TransactionIcon,
    recordRepresentation: "numStr",
};

export const transactionLifecycleCallbacks = {
    resource: "Transaction",
  
    afterRead: async (record: Transaction, dataProvider: DataProvider) => {
      const amount = record.amount < 0 ? -record.amount : record.amount;
      return {
        ...record,
        author: record.author?.id,
        customer: record.customer?.id,
        type: record.type?.id,
        account: record.account?.id,
        amount,
      };
    },

    beforeSave: async (data: Transaction, dataProvider: DataProvider) => {
      if (data.date) {
        data.date = new Date(data.date);
      }
      if (data.type) {
        data.type = new ParsePointer("TransactionType", data.type);
      }
      if (data.customer) {
        data.customer = new ParsePointer("Customer", data.customer);
      }
      if (data.account) {
        data.account = new ParsePointer("TransactionAccount", data.account);
      }
      return data;
    },
  
  
  };

export default resource;