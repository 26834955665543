import { ValidationErrorMessage } from "react-admin";

export const checkDatesValidator =
  () =>
  (value: any, allValues: any): ValidationErrorMessage | undefined | null => {
    if (allValues.start && allValues.end) {
      if (new Date(allValues.start) > new Date(allValues.end)) {
        return {
          message: "custom.validationsMessages.dates.startBeforeEnd",
          args: [],
        };
      }
    }
  };
