import {
  Edit,
  useRedirect,
} from "react-admin";

import { CreateEdit } from "./createEdit";
import { useMediaQuery, Theme } from "@mui/material";
import { CreateEditMobile } from "./createEditMobile";
import { useLocation } from "react-router-dom";

export const PartnerCarEdit = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  const location = useLocation();
  const redirect = useRedirect();
  const query = new URLSearchParams(location.search);
  const onSuccess = (data: any) => {
    const redirectSource = query.get("redirect")
    if (redirectSource) {
      redirect(redirectSource);
    }
  };

  if (isXsmall) {
    return (
      <Edit mutationMode="optimistic" mutationOptions={{ onSuccess }}>
        <CreateEditMobile edit={true} />
      </Edit>
    );
  }

  return (
    <Edit mutationMode="optimistic" mutationOptions={{ onSuccess }}>
      <CreateEdit edit={true} />
    </Edit>
  );
};

export default PartnerCarEdit;
