import { Create, ReferenceInput, SelectInput, SimpleForm, TextInput, required } from "react-admin";

export const AutoModelCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <ReferenceInput source="brand" reference="AutoBrand">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" validate={[required()]} fullWidth />
    </SimpleForm>
  </Create>
);

export default AutoModelCreate;
