import TransactionAccountIcon from '@mui/icons-material/PointOfSale';

import TransactionAccountList from './TransactionAccountList';
import TransactionAccountCreate from './TransactionAccountCreate';
import TransactionAccountEdit from './TransactionAccountEdit';
import { TransactionAccount } from '../types';
import { DataProvider } from 'react-admin';

const resource = {
    list: TransactionAccountList,
    create: TransactionAccountCreate,
    edit: TransactionAccountEdit,
    icon: TransactionAccountIcon,
    recordRepresentation: "name",
};

export const transactionAccountLifecycleCallbacks = {
    resource: "TransactionAccount",
  
    afterRead: async (record: TransactionAccount, dataProvider: DataProvider) => {
      return {
        ...record,
        author: record.author?.id,
      };
    },
  
  
  };

export default resource;