import {
  List,
  Datagrid,
  TextField,
  SearchInput,
  DateField,
  FileField,
} from "react-admin";


export const ImageList = () => (
<List exporter={false}>
  <Datagrid rowClick="edit" bulkActionButtons={false}>
    <FileField source="file._url" title="file._name" label={"resources.Image.fields.file"}/>
  </Datagrid>
</List>
);

export default ImageList;