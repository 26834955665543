import { CreateInDialogButton } from "@react-admin/ra-form-layout/esm/src";
import {
  ReferenceManyField,
  WithRecord,
  SimpleForm,
  TextInput,
  required,
  FileField,
  Datagrid,
  TextField,
  DeleteButton,
  useTranslate,
  DateField,
  ImageField,
  Toolbar,
  SaveButton,
  ImageInput,
} from "react-admin";
import { DocTypeEnum } from "../types";
import React from "react";

export interface ImageReferenceInputProps {
  target: string;
  iterator: JSX.Element;
}

const ImageReferenceInput = (props: ImageReferenceInputProps) => {
  const translate = useTranslate();
  return (
    <ReferenceManyField
      reference="Image"
      target={props.target}
      sort={{ field: "main", order: "DESC" }}
    >
      {props.iterator}

      <WithRecord
        render={(record) => (
          <CreateInDialogButton
            fullScreen
            record={{ [props.target]: record.id }}
            label={translate("resources.Image.actions.uploadImages")}
            ButtonProps={{
              size: "small",
              fullWidth: true,
              variant: "contained",
              sx: { mt: 1, p: 1 },
            }}
            sx={{ mb: "6em" }}
          >
            <SimpleForm>
              <ImageInput
                source="file"
                label={translate("resources.Image.fields.file")}
                validate={required()}
                multiple
                accept={"image/jpg,image/jpeg,image/heic,image/heif"}
                maxSize={10000000}
               
              >
                <ImageField source="src" title="title" />
              </ImageInput>
            </SimpleForm>
          </CreateInDialogButton>
        )}
      />
    </ReferenceManyField>
  );
};

export default ImageReferenceInput;
