import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { AutoBrand } from "src/types";

export const AutoModelEdit = () => (
  <Edit title={<AutoModelTitle />}>
    <SimpleForm>
      <ReferenceInput source="brand" reference="AutoBrand">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

const AutoModelTitle = () => {
  const record = useRecordContext<AutoBrand>();
  const translate = useTranslate();
  return (
    <span>
      {translate("resources.AutoModel.name", { smart_count: 1 })}{" "}
      {record ? `"${record.name}"` : ""}
    </span>
  );
};

export default AutoModelEdit;
