import * as React from "react";
import { ReactElement } from "react";
import {
  List,
  ListProps,
  RaRecord,
  UpdateParams,
  TransformData,
} from "react-admin";
import {
  EditDialog,
  EditDialogProps,
  CreateDialog,
  CreateDialogProps,
} from "@react-admin/ra-form-layout";
import { UseMutationOptions } from "react-query";

import { Calendar, CalendarProps } from "./Calendar";
import { getFilterValuesFromInterval } from "./getFilterValuesFromInterval";
import { EventClickArg } from "@fullcalendar/core/index.js";

/**
 * Replace the List and Edit views by a Calendar, powered by Full Calendar
 *
 * This all-in one component renders a calendar, as well as a form to edit or
 * create new events that opens in a dialog.
 *
 * Use it as the `list` prop of a `<Resource>`. No need to specify an `edit`
 * or `create` prop for this resource, but you'll have to pass a form component
 * (like `<SimpleForm>`) as child of `<CompleteCalendar>` to define the event
 * edition form.
 *
 * Accepted props:
 * - children: A Form element with Input children
 * - ListProps: Props passed to the `<List>` component
 * - CalendarProps: Props passed to the `<Calendar>` component
 * - EditDialogProps: Props passed to the `<EditDialog>` component
 * - CreateDialogProps: Props passed to the `<CreateDialog>` component
 *
 * @example
 * ```
 * import React, { FC } from 'react';
 * import {
 *     Admin,
 *     Resource,
 *     List,
 *     ListProps,
 *     SimpleForm,
 *     TextInput,
 *     DateTimeInput,
 * } from 'react-admin';
 * import { CompleteCalendar } from '@react-admin/ra-calendar';
 *
 * import dataProvider from './dataProvider';
 *
 * const EventList: FC<ListProps> = () => (
 *     <CompleteCalendar>
 *         <SimpleForm>
 *             <TextInput source="title" autoFocus />
 *             <DateTimeInput source="start" />
 *             <DateTimeInput source="end" />
 *         </SimpleForm>
 *     </CompleteCalendar>
 * );
 *
 * export const Basic: FC = () => (
 *     <Admin dataProvider={dataProvider}>
 *         <Resource name="events" list={EventList} />
 *     </Admin>
 * );
 * ```
 */
export const CompleteCalendar = (props: CompleteCalendarProps) => {
  const {
    mutationOptions,
    transform,
    CalendarProps,
    EditDialogProps,
    CreateDialogProps,
    ListProps,
    children,
  } = props;

  return (
    <>
      <List
        filterDefaultValues={getFilterValuesFromInterval()}
        perPage={1000}
        pagination={false}
        hasCreate
        {...ListProps}
      >
        <Calendar
          mutationOptions={mutationOptions}
          transform={transform}
          {...CalendarProps}
        />
      </List>

      <EditDialog
        // @ts-ignore
        mutationOptions={mutationOptions}
        transform={transform}
        {...EditDialogProps}
      >
        {children}
      </EditDialog>
      <CreateDialog
        // @ts-ignore
        mutationOptions={mutationOptions}
        transform={transform}
        {...CreateDialogProps}
      >
        {children}
      </CreateDialog>
    </>
  );
};

export interface CompleteCalendarProps<RecordType extends RaRecord = RaRecord> {
  children?: ReactElement;
  CalendarProps?: CalendarProps;
  ListProps?: Omit<ListProps, "children">;
  EditDialogProps?: EditDialogProps;
  CreateDialogProps?: CreateDialogProps;
  resource?: string;
  mutationOptions?: UseMutationOptions<
    RecordType,
    unknown,
    UpdateParams<RecordType>
  >;
  transform?: TransformData;
}
