import {
  AutocompleteInput,
  Datagrid,
  DatagridConfigurable,
  DateField,
  ExportButton,
  Identifier,
  List,
  NullableBooleanInput,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectColumnsButton,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";
import { FuelTypeEnum } from "../../../types";

import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import { carStatusChoises } from "../../../shared/select";
import rowSx from "../../../cars/rowSx";

import { Theme, useMediaQuery } from "@mui/material";
import InsuranceListAside from "./InsuranceAside";

const filterToQuery = (searchText: string) => ({ name: searchText });

const insuranceFilter = [
  <SearchInput source="name" alwaysOn />,
  <NullableBooleanInput
    label="resources.PartnerCar.filters.no_osago"
    key="osagoDate_has"
    source="osagoDate_has"
    defaultValue={false}
  />,
  <NullableBooleanInput
    label="resources.PartnerCar.filters.no_kasko"
    key="kaskoDate_has"
    source="kaskoDate_has"
    defaultValue={false}
  />,
];

const InsuranceListActions = () => {
  return (
    <TopToolbar>
      <SelectColumnsButton preferenceKey="insurance.datagrid" />
      <ExportButton />
    </TopToolbar>
  );
};

const InsuranceList = () => {
  useDefineAppLocation("cars.report.insurance");
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  return (
    <List
      filters={isSmall ? insuranceFilter : undefined}
      resource="PartnerCar"
      storeKey="reportInsurance"
      actions={<InsuranceListActions />}
      aside={<InsuranceListAside />}
    >
      <DatagridConfigurable
        rowClick={(id: Identifier) =>
          `/PartnerCar/${id}/1?redirect=/cars/report/insurance`
        }
        omit={[
          "osagoCompany",
          "kaskoCompany",
          "osagoNumber",
          "kaskoNumber",
          "brand",
          "model",
          "city",
          "carYear",
        ]}
        bulkActionButtons={false}
        preferenceKey="insurance.datagrid"
        sort={{ field: "sortNum", order: "ASC" }}
      >
        <TextField source="name" />
        <DateField source="osagoStartDate" />
        <DateField source="osagoDate" />
        <TextField source="osagoCompany" />
        <TextField source="osagoNumber" />
        <NumberField source="daysToOSAGOOut" sortable={false} />
        <DateField source="kaskoStartDate" />
        <DateField source="kaskoDate" />
        <TextField source="kaskoNumber" />
        <TextField source="kaskoCompany" />
        <NumberField source="daysToKASKOOut" sortable={false} />
        <ReferenceField source="brand" reference="AutoBrand">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="model" reference="AutoModel">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="licensePlateNumber" />
        <ReferenceField source="city" reference="City">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="carYear" />
      </DatagridConfigurable>
    </List>
  );
};

export default InsuranceList;
