import * as React from "react";
import { AnchorHTMLAttributes } from "react";
import get from "lodash/get";
import { Typography, Link } from "@mui/material";
import { useRecordContext, useTranslate } from "ra-core";
import { FieldProps, sanitizeFieldRestProps } from "ra-ui-materialui";
import { fieldPropTypes } from "ra-ui-materialui/src/field/types";

const formatPhone = (phone: string): string => {
  phone = phone.replace(/\D/g, "").substring(0, 11);
  return phone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/,"+$1 ($2) $3-$4");;
};

export const PhoneField = <
  RecordType extends Record<string, any> = Record<string, any>
>(
  props: PhoneFieldProps<RecordType>
) => {
  const { className, emptyText, source, ...rest } = props;
  const record = useRecordContext(props);
  const value = get(record, source!);
  const translate = useTranslate();

  if (value == null) {
    return (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeFieldRestProps(rest)}
      >
        {emptyText && translate(emptyText, { _: emptyText })}
      </Typography>
    );
  }

  return (
    <Link
      className={className}
      href={`tel:${formatPhone(value)}`}
      onClick={stopPropagation}
      variant="body2"
      {...sanitizeFieldRestProps(rest)}
    >
      {formatPhone(value)}
    </Link>
  );
};

PhoneField.propTypes = fieldPropTypes;
PhoneField.displayName = "PhoneField";

export interface PhoneFieldProps<
  RecordType extends Record<string, any> = Record<string, any>
> extends FieldProps<RecordType>,
    AnchorHTMLAttributes<HTMLAnchorElement> {}

// useful to prevent click bubbling in a Datagrid with rowClick
const stopPropagation = (e: { stopPropagation: () => any }) =>
  e.stopPropagation();
