import { Show, SimpleShowLayout, TextField, DateField, EmailField, BooleanField } from "react-admin";
import UserTitle from "./UserTitle";

export const UsersShow = () => (
  <Show title={<UserTitle />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" />
      <TextField source="username" />
      <TextField source="firstname" />
      <TextField source="lastname" />
      <EmailField source="email" />
      <BooleanField source="confirmed" />
      <BooleanField source="banned" />
    </SimpleShowLayout>
  </Show>
);

export default UsersShow;