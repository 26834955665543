import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import {
  Avatar,
  Button,
  Card,
  CardActions,
  Checkbox,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import {
  Form,
  required,
  TextInput,
  useTranslate,
  useLogin,
  useNotify,
  TextField,
} from "react-admin";

import Box from "@mui/material/Box";
import Logo from "./Logo";
import { IMaskInput } from "react-imask";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { PhoneInput } from "../shared/PhoneInput";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https:/p-and-a.ru/">
        p-and-a.ru
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [isAutoFill, setIsAutoFill] = useState(false);
  const translate = useTranslate();

  const notify = useNotify();
  const login = useLogin();
  const location = useLocation();

  const handleSubmit = (auth: FormValues) => {
    setLoading(true);
    login(
      auth,
      location.state ? (location.state as any).nextPathname : "/"
    ).catch((error: Error) => {
      setLoading(false);
      notify(
        typeof error === "string"
          ? error
          : typeof error === "undefined" || !error.message
          ? "ra.auth.sign_in_error"
          : error.message,
        {
          type: "error",
          messageArgs: {
            _:
              typeof error === "string"
                ? error
                : error && error.message
                ? error.message
                : undefined,
          },
        }
      );
    });
  };

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            backgroundImage:
              "url(https://source.unsplash.com/random?wallpapers)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[800]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Вход в P&A Cars CRM
            </Typography>
            <Box sx={{ mt: 1 }}>
              <PhoneInput
                source="username"
                label={translate("custom.auth.phone")}
                fullWidth
                disabled={loading}
                validate={required()}
                format={(value: string) => value && value.replace(/\D/g, "")}
              />
              <TextInput
                source="password"
                label={translate("ra.auth.password")}
                type="password"
                disabled={loading}
                validate={required()}
                fullWidth
                // autoComplete="current-password"
              />
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                fullWidth
                sx={{ mt: 3, mb: 2 }}
              >
                {loading && <CircularProgress size={25} thickness={2} />}
                {translate("ra.auth.sign_in")}
              </Button>
              {/* <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid> */}
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Form>
    // <Form onSubmit={handleSubmit} noValidate>
    //   <Box
    //     sx={{
    //       display: "flex",
    //       flexDirection: "column",
    //       minHeight: "100vh",
    //       alignItems: "center",
    //       justifyContent: "flex-start",
    //       background: "url(https://source.unsplash.com/featured/1600x900)",
    //       backgroundRepeat: "no-repeat",
    //       backgroundSize: "cover",
    //     }}
    //   >
    //     <Card sx={{ minWidth: 300, marginTop: "6em" }}>
    //       <Box
    //         sx={{
    //           margin: "1em",
    //           display: "flex",
    //           justifyContent: "center",
    //         }}
    //       >
    //         <Logo/>
    //       </Box>
    //       <Box
    //         sx={{
    //           marginTop: "1em",
    //           display: "flex",
    //           justifyContent: "center",
    //           color: (theme) => theme.palette.grey[500],
    //         }}
    //       >
    //         Вход в P&A Cars CRM
    //       </Box>
    //       <Box sx={{ padding: "0 1em 1em 1em" }}>
    //         <Box sx={{ marginTop: "1em" }}>
    // <TextInput
    //   autoFocus
    //   source="username"
    //   label={translate("custom.auth.phone")}
    //   disabled={loading}
    //   validate={required()}
    //   InputProps={{
    //     inputComponent: TextMaskCustom as any,
    //   }}
    //   fullWidth
    // />
    //         </Box>
    //         <Box sx={{ marginTop: "1em" }}>
    // <TextInput
    //   source="password"
    //   label={translate("ra.auth.password")}
    //   type="password"
    //   disabled={loading}
    //   validate={required()}
    //   fullWidth
    // />
    //         </Box>
    //       </Box>
    //       <CardActions sx={{ padding: "0 1em 1em 1em" }}>
    // <Button
    //   variant="contained"
    //   type="submit"
    //   color="primary"
    //   disabled={loading}
    //   fullWidth
    // >
    //   {loading && <CircularProgress size={25} thickness={2} />}
    //   {translate("ra.auth.sign_in")}
    // </Button>
    //       </CardActions>
    //     </Card>
    //   </Box>
    // </Form>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

interface FormValues {
  username?: string;
  password?: string;
}

export default Login;
