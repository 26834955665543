import { Grid, Box, Typography } from "@mui/material";
import {
  required,
  SelectInput,
  Edit,
  TabbedForm,
  DateTimeInput,
  FormDataConsumer,
  useTranslate,
  TextInput,
  ArrayInput,
  AutocompleteInput,
  NumberInput,
  ReferenceInput,
  SimpleFormIterator,
  minValue,
  WithRecord,
  useRecordContext,
} from "react-admin";
import { actTypeChoises, equipmentConditionChoises } from "../shared/select";
import { CarInput } from "../shared/CarInput";
import { CustomerSearchInput } from "../shared/customer/CustomerSearchInput";
import { AuthorInput } from "../shared/AuthorInput";
import { CreateCarEquipmentItem } from "../equipment/CreateEquipmentItem";
import PhotoCheckInput from "../shared/PhotoCheckInput";

export const ActEdit = (props: any) => {
  const translate = useTranslate();
  const record = useRecordContext();
  return (
    <Edit {...props}>
      <TabbedForm>
        <TabbedForm.Tab label="resources.Act.tabs.identity">
          <Grid container width={{ xs: "100%", xl: "100%" }} spacing={2}>
            <Grid item xs={12} md={12}>
              <FormDataConsumer<{ type: string }>>
                {({ formData }) => {
                  if (!formData.type)
                    return (
                      <Typography variant="h6" gutterBottom>
                        {translate("resources.Act.name", { smart_count: 1 })}
                      </Typography>
                    );
                  return (
                    <Typography variant="h6" gutterBottom>
                      {translate("custom.actType." + formData.type)}
                    </Typography>
                  );
                }}
              </FormDataConsumer>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box mr={{ xs: 0, sm: "0.5em" }}>
                  <DateTimeInput
                    source="date"
                    fullWidth
                    validate={required()}
                    defaultValue={new Date()}
                  />
                </Box>
                <Box mr={{ xs: 0, sm: "0.5em" }}>
                  <SelectInput
                    source="type"
                    validate={required()}
                    fullWidth
                    choices={actTypeChoises}
                    defaultValue={actTypeChoises[0].id}
                  />
                </Box>
              </Box>
              <CarInput source="car" isRequired disabled />
              <CustomerSearchInput disabled />
              <WithRecord
                render={(record) => (
                  <NumberInput
                    source="mileage"
                    validate={[required(), minValue(record.mileage)]}
                    defaultValue={record.mileage}
                  />
                )}
              />
              <Box mt="1em" />
              <TextInput source="description" fullWidth multiline />
              <AuthorInput source="manager" isRequired={true} />
            </Grid>
          </Grid>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="resources.Act.tabs.equipment">
          <Grid container width={{ xs: "100%", xl: "100%" }} spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                {translate("resources.Act.fieldGroups.equipment")}
              </Typography>
              <ArrayInput source="equipment">
                <SimpleFormIterator
                  inline
                  disableClear
                  disableReordering
                  fullWidth
                  getItemLabel={(index) => `${index + 1}`}
                >
                  <ReferenceInput
                    source="item"
                    label={translate("resources.CarEquipmentItem.fields.name")}
                    reference="CarEquipmentItem"
                    sort={{ field: "name", order: "ASC" }}
                  >
                    <AutocompleteInput
                      optionText="name"
                      optionValue="id"
                      validate={required()}
                      label={translate(
                        "resources.CarEquipmentItem.fields.name"
                      )}
                      filterToQuery={(searchText) => ({ name: searchText })}
                      sx={{ display: "flex", flex: 1 }}
                      create={<CreateCarEquipmentItem />}
                    />
                  </ReferenceInput>
                  <NumberInput
                    source="amount"
                    label={translate(
                      "resources.CarEquipmentItem.fields.amount"
                    )}
                    validate={required()}
                  />
                  <SelectInput
                    source="condition"
                    label={translate(
                      "resources.CarEquipmentItem.fields.condition"
                    )}
                    choices={equipmentConditionChoises}
                    validate={required()}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          </Grid>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="resources.Act.tabs.files">
          <Grid container width={{ xs: "100%", xl: "100%" }} spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                {translate("resources.Act.fieldGroups.images")}
              </Typography>
              <PhotoCheckInput />
            </Grid>
          </Grid>
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  );
};
