import * as React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  ResourceBreadcrumbItems,
} from "@react-admin/ra-navigation";
import { useCreatePath, useTranslate, RaRecord } from "react-admin";
import { CarType, DocTemplate, PartnerCar, Tariff } from "../types";

const CustomBreadcrumb = () => {
  const translate = useTranslate();
  const createPath = useCreatePath();

  const editLabel = translate("ra.action.edit");
  const createLabel = translate("ra.action.create");
  return (
    <Breadcrumb
      sx={{
        fontSize: "small",
        "& a": {
          pointerEvents: "visible",
        },
        mb: -1,
        ml: 1,
      }}
    >
      <ResourceBreadcrumbItems
        resources={[
          "City",
          "Doc",
          "Image",
          "Transaction",
          "TransactionType",
          "TransactionAccount",
          "Act",
          "Order"
        ]}
      />
      <BreadcrumbItem
        name="_User"
        label={translate("resources._User.name", 2)}
        to="/_User"
      >
        <BreadcrumbItem
          name="edit"
          label={({ record }: { record?: RaRecord }): string =>
            `${editLabel} ${record ? `${record.firstname} ${record.lastname} - ${record.username}` : "..."}`
          }
          to={({ record }: { record?: RaRecord }): string =>
            record
              ? createPath({
                  resource: "_User",
                  id: record.id,
                  type: "edit",
                })
              : ""
          }
        />
        <BreadcrumbItem
          name="create"
          label={createLabel}
          to="/_User/create"
        />
      </BreadcrumbItem>
      <BreadcrumbItem
        name="sales.customers"
        label={translate("resources.Customer.name", 2)}
        to="/Customer"
      >
        <BreadcrumbItem
          name="edit"
          label={({ record }: { record?: RaRecord }): string =>
            `${editLabel} ${record ? `"${record.fullname}"` : "..."}`
          }
          to={({ record }: { record?: RaRecord }): string =>
            record
              ? createPath({
                  resource: "Customer",
                  id: record.id,
                  type: "edit",
                })
              : ""
          }
        />
        <BreadcrumbItem
          name="create"
          label={createLabel}
          to="/Customer/create"
        />
      </BreadcrumbItem>
      <BreadcrumbItem
        name="sales.customer_segments"
        label={translate("resources.CustomerSegment.name", 2)}
        to="/CustomerSegment"
      >
        <BreadcrumbItem
          name="edit"
          label={({ record }: { record?: RaRecord }): string =>
            `${editLabel} ${record ? `"${record.name}"` : "..."}`
          }
          to={({ record }: { record?: RaRecord }): string =>
            record
              ? createPath({
                  resource: "CustomerSegment",
                  id: record.id,
                  type: "edit",
                })
              : ""
          }
        />
        <BreadcrumbItem
          name="create"
          label={createLabel}
          to="/CustomerSegment/create"
        />
      </BreadcrumbItem>
      <BreadcrumbItem
        name="sales.order"
        label={translate("resources.Order.name", 2)}
        to="/Order"
      >
        <BreadcrumbItem
          name="edit"
          label={({ record }: { record?: RaRecord }): string =>
            `${editLabel} ${record ? `"${record.numStr}"` : "..."}`
          }
          to={({ record }: { record?: RaRecord }): string =>
            record
              ? createPath({
                  resource: "Order",
                  id: record.id,
                  type: "edit",
                })
              : ""
          }
        />
        <BreadcrumbItem
          name="create"
          label={createLabel}
          to="/Order/create"
        />
      </BreadcrumbItem>
      <BreadcrumbItem
        name="sales.booking"
        label={translate("resources.Booking.name", 2)}
        to="/Order"
      >
        <BreadcrumbItem
          name="edit"
          label={({ record }: { record?: RaRecord }): string =>
            `${editLabel} ${record ? `"${record.numStr}"` : "..."}`
          }
          to={({ record }: { record?: RaRecord }): string =>
            record
              ? createPath({
                  resource: "Order",
                  id: record.id,
                  type: "edit",
                })
              : ""
          }
        />
        <BreadcrumbItem
          name="create"
          label={createLabel}
          to="/Order/create"
        />
      </BreadcrumbItem>
      <BreadcrumbItem
        name="sales.tariff"
        label={translate("resources.Tariff.name", 2)}
        to="/Tariff"
      >
        <BreadcrumbItem
          name="edit"
          label={({ record }: { record?: Tariff }): string =>
            `${editLabel} ${record ? `"${record.name}"` : "..."}`
          }
          to={({ record }: { record?: RaRecord }): string =>
            record
              ? createPath({
                  resource: "Tariff",
                  id: record.id,
                  type: "edit",
                })
              : ""
          }
        />
        <BreadcrumbItem
          name="create"
          label={createLabel}
          to="/Tariff/create"
        />
      </BreadcrumbItem>
      <BreadcrumbItem
        name="sales.car_type"
        label={translate("resources.CarType.name", 2)}
        to="/CarType"
      >
        <BreadcrumbItem
          name="edit"
          label={({ record }: { record?: Tariff }): string =>
            `${editLabel} ${record ? `"${record.name}"` : "..."}`
          }
          to={({ record }: { record?: RaRecord }): string =>
            record
              ? createPath({
                  resource: "CarType",
                  id: record.id,
                  type: "edit",
                })
              : ""
          }
        />
        <BreadcrumbItem
          name="create"
          label={createLabel}
          to="/CarType/create"
        />
      </BreadcrumbItem>
      <BreadcrumbItem
        name="sales.additional_service"
        label={translate("resources.AdditionalService.name", 2)}
        to="/AdditionalService"
      >
        <BreadcrumbItem
          name="edit"
          label={({ record }: { record?: Tariff }): string =>
            `${editLabel} ${record ? `"${record.name}"` : "..."}`
          }
          to={({ record }: { record?: RaRecord }): string =>
            record
              ? createPath({
                  resource: "AdditionalService",
                  id: record.id,
                  type: "edit",
                })
              : ""
          }
        />
        <BreadcrumbItem
          name="create"
          label={createLabel}
          to="/AdditionalService/create"
        />
      </BreadcrumbItem>
      <BreadcrumbItem
        name="cars.cars"
        label={translate("resources.PartnerCar.name", 2)}
        to="/PartnerCar"
      >
        <BreadcrumbItem
          name="edit"
          label={({ record }: { record?: RaRecord }): string =>
            `${editLabel} ${record ? `"${record.name}"` : "..."}`
          }
          to={({ record }: { record?: RaRecord }): string =>
            record
              ? createPath({
                  resource: "PartnerCar",
                  id: record.id,
                  type: "edit",
                })
              : ""
          }
        />
        <BreadcrumbItem
          name="create"
          label={createLabel}
          to="/PartnerCar/create"
        />
      </BreadcrumbItem>
      <BreadcrumbItem
        name="maintenance.maintenance"
        label={translate("resources.Maintenance.name", 2)}
        to="/Maintenance"
      >
        <BreadcrumbItem
          name="edit"
          label={({ record }: { record?: RaRecord }): string =>
            `${editLabel} ${record ? `"${record.numStr}"` : "..."}`
          }
          to={({ record }: { record?: RaRecord }): string =>
            record
              ? createPath({
                  resource: "Maintenance",
                  id: record.id,
                  type: "edit",
                })
              : ""
          }
        />
        <BreadcrumbItem
          name="create"
          label={createLabel}
          to="/Maintenance/create"
        />
      </BreadcrumbItem>
      <BreadcrumbItem
        name="maintenance.maintenance_item"
        label={translate("resources.MaintenanceItem.name", 2)}
        to="/MaintenanceItem"
      >
        <BreadcrumbItem
          name="edit"
          label={({ record }: { record?: RaRecord }): string =>
            `${editLabel} ${record ? `"${record.name}"` : "..."}`
          }
          to={({ record }: { record?: RaRecord }): string =>
            record
              ? createPath({
                  resource: "MaintenanceItem",
                  id: record.id,
                  type: "edit",
                })
              : ""
          }
        />
        <BreadcrumbItem
          name="create"
          label={createLabel}
          to="/MaintenanceItem/create"
        />
      </BreadcrumbItem>
      <BreadcrumbItem
        name="Partner"
        label={translate("resources.Partner.name", 2)}
        to="/Partner"
      >
        <BreadcrumbItem
          name="edit"
          label={({ record }: { record?: RaRecord }): string =>
            `${editLabel} ${record ? `"${record.fullName}"` : "..."}`
          }
          to={({ record }: { record?: RaRecord }): string =>
            record
              ? createPath({
                  resource: "Partner",
                  id: record.id,
                  type: "edit",
                })
              : ""
          }
        />
        <BreadcrumbItem
          name="create"
          label={createLabel}
          to="/Partner/create"
        />
      </BreadcrumbItem>
      <BreadcrumbItem
        name="Profile"
        label={translate("pos.menu.profile.title")}
        to="/Profile"
      />
      <BreadcrumbItem
        name="cars.report.insurance"
        label={translate("pos.reports.cars.insurance.title")}
        to="/Profile"
      />
      <BreadcrumbItem
        name="cars.report.inspection"
        label={translate("pos.reports.cars.inspection.title")}
        to="/Profile"
      />
      <BreadcrumbItem
        name="Company"
        label={translate("pos.menu.company.title")}
        to="/Company"
      />
      <BreadcrumbItem
        name="DocTemplate"
        label={translate("resources.DocTemplate.name", 2)}
        to="/DocTemplate"
      >
        <BreadcrumbItem
          name="edit"
          label={({ record }: { record?: DocTemplate }): string =>
            `${editLabel} ${record ? `"${record.name}"` : "..."}`
          }
          to={({ record }: { record?: RaRecord }): string =>
            record
              ? createPath({
                  resource: "DocTemplate",
                  id: record.id,
                  type: "edit",
                })
              : ""
          }
        />
        <BreadcrumbItem
          name="create"
          label={createLabel}
          to="/DocTemplate/create"
        />
      </BreadcrumbItem>
    </Breadcrumb>
  );
};

export default CustomBreadcrumb;
