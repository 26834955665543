// @ts-nocheck
import UsersIcon from "@mui/icons-material/Person";

import UserList from "./UserList";
import UserEdit from "./UserEdit";
import UsersShow from "./UserShow";
import { DataProvider, RaRecord } from "react-admin";
import { _Role, _User } from "src/types";

import Parse from "../parse/parse";

const resource = {
  list: UserList,
  edit: UserEdit,
  show: UsersShow,
  icon: UsersIcon,
  recordRepresentation: "lastname"
};

export default resource;

export const usersLifecycleCallbacks = {
  resource: "_User",

  afterRead: async (record: _User, dataProvider: DataProvider) => {
    return record;
  },
};
