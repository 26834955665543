import {
  ArrayField,
  Datagrid,
  FunctionField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  TextField,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { CarEquipment, _User } from "../types";
import { Card, CardContent, Grid, Typography, Box } from "@mui/material";
import dayjs from "dayjs";
import { AuthorField } from "../shared/AuthorField";

const CarEquipmentExpandShowMobile = () => {
  const record = useRecordContext<CarEquipment>();
  const translate = useTranslate();
  if (!record) return null;
  return (
    <Card sx={{ margin: 0, p: 0 }}>
      <CardContent sx={{ margin: 0, p: 0 }}>
        <Typography variant="h6" gutterBottom>
          Список комплектующих
        </Typography>
        <ArrayField source="equipment">
          <Datagrid bulkActionButtons={false}>
            <ReferenceField
              source="item"
              reference="CarEquipmentItem"
              label={translate("resources.CarEquipmentItem.fields.name")}
            >
              <TextField source="name" />
            </ReferenceField>
            <NumberField
              source="amount"
              label={translate("resources.CarEquipmentItem.fields.amount")}
            />
          </Datagrid>
        </ArrayField>
        <Box height={20}>&nbsp;</Box>
        <AuthorField source="author" />
      </CardContent>
    </Card>
  );
};

export default CarEquipmentExpandShowMobile;
