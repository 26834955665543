import CarIcon from "@mui/icons-material/CarRental";

import CarsList from "./CarsList";
import CarsEdit from "./CarsEdit";
import CarsCreate from "./CarsCreate";
import { AutoBrand, AutoModel, CarType, City, ParsePointer, PartnerCar } from "../types";
import {
  DataProvider,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetOneResult,
  RaRecord,
  ResourceCallbacks,
  UpdateParams,
} from "react-admin";
import { ParseDataProvider } from "../data-provider";
import CarShow from "./CarShow";
import {
  differenceInCalendarDays,
} from "date-fns";

const partnerCarResource = {
  list: CarsList,
  edit: CarsEdit,
  create: CarsCreate,
  // show: CarShow,
  icon: CarIcon,
  recordRepresentation: "name"
};

export default partnerCarResource;

export const carsLifecycleCallbacks = {
  resource: "PartnerCar",

   afterRead: async (record: PartnerCar, dataProvider: ParseDataProvider) => {
    const daysToOSAGOOut = record.osagoDate ? differenceInCalendarDays(new Date(record.osagoDate), new Date()) : 0;
    const daysToKASKOOut = record.kaskoDate ? differenceInCalendarDays(new Date(record.kaskoDate), new Date()) : 0;
    const daysToTOOut = record.technicalInspectionUntil ? differenceInCalendarDays(new Date(record.technicalInspectionUntil), new Date()) : 0;
    return {
      ...record,
      city: record.city?.id,
      brand: record.brand?.id,
      model: record.model?.id,
      carType: record.carType?.id,
      author: record.author?.id,
      daysToOSAGOOut,
      daysToKASKOOut,
      daysToTOOut,
    };
  },

  beforeSave: async (data: any, dataProvider: ParseDataProvider) => {
    data.brand = new ParsePointer("AutoBrand", data.brand);
    data.city = new ParsePointer("City", data.city);
    data.model = new ParsePointer("AutoModel", data.model);
    data.carType = new ParsePointer("CarType", data.carType);
    if (data.purchaseDate) {
      data.purchaseDate = new Date(data.purchaseDate);
    }
    if (data.osagoDate) {
      data.osagoDate = new Date(data.osagoDate);
    }
    if (data.kaskoDate) {
      data.kaskoDate = new Date(data.kaskoDate);
    }
    if (data.registrationDocDate) {
      data.registrationDocDate = new Date(data.registrationDocDate);
    }
    if (data.registrationDate) {
      data.registrationDate = new Date(data.registrationDate);
    }
    if (data.passportDate) {
      data.passportDate = new Date(data.passportDate);
    }
    if (data.technicalInspectionUntil) {
      data.technicalInspectionUntil = new Date(data.technicalInspectionUntil);
    }
    if (data.passportDigitalDate) {
      data.passportDigitalDate = new Date(data.passportDigitalDate);
    }
    if (data.kaskoStartDate) {
      data.kaskoStartDate = new Date(data.kaskoStartDate);
    }
    if (data.osagoStartDate) {
      data.osagoStartDate = new Date(data.osagoStartDate);
    }
    if (data.purchaseDate) {
      data.purchaseDate = new Date(data.purchaseDate);
    }
    delete data.daysToOSAGOOut;
    delete data.daysToKASKOOut;
    delete data.daysToTOOut;
    return data;
  },

};
