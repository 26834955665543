import {
  BooleanInput,
  Edit,
  FileField,
  FileInput,
  SimpleForm,
  TextField,
  TextInput,
  required,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { DocTemplate } from "../types";
import { Box, Grid } from "@mui/material";

const DocTemplateEdit = () => {
  const translate = useTranslate();

  return (
    <Edit>
      <SimpleForm>
        <TextInput
          source="name"
          validate={required()}
          fullWidth
          label={translate("resources.DocTemplate.fields.name")}
        />
        <FileInput
          source="file"
          validate={required()}
          fullWidth
          accept=".doc,.docx"
          label={translate("resources.DocTemplate.fields.file")}
        >
          <FileField source="_url" src="_url" title="_name" target="_blank" />
        </FileInput>
        <Grid container width={{ xs: "100%" }} spacing={2}>
          <Grid item xs={12} md={12}>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box mr={{ xs: 0, sm: "0.5em" }}>
                <BooleanInput
                  source="isFromOrganizationToOrganization"
                  label={translate(
                    "resources.DocTemplate.fields.isFromOrganizationToOrganization"
                  )}
                />
              </Box>
              <Box>
                <BooleanInput
                  source="isFromOrganizationToIndividual"
                  label={translate(
                    "resources.DocTemplate.fields.isFromOrganizationToIndividual"
                  )}
                />
              </Box>
            </Box>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box mr={{ xs: 0, sm: "0.5em" }}>
                <BooleanInput
                  source="isFromIPToOrganization"
                  label={translate(
                    "resources.DocTemplate.fields.isFromIPToOrganization"
                  )}
                />
              </Box>
              <Box>
                <BooleanInput
                  source="isFromIPToIndividual"
                  label={translate(
                    "resources.DocTemplate.fields.isFromIPToIndividual"
                  )}
                />
              </Box>
            </Box>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box mr={{ xs: 0, sm: "0.5em" }}>
                <BooleanInput
                  source="isIndividualToOrganization"
                  label={translate(
                    "resources.DocTemplate.fields.isIndividualToOrganization"
                  )}
                />
              </Box>
              <Box>
                <BooleanInput
                  source="isIndividualToIndividual"
                  label={translate(
                    "resources.DocTemplate.fields.isIndividualToIndividual"
                  )}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>

        <BooleanInput
          source="isReport"
          label={translate("resources.DocTemplate.fields.isReport")}
        />
      </SimpleForm>
    </Edit>
  );
};

export default DocTemplateEdit;
