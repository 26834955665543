import { Labeled, useRecordContext } from "react-admin";
import { Tariff } from "../types";

export const TariffInfoField = (props: any) => {
  const record = useRecordContext<Tariff>();
  let info = '';
  const { data } = record;
  if (data && data.length > 0) {
    const prices = data.map((item) => item.price);
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);
    info = `от ${minPrice} ₽ до ${maxPrice} ₽`;
  }
  return (
    <div>
      {info}
    </div>
  );
};

TariffInfoField.defaultProps = { label: 'Информация' };