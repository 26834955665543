import { Datagrid, DateField, List, NullableBooleanInput, SearchInput, TextField, TextInput } from "react-admin";

const partnerFilter = [
  <SearchInput source="q" alwaysOn />,
  <TextInput source="inn" alwaysOn label="resources.AccountInfo.fields.inn"/>,
  <TextInput source="lastnameAndInitials"  />,
];

export const PartnerList = () => (
  <List exporter={false} filters={partnerFilter}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" />
    </Datagrid>
  </List>
);

export default PartnerList;