import {
  AutocompleteInput,
  Filter,
  ReferenceInput,
  required,
  useTranslate,
} from "react-admin";

export const MaintenanceFilter = [
  <ReferenceInput
    source="item"
    label={"resources.MaintenanceItem.fields.name"}
    reference="MaintenanceItem"
    sort={{ field: "name", order: "ASC" }}
    alwaysOn={true}
  >
    <AutocompleteInput
      optionText="name"
      optionValue="id"
      sx={{ width: 400 }}
      label={"resources.MaintenanceItem.fields.name"}
      filterToQuery={(searchText: string) => ({ searchString: searchText })}
    />
  </ReferenceInput>,
];
