import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import {
  useRecordContext,
  useTranslate,
  useGetList,
  DateField,
  Link,
  RecordContextProvider,
  useLocaleState,
  TextField,
  NumberField,
  useReference,
} from "react-admin";
import {
  Customer,
  Order as OrderRecord,
  OrderTypeEnum,
  ParsePointer,
} from "../types";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import booking from ".";
import orders from "../orders";

const EditAside = () => {
  const record = useRecordContext<OrderRecord>();
  return (
    <Box width={400} display={{ xs: "none", lg: "block" }}>
      {record && <EventList />}
    </Box>
  );
};

const EventList = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const { referenceRecord } = useReference<Customer>({
    reference: "Customer",
    id: record?.customer,
  });

  const { data: ordersData, total: totalOrders } = useGetList<OrderRecord>(
    "Order",
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "date", order: "DESC" },
      filter: {
        customer: new ParsePointer("Customer", record.customer),
        type: OrderTypeEnum.ORDER,
      },
    }
  );

  const { data: bookingsData, total: totalBookings } =
    useGetList<OrderRecord>("Order", {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "date", order: "DESC" },
      filter: {
        customer: new ParsePointer("Customer", record.customer),
        type: OrderTypeEnum.BOOKING,
      },
    });
  const events = mixOrdersAndReviews(ordersData, bookingsData);

  return (
    <Box ml={2}>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {translate("resources.Customer.fieldGroups.identity")}
          </Typography>
          <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid item xs={6} display="flex" gap={1}>
              <Box flexGrow={1}>
                <TextField record={referenceRecord} source="fullname" />
              </Box>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography variant="h6" gutterBottom>
            {translate("resources.Customer.fieldGroups.history")}
          </Typography>
          <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid item xs={6} display="flex" gap={1}>
              <AccessTimeIcon fontSize="small" color="disabled" />
              <Box flexGrow={1}>
                <Typography variant="body2">
                  {translate("resources.Customer.fields.createdAt")}
                </Typography>
                <DateField record={referenceRecord} source="createdAt" />
              </Box>
            </Grid>
            <Grid item xs={6} display="flex" gap={1}>
              {totalOrders! > 0 && (
                <>
                  <orders.icon fontSize="small" color="disabled" />
                  <Link
                    variant="body2"
                    flexGrow={1}
                    to={{
                      pathname: "/Order",
                      search: `displayedFilters=${JSON.stringify({
                        customer: true,
                        type: OrderTypeEnum.ORDER,
                      })}&filter=${JSON.stringify({
                        customer: referenceRecord?.id,
                        type: OrderTypeEnum.ORDER,
                      })}`,
                    }}
                  >
                    {translate("resources.Customer.filters.orders", {
                      smart_count: totalOrders,
                    })}
                  </Link>
                </>
              )}
            </Grid>
            <Grid item xs={6} display="flex" gap={1}>
              {totalBookings! > 0 && (
                <>
                  <booking.icon fontSize="small" color="disabled" />
                  <Link
                    variant="body2"
                    flexGrow={1}
                    to={{
                      pathname: "/Order",
                      search: `displayedFilters=${JSON.stringify({
                        customer: true,
                        type: OrderTypeEnum.BOOKING,
                      })}&filter=${JSON.stringify({
                        customer: referenceRecord?.id,
                        type: OrderTypeEnum.BOOKING,
                      })}`,
                    }}
                  >
                    {translate("resources.Customer.filters.bookings", {
                      smart_count: totalBookings,
                    })}
                  </Link>
                </>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* {events && <Timeline events={events} />} */}
    </Box>
  );
};

interface AsideEvent {
  type: string;
  date: Date;
  data: OrderRecord;
}

const mixOrdersAndReviews = (
  orders?: OrderRecord[],
  reviews?: OrderRecord[]
): AsideEvent[] => {
  const eventsFromOrders = orders
    ? orders.map<AsideEvent>((order) => ({
        type: "order",
        date: order.start,
        data: order,
      }))
    : [];
  const eventsFromBookings = reviews
    ? reviews.map<AsideEvent>((review) => ({
        type: "booking",
        date: review.start,
        data: review,
      }))
    : [];
  const events = eventsFromOrders.concat(eventsFromBookings);
  events.sort(
    (e1, e2) => new Date(e2.date).getTime() - new Date(e1.date).getTime()
  );
  return events;
};

const Timeline = ({ events }: { events: AsideEvent[] }) => (
  <Stepper orientation="vertical" sx={{ my: 1, ml: 1.5 }}>
    {events.map((event) => (
      <Step key={`${event.type}-${event.data.id}`} expanded active completed>
        <Link
          to={`/${event.type === "order" ? "orders" : "bookings"}/${
            event.data.id
          }`}
        >
          <RecordContextProvider value={event.data}>
            <StepLabel
              icon={
                event.type === "order" ? (
                  <orders.icon color="disabled" sx={{ pl: 0.5 }} />
                ) : (
                  <booking.icon color="disabled" sx={{ pl: 0.5 }} />
                )
              }
            >
              {event.type === "order" ? <OrderTitle /> : <BookingTitle />}
            </StepLabel>
            <StepContent>
              {event.type === "order" ? <Order /> : <Booking />}
            </StepContent>
          </RecordContextProvider>
        </Link>
      </Step>
    ))}
  </Stepper>
);

const OrderTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  if (!record) return null;
  return (
    <>
      {translate("pos.events.order.title", {
        smart_count: record.basket.length,
      })}
    </>
  );
};

const Order = () => {
  const record = useRecordContext();
  const [locale] = useLocaleState();
  if (!record) return null;
  return (
    <>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        {new Date(record.date).toLocaleString(locale, {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })}
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Reference &nbsp;#{record.reference}&nbsp;-&nbsp;
        <TextField source="status" />
      </Typography>
      <Typography variant="body2" color="textSecondary">
        <NumberField
          source="total"
          options={{ style: "currency", currency: "USD" }}
        />
      </Typography>
    </>
  );
};

const BookingTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const { referenceRecord } = useReference({
    reference: "products",
    id: record?.product_id,
  });
  if (!record) return null;
  return (
    <>
      {translate("pos.events.review.title", {
        product: referenceRecord?.reference,
      })}
    </>
  );
};

const Booking = () => {
  const [locale] = useLocaleState();
  const record = useRecordContext();
  if (!record) return null;
  return (
    <>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        {new Date(record.date).toLocaleString(locale, {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })}
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        }}
        gutterBottom
      >
        {record.comment}
      </Typography>
    </>
  );
};

export default EditAside;
