import MaintenanceItemIcon from "@mui/icons-material/List";

import { MaintenanceItem, ParsePointer } from "../types";
import {
  DataProvider,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
} from "react-admin";
import { MaintenanceItemList } from "./MaintenanceItemList";
import { MaintenanceItemEdit } from "./MaintenanceItemEdit";
import { MaintenanceItemCreate } from "./MaintenanceItemCreate";

const resource = {
  list: MaintenanceItemList,
  edit: MaintenanceItemEdit,
  create: MaintenanceItemCreate,
  icon: MaintenanceItemIcon,
  recordRepresentation: "name",
};

export const maintenanceItemLifecycleCallbacks = {
  resource: "MaintenanceItem",

  afterRead: async (record: MaintenanceItem, dataProvider: DataProvider) => {
    return {
      ...record,
      author: record.author?.id,
      country: record.country?.id,
      brand: record.brand?.id,
      model: record.model?.id,
    };
  },

  beforeSave: async (data: MaintenanceItem, dataProvider: DataProvider) => {
    if (data.country) {
      data.country = new ParsePointer("Country", data.country);
    }
    if (data.brand) {
      data.brand = new ParsePointer("AutoBrand", data.brand);
    }

    if (data.model) {
      data.model = new ParsePointer("AutoModel", data.model);
    }
    return data;
  },


};

export default resource;
