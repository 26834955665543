import * as React from "react";
import { CreateButton, useRecordContext } from "react-admin";

export const CreateActButton = () => {
  const record = useRecordContext();
  return (
    <CreateButton
      resource="Act"
      state={{
        record: {
          car: record.car,
          customer: record.customer,
          orderId: record.id,
        },
      }}
    />
  );
};
