import React from "react";
import {
  ArrayInput,
  DateInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
  useCreateSuggestionContext,
  useTranslate,
} from "react-admin";
import {
  Typography,
  Box,
} from "@mui/material";
import { CreateDialog } from "@react-admin/ra-form-layout/esm/src";
import { Customer } from "../../types";
import {
  contactTypeChoises,
  customerTypeChoises,
  genderChoises,
} from "../select";

export const CreateNewCustomer = () => {
  const translate = useTranslate();
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [open, setOpen] = React.useState(true);
  const [value, setValue] = React.useState(filter || "");

  const handleClose = () => {
    setOpen(false);
    onCancel();
  };

  const onSuccess = (data: Customer) => {
    onCreate(data);
  };

  return (
    <CreateDialog
      resource="Customer"
      isOpen={open}
      close={handleClose}
      mutationOptions={{ onSuccess }}
      fullWidth
    >
      <SimpleForm defaultValues={{ phone: value }} sx={{ p: 3 }}>
        <Box display={{ xs: "block", sm: "flex" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <SelectInput
              source="type"
              validate={required()}
              label={translate("resources.Customer.fields.type")}
              fullWidth
              choices={customerTypeChoises}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              source="phone"
              validate={required()}
              fullWidth
              label={translate("resources.Customer.fields.phone")}
            />
          </Box>
        </Box>
        <Box display={{ xs: "block", sm: "flex" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput
              source="lastname"
              validate={required()}
              fullWidth
              label={translate("resources.Customer.fields.lastname")}
            />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput
              source="firstname"
              validate={required()}
              fullWidth
              label={translate("resources.Customer.fields.firstname")}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              source="surname"
              fullWidth
              label={translate("resources.Customer.fields.surname")}
            />
          </Box>
        </Box>
        <Box display={{ xs: "block", sm: "flex" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <SelectInput
              source="gender"
              validate={required()}
              label={translate("resources.Customer.fields.gender")}
              fullWidth
              choices={genderChoises}
            />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <DateInput
              source="birthDate"
              fullWidth
              helperText={false}
              label={translate("resources.Customer.fields.birthDate")}
              validate={required()}
            />
          </Box>
          <Box flex={1}>
            <TextInput
              source="birthPlace"
              fullWidth
              helperText={false}
              label={translate("resources.Customer.fields.birthPlace")}
            />
          </Box>
        </Box>
        <Box mt="1em" />

        <Typography variant="h6" gutterBottom>
          {translate("resources.Partner.fieldGroups.contacts")}
        </Typography>
        <ArrayInput source="contacts" label={false}>
          <SimpleFormIterator inline disableClear disableReordering fullWidth>
            <SelectInput
              source="type"
              label={translate("resources.Contact.fields.type")}
              validate={required()}
              sx={{ display: "flex", flex: 1 }}
              choices={contactTypeChoises}
            />
            <TextInput
              source="value"
              sx={{ display: "flex", flex: 2 }}
              validate={required()}
              label={translate("resources.Contact.fields.value")}
            />
            <TextInput
              source="description"
              validate={required()}
              sx={{ display: "flex", flex: 1 }}
              label={translate("resources.Contact.fields.description")}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </CreateDialog>
  );
};
