import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ChipField,
  Datagrid,
  DateField,
  Identifier,
  List,
  NullableBooleanInput,
  NumberField,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectArrayInput,
  SelectField,
  SelectInput,
  SingleFieldList,
  TextField,
  TextInput,
  UrlField,
} from "react-admin";
import { maintenanceStatusChoises } from "../shared/select";
import { Maintenance, MaintenanceStatusEnum } from "../types";
import { SxProps } from "@mui/material";
import { green, orange } from "@mui/material/colors";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import { AuthorField } from "../shared/AuthorField";
import { RUBFormat } from "../shared/formatUtils";
import { CarInput } from "../shared/CarInput";
import { AuthorInput } from "../shared/AuthorInput";

const rowSx =
  (selectedRow?: Identifier) =>
  (record: Maintenance): SxProps => {
    let style = {};
    if (!record) {
      return style;
    }
    if (selectedRow && selectedRow === record.id) {
      style = {
        ...style,
        backgroundColor: "action.selected",
      };
    }
    if (record.status === MaintenanceStatusEnum.DONE)
      return {
        ...style,
        borderLeftColor: green[500],
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
      };
    if (record.status === MaintenanceStatusEnum.IN_PROGRESS)
      return {
        ...style,
        borderLeftColor: orange[500],
        borderLeftWidth: 5,
        borderLeftStyle: "solid",
      };
    return style;
  };

const maintenanceFilter = [
  <SelectInput source="status" alwaysOn choices={maintenanceStatusChoises} />,
  <CarInput source="car" alwaysOn/>,
  <AuthorInput alwaysOn source="technician"/>,
  <AuthorInput alwaysOn source="author"/>,
  <ReferenceInput
    source="item"
    label="resources.MaintenanceItem.fields.name"
    reference="MaintenanceItem"
    sort={{ field: "name", order: "ASC" }}
    alwaysOn={true}
  >
    <AutocompleteInput
      optionText="name"
      optionValue="id"
      fullWidth
      label="resources.MaintenanceItem.fields.name"
      filterToQuery={(searchText: string) => ({ searchString: searchText })}
    />
  </ReferenceInput>,
];

export const MaintenanceList = () => {
  useDefineAppLocation("maintenance.maintenance");
  return (
    <List
      exporter={false}
      sort={{ field: "start", order: "DESC" }}
      filters={maintenanceFilter}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false} rowSx={rowSx()} optimized>
        <TextField source="numStr" />
        <DateField source="start" />
        <DateField source="end" />
        <ReferenceField source="car" reference="PartnerCar">
          <TextField source="name" />
        </ReferenceField>
        <NumberField
          source="amount"
          options={RUBFormat()}
        />
        <AuthorField source="author" />
      </Datagrid>
    </List>
  );
};

export default MaintenanceList;
