import { useRecordContext, useTranslate } from "react-admin";
import { _User } from "src/types";

const UserTitle = () => {
  const record = useRecordContext<_User>();
  const translate = useTranslate();
  return (
    <span>
      {translate("resources._User.name", { smart_count: 1 })}{" "}
      {record
        ? `${record.firstname} ${record.lastname} - ${record.username}`
        : ""}
    </span>
  );
};

export default UserTitle;