import {
  CreateInDialogButton,
  EditInDialogButton,
} from "@react-admin/ra-form-layout/esm/src";
import {
  ReferenceManyField,
  WithRecord,
  SimpleForm,
  TextInput,
  required,
  FileInput,
  FileField,
  Datagrid,
  TextField,
  DeleteButton,
  useTranslate,
  DateField,
  Toolbar,
  SaveButton,
} from "react-admin";
import { DocTypeEnum } from "../types";
import { Box } from "@mui/material";

export interface DocsReferenceInputProps {
  source: DocTypeEnum;
  target: string;
}

const DataGridEmpty = () => {
  const translate = useTranslate();
  return <div></div>;
  // return <div>{translate("pos.empty.files")}</div>;
};

const DocsReferenceInput = (props: DocsReferenceInputProps) => {
  const translate = useTranslate();
  return (
    <ReferenceManyField
      reference="Doc"
      target={props.target}
      filter={{ source: props.source }}
    >
      <WithRecord
        render={(record) => (
          <CreateInDialogButton
            fullWidth
            record={{
              [props.target]: record.id,
              source: props.source,
            }}
            label="Добавить файл"
          >
            <SimpleForm>
              <TextInput
                source="title"
                label={translate("resources.Doc.fields.title")}
                validate={required()}
                fullWidth
              />
              <FileInput
                source="file"
                label={translate("resources.Doc.fields.file")}
                validate={required()}
              >
                <FileField source="src" title="title" />
              </FileInput>
            </SimpleForm>
          </CreateInDialogButton>
        )}
      />
      <Datagrid bulkActionButtons={false} empty={<DataGridEmpty />}>
        <DateField source="createdAt" />
        <TextField source="title" />
        <FileField
          label={translate("resources.Doc.fields.file")}
          source="file._url"
          title="Ссылка на файл"
          target="_blank"
        />
        <Box flex={2} />
        <DeleteButton redirect={false} />
        <WithRecord
          render={(record) => {
            return (
              <EditInDialogButton
                fullWidth
                id={record.id}
                mutationMode="optimistic"
                resource="Doc"
              >
                <SimpleForm
                  toolbar={
                    <Toolbar sx={{ justifyContent: "space-between" }}>
                      <SaveButton />
                    </Toolbar>
                  }
                >
                  <TextInput
                    source="title"
                    label={translate("resources.Doc.fields.title")}
                    validate={required()}
                    fullWidth
                  />
                </SimpleForm>
              </EditInDialogButton>
            );
          }}
        />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default DocsReferenceInput;
