import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { AutoBrand } from "src/types";
import { CreateEdit } from "./CreateEdit";

export const CarTypeEdit = () => (
  <Edit>
    <CreateEdit edit/>
  </Edit>
);

export default CarTypeEdit;
