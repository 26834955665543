import React, { useMemo, CSSProperties } from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateTimeInput,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  SimpleForm,
  SimpleList,
  TextField,
  TextInput,
  useGetList,
} from "react-admin";
import { useMediaQuery, Theme, Grid, Box } from "@mui/material";
import { Calendar, CompleteCalendar } from "@react-admin/ra-calendar";
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  subMonths,
  endOfToday,
  startOfToday,
  add,
  sub,
  startOfDay,
} from "date-fns";
import Welcome from "./Welcome";
import Logo from "../layout/Logo";
import OverallCustomers from "./OverallCustomers";
import {
  CarStatusEnum,
  Customer,
  Order,
  OrderTypeEnum,
  Partner,
  PartnerCar,
  Transaction,
  TransactionDirectionEnum,
} from "../types";
import OverallCars from "./OverallCars";
import { startOfMonth } from "date-fns";
import NewCustomers from "./NewCustomers";
import rowSx from "../cars/rowSx";
import { AuthorField } from "../shared/AuthorField";
import CarsTabbedDatagrid from "./CarsTabbedList";
import { OrdersCalendar } from "./calendar/OrdersCalendar";
import OverallFreeCars from "./OverallFreeCars";
import TodayRent from "./TodayRent";
import TodayBooking from "./TodayBooking";
import TodayIncome from "./TodayIncome";
import TodayOutcome from "./TodayOutcome";
import ActionPanel from "./ActionPanel";

interface CustomerStats {
  newCustomerCount: number;
}

interface State {
  customerCount?: number;
  newCustomerCount?: number;
  carCount?: number;
  freeCarCount?: number;
  todayRent?: number;
  todayBookings?: number;
  todayIncome?: number;
  todayOutcome?: number;
}

const carsFilter = [
  <SearchInput source="name" alwaysOn />,
  <ReferenceInput
    source="carType"
    reference="CarType"
    sort={{ field: "name", order: "ASC" }}
  >
    <SelectInput source="name" />
  </ReferenceInput>,
  <ReferenceInput
    source="brand"
    reference="AutoBrand"
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput
      optionText="name"
      optionValue="id"
      filterToQuery={(searchText: string) => ({ name: searchText })}
    />
  </ReferenceInput>,
];

const Dashboard = () => {
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  const BLOCKS_SPACING = 2;

  const { data: allCustomers, total: allCustomersTotal } = useGetList<Customer>(
    "Customer",
    {
      sort: { field: "createdAt", order: "DESC" },
      pagination: { page: 1, perPage: 50 },
    }
  );

  const { data: allCars, total: allCarsTotal } = useGetList<PartnerCar>(
    "PartnerCar",
    {
      sort: { field: "date", order: "DESC" },
      pagination: { page: 1, perPage: 1000 },
    }
  );

  const { data: allOrders, total: allOrdersTotal } = useGetList<Order>(
    "Order",
    {
      sort: { field: "date", order: "DESC" },
      filter: { start_gte: startOfToday().toISOString(), type: OrderTypeEnum.ORDER },
      pagination: { page: 1, perPage: 1000 },
    }
  );

  const { data: allBookings, total: allBookingsTotal } = useGetList<Order>(
    "Order",
    {
      sort: { field: "date", order: "DESC" },
      filter: { start_gte: startOfToday().toISOString(), type: OrderTypeEnum.BOOKING },
      pagination: { page: 1, perPage: 1000 },
    }
  );

  const { data: allTransactions, total: allTransactionsTotal } =
    useGetList<Transaction>("Transaction", {
      sort: { field: "date", order: "DESC" },
      filter: { date_gte: startOfToday().toISOString() },
      pagination: { page: 1, perPage: 1000 },
    });

  const aggregation = useMemo<State>(() => {
    const aggregations = allCustomers
      ?.filter((customer) => customer.createdAt > startOfMonth(new Date()))
      .reduce(
        (stats: CustomerStats, customer) => {
          stats.newCustomerCount++;
          return stats;
        },
        { newCustomerCount: 0 }
      );
    const carAggregations = allCars?.filter(
      (car) => car.currentState == CarStatusEnum.FREE
    );

    const transactionIncomes = allTransactions
      ?.filter(
        (transaction) =>
          transaction.direction == TransactionDirectionEnum.INCOME
      )
      .reduce(
        (totalAmount, transaction) => transaction.amount + totalAmount,
        0
      );
    const transactionOutcomes = allTransactions
      ?.filter(
        (transaction) =>
          transaction.direction == TransactionDirectionEnum.OUTCOME
      )
      .reduce(
        (totalAmount, transaction) => transaction.amount + totalAmount,
        0
      );
    return {
      customerCount: allCustomersTotal,
      carCount: allCarsTotal,
      freeCarCount: carAggregations?.length ?? 0,
      newCustomerCount: aggregations?.newCustomerCount ?? 0,
      todayRent: allOrdersTotal ?? 0,
      todayBookings: allBookingsTotal ?? 0,
      todayIncome: transactionIncomes,
      todayOutcome: transactionOutcomes,
    };
  }, [allCustomers, allCars, allOrders, allBookings, allTransactions]);

  const {
    customerCount,
    carCount,
    freeCarCount,
    newCustomerCount,
    todayRent,
    todayBookings,
    todayIncome,
    todayOutcome,
  } = aggregation;

  return isXSmall ? (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Welcome />
      </Grid>
      <Grid item xs={12}>
        <OverallCustomers value={customerCount} />
      </Grid>
      <Grid item xs={12}>
        <OverallCars value={carCount} />
      </Grid>
      <Grid item xs={12}>
        <TodayIncome value={todayIncome} />
      </Grid>
      <Grid item xs={12}>
        <OrdersCalendar />
      </Grid>
      <Grid item xs={12}>
        <ActionPanel />
      </Grid>
    </Grid>
  ) : isSmall ? (
    <Grid container spacing={BLOCKS_SPACING} padding={1}>
      <Grid item xs={12}>
        <Welcome />
      </Grid>
      <Grid item xs={6}>
        <OverallCustomers value={customerCount} />
      </Grid>
      <Grid item xs={6}>
        <OverallCars value={carCount} />
      </Grid>
      <Grid item xs={6}>
        <TodayRent value={todayRent} />
      </Grid>
      <Grid item xs={6}>
        <TodayIncome value={todayIncome} />
      </Grid>
      <Grid item xs={12}>
        <ActionPanel />
      </Grid>
      <Grid item xs={12}>
        <OrdersCalendar />
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={BLOCKS_SPACING} padding={1}>
      <Grid item xs={12}>
        <Welcome />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={BLOCKS_SPACING}>
          <Grid item xs={3}>
            <OverallCustomers value={customerCount} />
          </Grid>
          <Grid item xs={3}>
            <NewCustomers value={newCustomerCount} />
          </Grid>
          <Grid item xs={3}>
            <OverallCars value={carCount} />
          </Grid>
          <Grid item xs={3}>
            <OverallFreeCars value={freeCarCount} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={BLOCKS_SPACING}>
          <Grid item xs={3}>
            <TodayRent value={todayRent} />
          </Grid>
          <Grid item xs={3}>
            <TodayBooking value={todayBookings} />
          </Grid>
          <Grid item xs={3}>
            <TodayIncome value={todayIncome} />
          </Grid>
          <Grid item xs={3}>
            <TodayOutcome value={todayOutcome} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={9}>
        <OrdersCalendar />
      </Grid>
      <Grid item xs={3}>
        <ActionPanel />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
