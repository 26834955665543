import React, { useCallback } from 'react'
import { IMaskInput } from "react-imask";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    const handleAccept = useCallback((value: any) => {
      onChange && onChange({ target: { name: props.name, value } });
    }, [onChange]);
    

    return (
      <IMaskInput
        {...other}
        mask="+7 (#00) 000-00-00"
        definitions={{
          '#': /[1-9]/,
        }}
        placeholder={'+7 (964) 123-45-67'}
        inputRef={ref}
        // onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        onAccept={(value, mask) => handleAccept(value) }
        overwrite
      />
    );
  },
);