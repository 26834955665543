import {
  AutocompleteInput,
  ReferenceInput,
  ReferenceInputProps,
  required,
} from "react-admin";

interface AuthorInputProps extends Omit<ReferenceInputProps, "reference"> {
  isRequired?: boolean;
  source: string;
}

export const AuthorInput = (props: AuthorInputProps) => {
  const { isRequired, source, ...rest } = props;
  return (
    <ReferenceInput source={source} {...rest} reference="_User">
      <AutocompleteInput
        optionText={(record) =>
          record.position
            ? `${record.lastname} ${record.firstname} - ${record.position}`
            : `${record.lastname} ${record.firstname}`
        }
        optionValue="id"
        validate={isRequired ? required() : undefined}
        filterToQuery={(searchText: string) => ({
          name: searchText,
        })}
        {...rest}
      />
    </ReferenceInput>
  );
};
