import { BooleanInput, Edit, FileField, ImageField, SimpleForm, TextInput, useRecordContext, useTranslate } from "react-admin";
import { ImageClass } from "src/types";


export const DocEdit = () => (
  <Edit title={<DocTitle />} mutationMode="pessimistic" redirect={false} >
    <SimpleForm>
      <TextInput source="title" fullWidth/>
      <FileField source="file._url" title="file._name"/>
    </SimpleForm>
  </Edit>
);

const DocTitle = () => {
  const record = useRecordContext<ImageClass>();
  const translate = useTranslate();
  return (
    <span>
      {translate("resources.Doc.name", { smart_count: 1 })}{" "}
      {record ? `"${record.name}"` : ""}
    </span>
  );
};

export default DocEdit;