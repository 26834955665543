import { Grid, Typography, Box } from "@mui/material";
import {
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  FormDataConsumer,
  Identifier,
  List,
  NumberField,
  NumberInput,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  required,
  useRecordContext,
  useTranslate,
} from "react-admin";
import {
  DocTypeEnum,
  CarPassortTypeEnum,
} from "../types";
import {
  bodyTypeChoises,
  carColorsChoises,
  carPassportTypeChoises,
  driveTypeChoises,
  engineCapacityChoises,
  fuelTypeChoises,
  transmissionTypeChoises,
  vehicleTypeChoises,
  wheelDriveChoises,
} from "../shared/select";

import { CarImagesField } from "./CarImagesField";
import { CreateEditProps } from "../interface";
import DocsReferenceInput from "../shared/DocsReferenceInput";
import ImageReferenceInput from "../shared/ImageReferenceInput";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import { MaintenanceListActions } from "./maintenanceListActions";
import { MaintenanceFilter } from "./CarMaintenanceFilter";
import CarMaintenanceExpandShow from "../maintenance/CarMaintenanceExpandShow";
import { AuthorField } from "../shared/AuthorField";
import { RUBFormat } from "../shared/formatUtils";

const filterToQuery = (searchText: string) => ({ name: searchText });

export const CreateEdit = (props: CreateEditProps) => {
  const record = useRecordContext();
  if (props.edit) {
    useDefineAppLocation("cars.cars.edit", { record });
  } else {
    useDefineAppLocation("cars.cars.create");
  }
  const translate = useTranslate();

  return (
    <TabbedForm>
      <TabbedForm.Tab label="resources.PartnerCar.tabs.summary">
        <Grid container width={{ xs: "100%", xl: "100%" }} spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              {translate("resources.PartnerCar.fieldGroups.identity")}
            </Typography>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <NumberInput source="sortNum" fullWidth />
              </Box>
              <Box flex={6} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="name" fullWidth validate={required()} />
              </Box>
            </Box>

            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <ReferenceInput
                  source="brand"
                  reference="AutoBrand"
                  sort={{ field: "name", order: "ASC" }}
                >
                  <AutocompleteInput
                    optionText="name"
                    optionValue="id"
                    validate={required()}
                    filterToQuery={filterToQuery}
                  />
                </ReferenceInput>
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <FormDataConsumer<{ brand: string }>>
                  {({
                    formData, // The whole form data
                    scopedFormData, // The data for this item of the ArrayInput
                  }) =>
                    formData.brand &&
                    formData.brand !== scopedFormData?.brand && (
                      <ReferenceInput
                        source="model"
                        reference="AutoModel"
                        filter={{ brand: formData.brand }}
                        sort={{ field: "name", order: "ASC" }}
                      >
                        <AutocompleteInput
                          optionText="name"
                          optionValue="id"
                          validate={required()}
                          filterToQuery={filterToQuery}
                        />
                      </ReferenceInput>
                    )
                  }
                </FormDataConsumer>
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <ReferenceInput source="city" reference="City">
                  <SelectInput
                    optionText="name"
                    validate={required()}
                    fullWidth
                  />
                </ReferenceInput>
              </Box>
              <Box flex={1} mr={{ xs: 0 }}>
                <TextInput
                  source="licensePlateNumber"
                  fullWidth
                  validate={required()}
                />
              </Box>
            </Box>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <ReferenceInput source="carType" reference="CarType">
                  <SelectInput
                    optionText="name"
                    validate={required()}
                    fullWidth
                  />
                </ReferenceInput>
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <SelectInput
                  source="wheelDrive"
                  validate={required()}
                  fullWidth
                  choices={wheelDriveChoises}
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <SelectInput
                  source="bodyType"
                  validate={required()}
                  fullWidth
                  choices={bodyTypeChoises}
                />
              </Box>
              <Box flex={1}>
                <SelectInput
                  source="vehicleType"
                  validate={required()}
                  fullWidth
                  choices={vehicleTypeChoises}
                />
              </Box>
            </Box>

            <Box mt="1em" />

            <Typography variant="h6" gutterBottom>
              {translate("resources.PartnerCar.fieldGroups.extra")}
            </Typography>

            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <NumberInput source="tankCapacity" fullWidth />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <AutocompleteInput
                  source="engineCapacity"
                  choices={engineCapacityChoises}
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <SelectInput
                  source="transmission"
                  validate={required()}
                  fullWidth
                  choices={transmissionTypeChoises}
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <SelectInput
                  source="drive"
                  validate={required()}
                  fullWidth
                  choices={driveTypeChoises}
                />
              </Box>
              <Box flex={1}>
                <AutocompleteInput
                  validate={required()}
                  source="bodyColor"
                  choices={carColorsChoises}
                />
              </Box>
            </Box>

            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <NumberInput source="price" fullWidth />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <DateInput
                  source="purchaseDate"
                  fullWidth
                  validate={required()}
                />
              </Box>
              <Box flex={1}>
                <TextInput source="fuelRequirement" fullWidth />
              </Box>
            </Box>
            <TextInput source="carOwner" fullWidth />

            <Box mt="1em" />

            <Typography variant="h6" gutterBottom>
              {translate("resources.PartnerCar.fieldGroups.telemetry")}
            </Typography>

            <BooleanInput source="hasTelemetry" />
            <FormDataConsumer<{ hasTelemetry: boolean }>>
              {({
                formData, // The whole form data
                scopedFormData, // The data for this item of the ArrayInput
              }) =>
                formData.hasTelemetry && (
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="telemetryProviderName"
                        fullWidth
                        validate={required()}
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <TextInput
                        source="telemetryPhoneNumber"
                        fullWidth
                        validate={required()}
                      />
                    </Box>
                    <Box flex={1}>
                      <TextInput
                        source="telemetryImei"
                        fullWidth
                        validate={required()}
                      />
                    </Box>
                  </Box>
                )
              }
            </FormDataConsumer>

            <BooleanInput source="hasProtection" />

            <FormDataConsumer<{ hasProtection: boolean }>>
              {({
                formData, // The whole form data
                scopedFormData, // The data for this item of the ArrayInput
              }) =>
                formData.hasProtection && (
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <TextInput source="protectionModel" fullWidth />
                    </Box>
                  </Box>
                )
              }
            </FormDataConsumer>

            <Box mb="2em" />
          </Grid>
          {props.edit && (
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                {translate("resources.PartnerCar.fieldGroups.images")}
              </Typography>

              <ImageReferenceInput
                target="carId"
                iterator={<CarImagesField />}
              />

              <Box mb="6em" />
            </Grid>
          )}
        </Grid>
      </TabbedForm.Tab>
      <TabbedForm.Tab label="resources.PartnerCar.tabs.insurance">
        <Grid container width={{ xs: "100%", xl: "100%" }} spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" gutterBottom>
              {translate("resources.PartnerCar.fieldGroups.osago")}
            </Typography>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="osagoNumber" fullWidth />
              </Box>
              <Box flex={1}>
                <DateInput source="osagoStartDate" fullWidth />
              </Box>
              <Box flex={1}>
                <DateInput source="osagoDate" fullWidth />
              </Box>
            </Box>

            <TextInput source="osagoCompany" fullWidth />

            {props.edit && (
              <>
                <DocsReferenceInput source={DocTypeEnum.OSAGO} target="carId" />
              </>
            )}

            <Box mt="1em" />

            <Typography variant="h6" gutterBottom>
              {translate("resources.PartnerCar.fieldGroups.kasko")}
            </Typography>

            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="kaskoNumber" fullWidth />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <DateInput source="kaskoStartDate" fullWidth />
              </Box>
              <Box flex={1} >
                <DateInput source="kaskoDate" fullWidth />
              </Box>
            </Box>

            <TextInput source="kaskoCompany" fullWidth />

            {props.edit && (
              <>
                <DocsReferenceInput source={DocTypeEnum.KASKO} target="carId" />
                <Box mb="6em" />
              </>
            )}
          </Grid>
        </Grid>
      </TabbedForm.Tab>
      <TabbedForm.Tab label="resources.PartnerCar.tabs.docs">
        <Grid container width={{ xs: "100%" }} spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" gutterBottom>
              {translate("resources.PartnerCar.fieldGroups.registrationDoc")}
            </Typography>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  source="registrationDocPart"
                  fullWidth
                  validate={required()}
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput
                  source="registrationDocNumber"
                  fullWidth
                  validate={required()}
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <DateInput
                  source="registrationDocDate"
                  fullWidth
                  validate={required()}
                />
              </Box>
              <Box flex={1}>
                <DateInput
                  source="registrationDate"
                  fullWidth
                  validate={required()}
                />
              </Box>
            </Box>
            <TextInput
              source="registrationDocDepartment"
              fullWidth
              validate={required()}
            />

            {props.edit && (
              <>
                <DocsReferenceInput
                  source={DocTypeEnum.AUTOREGDOC}
                  target="carId"
                />
              </>
            )}

            <Box mt="1em" />

            <Typography variant="h6" gutterBottom>
              {translate("resources.PartnerCar.fieldGroups.passport")}
            </Typography>

            <SelectInput
              source="passportType"
              validate={required()}
              fullWidth
              choices={carPassportTypeChoises}
            />

            <FormDataConsumer<{ passportType: CarPassortTypeEnum }>>
              {({
                formData, // The whole form data
              }) =>
                formData.passportType == CarPassortTypeEnum.PAPER ? (
                  <>
                    <Box display={{ xs: "block", sm: "flex" }}>
                      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                        <TextInput source="passportPart" fullWidth />
                      </Box>
                      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                        <TextInput source="passportNumber" fullWidth />
                      </Box>
                      <Box flex={1} mr={{ xs: 0 }}>
                        <DateInput source="passportDate" fullWidth />
                      </Box>
                    </Box>
                    <TextInput source="passportDepartment" fullWidth />
                  </>
                ) : (
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                      <TextInput source="passportDigitalNumber" fullWidth />
                    </Box>
                    <Box flex={1} mr={{ xs: 0 }}>
                      <DateInput source="passportDigitalDate" fullWidth />
                    </Box>
                  </Box>
                )
              }
            </FormDataConsumer>

            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="engineNumber" fullWidth />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="engineNumberFact" fullWidth />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <NumberInput source="enginePower" fullWidth />
              </Box>

              <Box flex={1} mr={{ xs: 0 }}>
                <SelectInput
                  source="fuelType"
                  fullWidth
                  choices={fuelTypeChoises}
                  validate={required()}
                  defaultValue={fuelTypeChoises[0].id}
                />
              </Box>
            </Box>

            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="vinNumber" fullWidth />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="chassisNumber" fullWidth />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="bodyNumber" fullWidth />
              </Box>
              <Box flex={1}>
                <NumberInput source="carYear" fullWidth validate={required()} />
              </Box>
            </Box>

            {props.edit && (
              <>
                <DocsReferenceInput
                  source={DocTypeEnum.AUTOPASSPORT}
                  target="carId"
                />
              </>
            )}

            <Box mt="1em" />

            <Typography variant="h6" gutterBottom>
              {translate(
                "resources.PartnerCar.fieldGroups.technicalInspection"
              )}
            </Typography>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="technicalInspectionNumber" fullWidth />
              </Box>
              <Box flex={1} mr={{ xs: 0 }}>
                <DateInput source="technicalInspectionUntil" fullWidth />
              </Box>
            </Box>

            <Box mt="1em" />

            {props.edit && (
              <>
                <DocsReferenceInput
                  source={DocTypeEnum.AUTOCHECKDOC}
                  target="carId"
                />
                <Box mt="6em" />
              </>
            )}
          </Grid>
        </Grid>
      </TabbedForm.Tab>
      {props.edit && (
        <TabbedForm.Tab label="resources.PartnerCar.tabs.files">
          <Grid container width={{ xs: "100%" }} spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                {translate("resources.PartnerCar.fieldGroups.files")}
              </Typography>

              <DocsReferenceInput source={DocTypeEnum.OTHER} target="carId" />
              <Box mt="6em" />
            </Grid>
          </Grid>
        </TabbedForm.Tab>
      )}
      {props.edit && (
        <TabbedForm.Tab label="resources.PartnerCar.tabs.equipment">
          <Grid container width={{ xs: "100%" }} spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                {translate("resources.PartnerCar.fieldGroups.equipment")}
              </Typography>
              {/* <ReferenceManyField
                reference="CarEquipment"
                target="car"
                sort={{ field: "date", order: "DESC" }}
                pagination={<Pagination />}
              >
                <AddEquipmentButton />
                <Datagrid
                  expand={<CarEquipmentExpandShow />}
                  bulkActionButtons={false}
                  sx={{
                    width: "100%",
                    "& .column-comment": {
                      maxWidth: "20em",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    },
                  }}
                >
                  <DateField source="date" />
                  <EditButton />
                </Datagrid>
              </ReferenceManyField> */}
              <Box mt="6em" />
            </Grid>
          </Grid>
        </TabbedForm.Tab>
      )}
      {props.edit && (
        <TabbedForm.Tab label="resources.PartnerCar.tabs.service">
          <Grid container width={{ xs: "100%" }} spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                {translate("resources.Maintenance.name", { smart_count: 2 })}
              </Typography>
              <ReferenceManyField reference="Maintenance" target="car">
                <List
                  filter={{ car: record.id }}
                  exporter={false}
                  actions={<MaintenanceListActions />}
                  filters={MaintenanceFilter}
                >
                  <Datagrid
                    bulkActionButtons={false}
                    rowClick={(id: Identifier) =>
                      `/Maintenance/${id}?car_id=${record.id}&redirect=PartnerCar`
                    }
                    expand={<CarMaintenanceExpandShow />}
                    sx={{
                      width: "100%",
                      "& .column-comment": {
                        maxWidth: "20em",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      },
                    }}
                  >
                    <TextField
                      source="numStr"
                      label={translate("resources.Maintenance.fields.numStr")}
                    />
                    <DateField
                      source="start"
                      label={translate(
                        "resources.Maintenance.fields.start"
                      )}
                    />
                    <DateField
                      source="end"
                      label={translate("resources.Maintenance.fields.end")}
                    />
                    <NumberField
                      source="amount"
                      label={translate("resources.Maintenance.fields.amount")}
                      options={RUBFormat()}
                    />
                    <AuthorField source="author" />
                  </Datagrid>
                </List>
              </ReferenceManyField>
              <Box mt="6em" />
            </Grid>
          </Grid>
        </TabbedForm.Tab>
      )}
    </TabbedForm>
  );
};
