import * as React from "react";
import { SolarLayout, SolarLayoutProps } from "@react-admin/ra-navigation";
import { useQueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  useStore,
  useRedirect,
  useDataProvider,
  useRefresh,
  CheckForApplicationUpdate,
} from "react-admin";

import { TourProvider } from "@react-admin/ra-tour";
import AppBar from "./AppBar";
import { Menu } from "./Menu";
import CustomBreadcrumb from "./Breadcrumb";
import { ProfileProvider } from "../profile/Profile";

const Layout = (props: SolarLayoutProps) => {
  // const redirect = useRedirect();
  // const dataProvider = useDataProvider();
  // const queryClient = useQueryClient();
  // const refresh = useRefresh();

  return (
    <>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      <ProfileProvider>
        <SolarLayout {...props} menu={Menu} appBar={AppBar}>
          <CustomBreadcrumb />
          {props.children}
          <CheckForApplicationUpdate />
        </SolarLayout>
      </ProfileProvider>
    </>
  );
};

export default Layout;
