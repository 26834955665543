import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  FormDataConsumer,
  List,
  NullableBooleanInput,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import { FuelTypeEnum } from "../types";
import rowSx from "./rowSx";
import {
  carColorsChoises,
  carStatusChoises,
  fuelTypeChoises,
  transmissionTypeChoises,
  wheelDriveChoises,
} from "../shared/select";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import { AuthorField } from "../shared/AuthorField";

export const CarsFilter = ({ ...props }) => {
  return (
    <Filter {...props}>
      <SearchInput source="name" alwaysOn />
      <SelectInput source="currentState" alwaysOn choices={carStatusChoises} />
      <ReferenceInput
        alwaysOn
        source="carType"
        reference="CarType"
        sort={{ field: "name", order: "ASC" }}
      >
        <SelectInput source="name" />
      </ReferenceInput>
  
      <SelectInput source="fuelType" choices={fuelTypeChoises} />
      <SelectInput source="bodyColor" choices={carColorsChoises} />
      <SelectInput source="transmission" choices={transmissionTypeChoises} />
      <SelectInput source="wheelDrive" choices={wheelDriveChoises} />
      <ReferenceInput
        source="brand"
        reference="AutoBrand"
        sort={{ field: "name", order: "ASC" }}
        alwaysOn={true}
      >
        <AutocompleteInput
          optionText="name"
          optionValue="id"
          filterToQuery={(searchText: string) => ({ name: searchText })}
        />
      </ReferenceInput>
      <FormDataConsumer source="model" alwaysOn>
        {({ formData, ...restOfTheProps }) =>
          formData.brand && (
            <ReferenceInput
              key={formData.brand}
              source="model"
              alwaysOn
              reference="AutoModel"
              filter={{ brand: formData.brand }}
              sort={{ field: "name", order: "ASC" }}
            >
              <AutocompleteInput
                optionText="name"
                optionValue="id"
                filterToQuery={(searchText: string) => ({ name: searchText })}
              />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>
      <ReferenceInput
        source="author"
        reference="_User"
        label={"pos.shared.author"}
        fullWidth
        alwaysOn
      >
        <AutocompleteInput
          optionText="lastname"
          optionValue="id"
          filterToQuery={(searchText: string) => ({ lastname: searchText })}
        />
      </ReferenceInput>
      
    </Filter>
  );
};


export const PartnerCarList = () => {
  useDefineAppLocation("cars.cars");
  return (
    <List filters={<CarsFilter/>} exporter={false}>
      <Datagrid
        rowClick="edit"
        bulkActionButtons={false}
        sort={{ field: "sortNum", order: "ASC" }}
        rowSx={rowSx()}
      >
        <NumberField source="sortNum" />
        <TextField source="name" />
        <ReferenceField source="carType" reference="CarType">
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField source="brand" reference="AutoBrand">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="model" reference="AutoModel">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="city" reference="City">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="carYear" />
        <NumberField source="mileage" />
        <AuthorField source="author" />
      </Datagrid>
    </List>
  );
};

export default PartnerCarList;
