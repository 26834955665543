import { TranslationMessages } from "react-admin";
import englishMessages from "ra-language-english";

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,
  pos: {
    search: "Search",
    configuration: "Configuration",
    language: "Language",
    theme: {
      name: "Theme",
      light: "Light",
      dark: "Dark",
    },
    dashboard: {
      monthly_revenue: "Monthly Revenue",
      month_history: "30 Day Revenue History",
      new_orders: "New Orders",
      pending_reviews: "Pending Reviews",
      all_reviews: "See all reviews",
      new_customers: "New Customers",
      all_customers: "See all customers",
      pending_orders: "Pending Orders",
      order: {
        items:
          "by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items",
      },
      welcome: {
        title: "Welcome to the react-admin e-commerce demo",
        subtitle:
          "This is the admin of an imaginary poster shop. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
        ra_button: "react-admin site",
        demo_button: "Source for this demo",
      },
    },
    menu: {
      cars: "Cars",
      sales: "Sales",
      
      customers: "Customers",
      admin: "Administration",
    },
  },
  resources: {
    City: {
      name: "City |||| Cities",
      fields: {
        name: "Name",
      },
    },
    _User: {
      name: "User |||| Users",
      fields: {
        createdAt: "Registration date",
        username: "Phone",
        firstname: "First name",
        lastname: "Last name",
        confirmed: "Is phone confirmed",
        banned: "Is banned",
        banReason: "Ban reason",
        roles: "User roles",
      },
      tabs: {
        summary: "User information",
        roles: "User roles",
      },
    },
    _Role: {
      name: "Role |||| Roles",
      fields: {
        title: "Role name",
      },
    },
    AutoBrand: {
      name: "Car brand |||| Car brands",
      fields: {
        name: "Brand name",
      },
    },
    AutoModel: {
      name: "Car model |||| Car models",
      fields: {
        brand: "Brand name",
        name: "Model name",
      },
    },
    Partner: {
      name: "Partner |||| Partners",
      fields: {
        name: "Name",
        shortName: "Short name",
        fullName: "Full name",
        positionOfHead: "Position of head",
        positionReason: "Position reason",
        lastnameAndInitials: "Last name and initials of head",
        legalAddress: "Legal address",
        actualAddress: "Actual address",
        city: "City",
      },
      fieldGroups: {
        identity: "Identity",
        accountInfo: "Account information",
        bankInfo: "Bank information",
        addressInfo: "Addresses",
        contacts: "Contacts",
      },
    },
    PartnerCar: {
      name: "Car |||| Cars",
      fields: {
        name: "Name",
        carType: "Type",
        model: "Model",
        city: "City",
        technicalInspectionNumber: "Technical inspection number",
        technicalInspectionUntil: "Technical inspection due date",
        licensePlateNumber: "Plate number"
      },
      tabs: {
        summary: "Car information",
        docs: "Documents",
        service: "Service",
      }
    },
  },
  custom: {
    auth: {
      phone: "Phone",
    },
    carType: {
      ECONOM: "Econom",
      BUSINESS: "Business",
      PREMIUM: "Premium",
    },
    bodyType: {
      SEDAN: "Sedan",
      HATCHBACK: "Hatchback",
      STATION_WAGON: "Station wagon",
      PICKUP_TRUCK: "Pickup truck",
      VAN: "Van",
      MINIVAN: "Minivan",
      CABRIOLET: "Cabrolet",
      SUV: "SUV",
      LIMOUSINE: "Limousine",
      COUPE: "Coupe",
      CROSSOVER: "Crossover",
    },
    vehicleType: {
      CAR: "Car",
      TRUCK: "Truck",
      SPECIAL: "Special",
    },
    fuelType: {
      GASOLINE: "Gasoline",
      GAS: "Gas",
      DIESEL: "Diesel",
      EV: "Electric",
    },
    contactType: {
      PHONE: "Phone",
      EMAIL: "Email",
      WEBSITE: "Website",
      VK: "VK",
      TELEGRAM: "Telegram",
      TIKTOK: "TikTok",
      OK: "OK",
      INSTAGRAM: "Instagram",
      FACEBOOK: "Facebook",
    },
  },
};

export default customEnglishMessages;
