import { SimpleForm, TextInput, useRecordContext } from "react-admin";
import { CreateEditProps } from "../interface";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";

export const CreateEdit = (props: CreateEditProps) => {
  const record = useRecordContext();
  if (props.edit) {
    useDefineAppLocation("sales.car_type.edit", { record });
  } else {
    useDefineAppLocation("sales.car_type.create");
  }
  return (
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  );
};
