import {
  AutocompleteArrayInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  Identifier,
  List,
  NullableBooleanInput,
  NumberField,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectArrayInput,
  SelectField,
  SelectInput,
  SingleFieldList,
  TextField,
  TextInput,
  UrlField,
  useRedirect,
} from "react-admin";
import { maintenanceStatusChoises } from "../shared/select";
import { Maintenance, MaintenanceStatusEnum } from "../types";
import { CreateEdit } from "./createEdit";
import { useLocation } from "react-router-dom";


export const MaintenanceCreate = (props: any) => {
  const redirect = useRedirect();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const onSuccess = (data: any) => {
    const redirectSource = query.get("redirect")
    if (redirectSource) {
      if (query.get("car_id")) {
        redirect(`/${redirectSource}/${query.get("car_id")}/5`);
      } else {
        redirect("/${redirectSource}");
      }
    } else {
      redirect("/Maintenance");
    }
  };

  return (
    <Create mutationOptions={{ onSuccess }}>
      <CreateEdit edit={false} defaultValues={{ car: query.get("car_id") }}/>
    </Create>
  );
};

export default MaintenanceCreate;
