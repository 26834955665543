import * as React from "react";
import CustomerIcon from "@mui/icons-material/GroupAdd";
import { useCreatePath, useTranslate } from "react-admin";
import { startOfMonth } from "date-fns";
import CardWithIcon from "./CardWithIcon";
import querystring from "query-string";

interface Props {
  value?: number;
}

export const newCustomerFilter = querystring.stringify({
  filter: JSON.stringify({
    createdAt_gte: startOfMonth(new Date()).toISOString(),
  }),
});

const NewCustomers = (props: Props) => {
  const { value } = props;
  const translate = useTranslate();
  const createPath = useCreatePath();
  return (
    <CardWithIcon
      to={`/Customer?${newCustomerFilter}`}
      icon={CustomerIcon}
      title={translate("pos.dashboard.new_customers")}
      subtitle={value}
    />
  );
};

export default NewCustomers;
