import {
  ArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  ReferenceInput,
  SimpleFormIterator,
  required,
  useTranslate,
} from "react-admin";
import PriceInput from "./PriceInput";
import { Tariff, AdditionalService } from "../../types";

export const AdditionalServicesInput = (props: any) => {
  const translate = useTranslate();
  return (
    <>
      <ArrayInput source="additionalData" {...props}>
        <SimpleFormIterator inline disableClear disableReordering fullWidth>
          <ReferenceInput
            source="item"
            label={translate("resources.AdditionalService.fields.name")}
            reference="AdditionalService"
            sort={{ field: "name", order: "ASC" }}
          >
            <AutocompleteInput
              optionText="name"
              optionValue="id"
              validate={required()}
              label={translate("resources.AdditionalService.fields.name")}
              filterToQuery={(searchText: string) => ({
                name: searchText,
              })}
              sx={{ display: "flex", flex: 1 }}
            />
          </ReferenceInput>
          <FormDataConsumer>
            {({ scopedFormData, getSource }) => {
              if (!scopedFormData || !getSource || !scopedFormData.item) {
                return null;
              }
              return (
                <PriceInput
                  source={getSource("price")}
                  item={scopedFormData.item}
                />
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};
