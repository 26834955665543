import { Create, SelectInput, SimpleForm, TextInput, required } from "react-admin";
import { transactionDirectionChoises } from "../shared/select";

export const TransactionAccountCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <TextInput source="name" validate={[required()]} fullWidth />
    </SimpleForm>
  </Create>
);

export default TransactionAccountCreate;