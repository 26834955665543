import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import { UserNotification, UserNotificationTypeEnum } from "../../types";
import InsuranceIcon from "@mui/icons-material/AccountBalance";
import InspectionIcon from "@mui/icons-material/NoCrash";
import MaintenanceIcon from "@mui/icons-material/Construction";
import {
  useCreatePath,
  useRedirect,
  useRefresh,
  useTranslate,
} from "react-admin";
import { useMutation } from "react-query";
import dataProvider from "../../data-provider";
import { Link } from "react-router-dom";
// ----------------------------------------------------------------------

type NotificationItemProps = {
  notification: UserNotification;
};

export default function NotificationItem({
  notification,
}: NotificationItemProps) {
  const translate = useTranslate();
  const refresh = useRefresh();
  const createPath = useCreatePath();
  const redirect = useRedirect();
  const { mutate: markAsRead, isLoading } = useMutation(() =>
    dataProvider
      .update("Notification", {
        id: notification.id,
        data: { isUnRead: false },
        previousData: { notification },
      })
      .then(() => refresh())
      .finally(() => redirect(getPathForNotification(notification)))
  );
  const renderAvatar = (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        width: 40,
        height: 40,
        borderRadius: "50%",
        bgcolor: "background.neutral",
        mr: 2,
      }}
    >
      <Box sx={{ width: 24, height: 24 }}>
        {getIconByType(notification.type)}
      </Box>
    </Stack>
  );

  const renderText = (
    <ListItemText
      disableTypography
      primary={reader(notification.description)}
      secondary={
        <Stack
          direction="row"
          alignItems="center"
          sx={{ typography: "caption", color: "text.disabled" }}
          divider={
            <Box
              sx={{
                width: 2,
                height: 2,
                bgcolor: "currentColor",
                mx: 0.5,
                borderRadius: "50%",
              }}
            />
          }
        >
          {notification.createdAt.toDateString()}
          {notification.title}
        </Stack>
      }
    />
  );

  const renderUnReadBadge = notification.isUnRead && (
    <Box
      sx={{
        top: 26,
        width: 8,
        height: 8,
        right: 20,
        borderRadius: "50%",
        bgcolor: "info.main",
        position: "absolute",
      }}
    />
  );

  const markAsReadedAction = (
    <Stack direction="row" spacing={1} sx={{ mt: 1.5 }}>
      <Button
        size="small"
        variant="contained"
        onClick={() => markAsRead()}
        disabled={isLoading}
      >
        {translate("pos.dashboard.notifications.mark_as_read")}
      </Button>
    </Stack>
  );

  return (
    <ListItemButton
      onClick={() => markAsRead()}
      // to={getPathForNotification(notification)}
      disableRipple
      sx={{
        p: 2.5,
        alignItems: "flex-start",
        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
      }}
    >
      {renderUnReadBadge}
      {renderAvatar}
      <Stack sx={{ flexGrow: 1 }}>
        {renderText}
        {notification.isUnRead}
      </Stack>
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function reader(data: string) {
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: data }}
      sx={{
        mb: 0.5,
        mr: 2,
        // "& p": { typography: "body2", m: 0 },
        // "& a": { color: "inherit", textDecoration: "none" },
        // "& strong": { typography: "subtitle2" },
      }}
    />
  );
}

function getIconByType(type: UserNotificationTypeEnum) {
  switch (type) {
    case UserNotificationTypeEnum.INSURANCE_KASKO:
    case UserNotificationTypeEnum.INSURANCE_OSAGO:
      return <InsuranceIcon />;
    case UserNotificationTypeEnum.MAINTENANCE:
      return <MaintenanceIcon />;
    case UserNotificationTypeEnum.TECHNICAL_INSPECTION:
      return <InspectionIcon />;
    default:
      return null;
  }
}

function getPathForNotification(notification: UserNotification) {
  const { payload } = notification;
  const { carId } = payload;
  switch (notification.type) {
    case UserNotificationTypeEnum.INSURANCE_KASKO:
    case UserNotificationTypeEnum.INSURANCE_OSAGO:
      return `/PartnerCar/${carId}/1`;
    case UserNotificationTypeEnum.MAINTENANCE:
      return `/PartnerCar/${carId}/5`;
    case UserNotificationTypeEnum.TECHNICAL_INSPECTION:
      return `/PartnerCar/${carId}/2`;
    default:
      return "/";
  }
}
