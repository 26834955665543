import { AutocompleteInput, ReferenceInput, ReferenceInputProps, required } from "react-admin";

interface CountryInputProps extends Omit<ReferenceInputProps, "reference">{
  isRequired?: boolean;
  source: string;
}

export const CountryInput = (props: CountryInputProps) => {
  const { isRequired, ...rest } = props;
  return (
    <ReferenceInput {...rest} reference="Country">
      <AutocompleteInput
        optionText="name"
        optionValue="id"
        validate={isRequired ? required() : undefined}
        filterToQuery={(searchText: string) => ({
          name: searchText,
        })}
      />
    </ReferenceInput>
  );
};
