import {
  List,
  Datagrid,
  TextField,
  SearchInput,
  SelectField,
} from "react-admin";
import { transactionDirectionChoises } from "../shared/select";
import { AuthorField } from "../shared/AuthorField";

export const TransactionAccountList = () => (
  <List exporter={false}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" />
      <AuthorField source="author" />
    </Datagrid>
  </List>
);

export default TransactionAccountList;
