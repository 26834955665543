import {
  AutocompleteInput,
  Datagrid,
  DatagridConfigurable,
  DateField,
  ExportButton,
  Identifier,
  List,
  NullableBooleanInput,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectColumnsButton,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";
import { FuelTypeEnum } from "../../../types";

import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import { carStatusChoises } from "../../../shared/select";
import rowSx from "../../../cars/rowSx";
import InsuranceLIstAside from "./InspectionListAside";
import { Theme, useMediaQuery } from "@mui/material";

const filterToQuery = (searchText: string) => ({ name: searchText });

const inspectionFilter = [
  <SearchInput source="name" alwaysOn />,
  <NullableBooleanInput
    label="resources.PartnerCar.filters.no_technical_inspection"
    key="technicalInspectionUntil_has"
    source="technicalInspectionUntil_has"
    defaultValue={false}
  />,
];

const InspectionActions = () => {
  return (
    <TopToolbar>
      <SelectColumnsButton preferenceKey="inspection.datagrid"/>
      <ExportButton />
    </TopToolbar>
  );
};

const InspectionList = () => {
  useDefineAppLocation("cars.report.inspection");
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  return (
    <List
      filters={isSmall ? inspectionFilter : undefined}
      resource="PartnerCar"
      storeKey="reportInspection"
      actions={<InspectionActions />}
      aside={<InsuranceLIstAside />}
    >
      <DatagridConfigurable
        rowClick={(id: Identifier) =>
          `/PartnerCar/${id}/2?redirect=/cars/report/to_inspection`
        }
        omit={["osagoCompany", "kaskoCompany", "brand", "model", "city", "carYear"]}
        bulkActionButtons={false}
        preferenceKey="inspection.datagrid"
        sort={{ field: "sortNum", order: "ASC" }}
      >
        <TextField source="name" />
        <DateField source="technicalInspectionUntil" />
        <NumberField source="daysToTOOut" sortable={false}/>
        <TextField source="technicalInspectionNumber" />
        <ReferenceField source="brand" reference="AutoBrand">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="model" reference="AutoModel">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="city" reference="City">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="carYear" />
      </DatagridConfigurable>
    </List>
  );
};

export default InspectionList;