import {
  Edit,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { transactionDirectionChoises } from "../shared/select";

export const TransactionAccountEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm>
      <TextInput source="name" validate={[required()]} fullWidth />
    </SimpleForm>
  </Edit>
);

export default TransactionAccountEdit;
