import { Typography, Box, useMediaQuery, Theme } from "@mui/material";
import {
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  FormDataConsumer,
  Identifier,
  List,
  NumberInput,
  Pagination,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  TextField,
  TextInput,
  required,
  useRecordContext,
  useTranslate,
} from "react-admin";
import {
  DocTypeEnum,
  CarPassortTypeEnum,
} from "../types";
import {
  bodyTypeChoises,
  carColorsChoises,
  carPassportTypeChoises,
  driveTypeChoises,
  engineCapacityChoises,
  fuelTypeChoises,
  transmissionTypeChoises,
  vehicleTypeChoises,
  wheelDriveChoises,
} from "../shared/select";

import { CarImagesField } from "./CarImagesField";
import { CreateEditProps } from "../interface";
import DocsReferenceInput from "../shared/DocsReferenceInput";
import ImageReferenceInput from "../shared/ImageReferenceInput";
import { AddEquipmentButton } from "./AddEquipmentButton";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import { AddMaintenanceButton } from "./AddMaintenanceButton";
import { AccordionForm } from "@react-admin/ra-form-layout";
import CarEquipmentExpandShowMobile from "../equipment/CarEquipmentExpandShowMobile";
import { MaintenanceListActions } from "./maintenanceListActions";
import { MaintenanceFilter } from "./CarMaintenanceFilter";

const filterToQuery = (searchText: string) => ({ name: searchText });



export const CreateEditMobile = (props: CreateEditProps) => {
  const record = useRecordContext();
  if (props.edit) {
    useDefineAppLocation("cars.cars.edit", { record });
  } else {
    useDefineAppLocation("cars.cars.create");
  }
  const translate = useTranslate();
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  return (
    <AccordionForm>
      <AccordionForm.Panel
        defaultExpanded
        label="resources.PartnerCar.tabs.summary"
      >
        <Typography variant="h6" gutterBottom>
          {translate("resources.PartnerCar.fieldGroups.identity")}
        </Typography>
        <NumberInput source="sortNum" fullWidth />
        <TextInput source="name" fullWidth validate={required()} />
        <ReferenceInput
          source="brand"
          reference="AutoBrand"
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteInput
            optionText="name"
            optionValue="id"
            validate={required()}
            filterToQuery={filterToQuery}
          />
        </ReferenceInput>
        <FormDataConsumer<{ brand: string }>>
          {({
            formData, // The whole form data
            scopedFormData, // The data for this item of the ArrayInput
          }) =>
            formData.brand &&
            formData.brand !== scopedFormData?.brand && (
              <ReferenceInput
                source="model"
                reference="AutoModel"
                filter={{ brand: formData.brand }}
                sort={{ field: "name", order: "ASC" }}
              >
                <AutocompleteInput
                  optionText="name"
                  optionValue="id"
                  validate={required()}
                  filterToQuery={filterToQuery}
                />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <ReferenceInput source="city" reference="City">
          <SelectInput optionText="name" validate={required()} fullWidth />
        </ReferenceInput>
        <TextInput
          source="licensePlateNumber"
          fullWidth
          validate={required()}
        />
        <ReferenceInput source="carType" reference="CarType">
          <SelectInput optionText="name" validate={required()} fullWidth />
        </ReferenceInput>
        <SelectInput
          source="wheelDrive"
          validate={required()}
          fullWidth
          choices={wheelDriveChoises}
        />
        <SelectInput
          source="bodyType"
          validate={required()}
          fullWidth
          choices={bodyTypeChoises}
        />
        <SelectInput
          source="vehicleType"
          validate={required()}
          fullWidth
          choices={vehicleTypeChoises}
        />
        <Box mt="1em" />

        <Typography variant="h6" gutterBottom>
          {translate("resources.PartnerCar.fieldGroups.extra")}
        </Typography>

        <NumberInput source="tankCapacity" fullWidth />
        <AutocompleteInput
          source="engineCapacity"
          choices={engineCapacityChoises}
        />
        <SelectInput
          source="transmission"
          validate={required()}
          fullWidth
          choices={transmissionTypeChoises}
        />
        <SelectInput
          source="drive"
          validate={required()}
          fullWidth
          choices={driveTypeChoises}
        />
        <AutocompleteInput
          source="bodyColor"
          choices={carColorsChoises}
          validate={required()}
        />
        <NumberInput source="price" fullWidth />
        <DateInput source="purchaseDate" fullWidth validate={required()} />
        <TextInput source="fuelRequirement" fullWidth />
        <TextInput source="carOwner" fullWidth />
        <Box mt="1em" />

        <Typography variant="h6" gutterBottom>
          {translate("resources.PartnerCar.fieldGroups.telemetry")}
        </Typography>

        <BooleanInput source="hasTelemetry" />
        <FormDataConsumer<{ hasTelemetry: boolean }>>
          {({
            formData, // The whole form data
            scopedFormData, // The data for this item of the ArrayInput
          }) =>
            formData.hasTelemetry && (
              <>
                <TextInput
                  source="telemetryProviderName"
                  fullWidth
                  validate={required()}
                />
                <TextInput
                  source="telemetryPhoneNumber"
                  fullWidth
                  validate={required()}
                />
                <TextInput
                  source="telemetryImei"
                  fullWidth
                  validate={required()}
                />
              </>
            )
          }
        </FormDataConsumer>

        <BooleanInput source="hasProtection" />

        <FormDataConsumer<{ hasProtection: boolean }>>
          {({
            formData, // The whole form data
            scopedFormData, // The data for this item of the ArrayInput
          }) =>
            formData.hasProtection && (
              <TextInput source="protectionModel" fullWidth />
            )
          }
        </FormDataConsumer>

        {props.edit && (
          <>
            <Typography variant="h6" gutterBottom>
              {translate("resources.PartnerCar.fieldGroups.images")}
            </Typography>

            <ImageReferenceInput target="carId" iterator={<CarImagesField />} />
          </>
        )}
      </AccordionForm.Panel>
      <AccordionForm.Panel label="resources.PartnerCar.tabs.insurance">
        <Typography variant="h6" gutterBottom>
          {translate("resources.PartnerCar.fieldGroups.osago")}
        </Typography>
        <TextInput source="osagoNumber" fullWidth />
        <DateInput source="osagoStartDate" fullWidth />
        <DateInput source="osagoDate" fullWidth />
        <TextInput source="osagoCompany" fullWidth />
        {props.edit && (
          <>
            <DocsReferenceInput source={DocTypeEnum.OSAGO} target="carId" />
          </>
        )}

        <Box mt="1em" />

        <Typography variant="h6" gutterBottom>
          {translate("resources.PartnerCar.fieldGroups.kasko")}
        </Typography>
        <TextInput source="kaskoNumber" fullWidth />
        <DateInput source="kaskoStartDate" fullWidth />
        <DateInput source="kaskoDate" fullWidth />
        <TextInput source="kaskoCompany" fullWidth />
        {props.edit && (
          <>
            <DocsReferenceInput source={DocTypeEnum.KASKO} target="carId" />
            <Box mb="6em" />
          </>
        )}
      </AccordionForm.Panel>
      <AccordionForm.Panel label="resources.PartnerCar.tabs.docs">
        <Typography variant="h6" gutterBottom>
          {translate("resources.PartnerCar.fieldGroups.registrationDoc")}
        </Typography>
        <TextInput
          source="registrationDocPart"
          fullWidth
          validate={required()}
        />
        <TextInput
          source="registrationDocNumber"
          fullWidth
          validate={required()}
        />
        <DateInput
          source="registrationDocDate"
          fullWidth
          validate={required()}
        />
        <DateInput source="registrationDate" fullWidth validate={required()} />

        <TextInput
          source="registrationDocDepartment"
          fullWidth
          validate={required()}
        />

        {props.edit && (
          <>
            <DocsReferenceInput
              source={DocTypeEnum.AUTOREGDOC}
              target="carId"
            />
          </>
        )}

        <Box mt="1em" />

        <Typography variant="h6" gutterBottom>
          {translate("resources.PartnerCar.fieldGroups.passport")}
        </Typography>

        <SelectInput
          source="passportType"
          validate={required()}
          fullWidth
          choices={carPassportTypeChoises}
        />

        <FormDataConsumer<{ passportType: CarPassortTypeEnum }>>
          {({
            formData, // The whole form data
          }) =>
            formData.passportType == CarPassortTypeEnum.PAPER ? (
              <>
                <TextInput source="passportPart" fullWidth />
                <TextInput source="passportNumber" fullWidth />
                <DateInput source="passportDate" fullWidth />
                <TextInput source="passportDepartment" fullWidth />
              </>
            ) : (
              <>
                <TextInput source="passportDigitalNumber" fullWidth />
                <DateInput source="passportDigitalDate" fullWidth />
              </>
            )
          }
        </FormDataConsumer>
        <TextInput source="engineNumber" fullWidth />
        <TextInput source="engineNumberFact" fullWidth />
        <NumberInput source="enginePower" fullWidth />
        <SelectInput
          source="fuelType"
          fullWidth
          choices={fuelTypeChoises}
          validate={required()}
          defaultValue={fuelTypeChoises[0].id}
        />
        <TextInput source="vinNumber" fullWidth />
        <TextInput source="chassisNumber" fullWidth />
        <TextInput source="bodyNumber" fullWidth />
        <NumberInput source="carYear" fullWidth validate={required()} />

        {props.edit && (
          <>
            <DocsReferenceInput
              source={DocTypeEnum.AUTOPASSPORT}
              target="carId"
            />
          </>
        )}

        <Box mt="1em" />

        <Typography variant="h6" gutterBottom>
          {translate("resources.PartnerCar.fieldGroups.technicalInspection")}
        </Typography>
        <TextInput source="technicalInspectionNumber" fullWidth />
        <DateInput source="technicalInspectionUntil" fullWidth />

        <Box mt="1em" />

        {props.edit && (
          <>
            <DocsReferenceInput
              source={DocTypeEnum.AUTOCHECKDOC}
              target="carId"
            />
            <Box mt="6em" />
          </>
        )}
      </AccordionForm.Panel>
      {props.edit && (
        <AccordionForm.Panel label="resources.PartnerCar.tabs.files">
          <Typography variant="h6" gutterBottom>
            {translate("resources.PartnerCar.fieldGroups.files")}
          </Typography>

          <DocsReferenceInput source={DocTypeEnum.OTHER} target="carId" />
          <Box mt="6em" />
        </AccordionForm.Panel>
      )}
      {props.edit && (
        <AccordionForm.Panel label="resources.PartnerCar.tabs.equipment">
          <Typography variant="h6" gutterBottom>
            {translate("resources.PartnerCar.fieldGroups.equipment")}
          </Typography>
          <ReferenceManyField
            reference="CarEquipment"
            target="car"
            sort={{ field: "date", order: "DESC" }}
            pagination={<Pagination />}
          >
            <AddEquipmentButton />
            <Datagrid
              expand={<CarEquipmentExpandShowMobile />}
              bulkActionButtons={false}
              sx={{
                width: "100%",
                "& .column-comment": {
                  maxWidth: "20em",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                },
              }}
            >
              <DateField source="date" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <Box mt="6em" />
        </AccordionForm.Panel>
      )}
      {props.edit && (
        <AccordionForm.Panel label="resources.PartnerCar.tabs.service">
          <Typography variant="h6" gutterBottom>
            {translate("resources.Maintenance.name", { smart_count: 2 })}
          </Typography>
          <ReferenceManyField
            reference="Maintenance"
            target="car"
            pagination={<Pagination />}
          >
            <AddMaintenanceButton />
            <List
              filter={{ car: record.id }}
              exporter={false}
              actions={<MaintenanceListActions />}
              filters={MaintenanceFilter}
            >
              <Datagrid
                bulkActionButtons={false}
                rowClick={(id: Identifier) =>
                  `/Maintenance/${id}?car_id=${record.id}&redirect=PartnerCar`
                }
                sx={{
                  width: "100%",
                  "& .column-comment": {
                    maxWidth: "20em",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  },
                }}
              >
                <TextField
                  source="numStr"
                  label={translate("resources.Maintenance.fields.numStr")}
                />
                <DateField
                  source="start"
                  label={translate("resources.Maintenance.fields.start")}
                />
              </Datagrid>
            </List>
          </ReferenceManyField>

          <Box mt="6em" />
        </AccordionForm.Panel>
      )}
    </AccordionForm>
  );
};
