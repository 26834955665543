import CarIcon from '@mui/icons-material/DirectionsCar';

import AutoModelList from './AutoModelList';
import AutoModelCreate from './AutoModelCreate';
import AutoModelEdit from './AutoModelEdit';
import { ParseDataProvider } from '../data-provider';
import { ParsePointer } from '../types';

const resource = {
    list: AutoModelList,
    create: AutoModelCreate,
    edit: AutoModelEdit,
    icon: CarIcon,
};

export const autoModelLifecycleCallbacks = {
    resource: "AutoModel",
  
    afterRead: async (record: any, dataProvider: ParseDataProvider) => {
      return {
        ...record,
        brand: record.brand?.id,
      };
    },
  
    beforeSave: async (data: any, dataProvider: ParseDataProvider) => {
      data.brand = new ParsePointer("AutoBrand", data.brand);
      return data;
    },
  
  
  };

export default resource;