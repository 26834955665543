import {
  Loading,
  NumberInput,
  SelectInput,
  minValue,
  required,
  useGetOne,
  useTranslate,
} from "react-admin";
import { unitTypeChoises } from "../shared/select";

const MaintenanceItemUnitTypeInput = (props: any) => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetOne("MaintenanceItem", {
    id: props.item,
  });

  return (
    <SelectInput
      source="unitType"
      label={translate("resources.MaintenanceItem.fields.unitType")}
      defaultValue={data && data.unitType ? data.unitType : 'PIECE'}
      validate={required()}
      choices={unitTypeChoises}
      isLoading={isLoading}
      {...props}
    />
  );
};

export default MaintenanceItemUnitTypeInput;
