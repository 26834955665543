import {
  Edit,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { City, ContactTypeEnum, CarEquipment, GenderEnum } from "../types";
import { CreateEdit } from "./createEdit";


export const CarEquipmentEdit = () => {
  
  return (
    <Edit mutationMode="pessimistic">
      <CreateEdit edit={true}/>
    </Edit>
  );
};

export default CarEquipmentEdit;
