import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  SelectField,
  TextField,
  useTranslate,
} from "react-admin";
import {
  AdditionalServiceRewardTypeEnum,
  AdditionalServiceTypeEnum,
  AdditionalService,
} from "../types";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import { AuthorField } from "../shared/AuthorField";
import { RUBFormat } from "../shared/formatUtils";
import { actTypeChoises } from "../shared/select";

export const ActList = () => {
  const translate = useTranslate();
  return (
    <List exporter={false} hasCreate={false}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="numStr" />
        <DateField source="date" showTime />
        <ReferenceField source="car" reference="PartnerCar">
          <TextField source="name" />
        </ReferenceField>
        <SelectField source="type" choices={actTypeChoises} />
        <AuthorField source="author" />
      </Datagrid>
    </List>
  );
};
