import {
  BooleanInput,
  DeleteWithConfirmButton,
  Edit,
  ImageField,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useRecordContext,
  useRedirect,
  useTranslate,
} from "react-admin";
import { ImageClass } from "src/types";
import ImagePoster from "./ImagePoster";
import { useLocation } from "react-router";


const EditToolbar = (props: any) => {
  const record = useRecordContext();

  <Toolbar {...props}>
    <SaveButton />
    <DeleteWithConfirmButton translateOptions={{ name: record.name }} />
  </Toolbar>;
};

export const ImageEdit = () => {
  const notify = useNotify();
  return (
    <Edit
      title={<ImageTitle />}
      mutationMode="optimistic"
    >
      <SimpleForm>
        <ImagePoster />
        <BooleanInput source="main" />
      </SimpleForm>
    </Edit>
  );
};

const ImageTitle = () => {
  const record = useRecordContext<ImageClass>();
  const translate = useTranslate();
  return (
    <span>
      {translate("resources.Image.name", { smart_count: 1 })}{" "}
      {record ? `"${record.name}"` : ""}
    </span>
  );
};

export default ImageEdit;
