import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ChipField,
  Datagrid,
  List,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceInput,
  SearchInput,
  SelectField,
  SelectInput,
  SingleFieldList,
  TextField,
} from "react-admin";
import { customerTypeChoises } from "../shared/select";
import { PhoneField } from "../shared/PhoneField";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import { AuthorField } from "../shared/AuthorField";

const filterToQuery = (searchText: string) => ({ name: searchText });

const customersFilters = [
  <SearchInput source="searchString" alwaysOn />,
  <SelectInput
    source="type"
    fullWidth
    alwaysOn
    choices={customerTypeChoises}
  />,
  <ReferenceArrayInput source="segments" reference="CustomerSegment">
    <AutocompleteArrayInput
      label="resources.Customer.fields.segments"
      filterToQuery={filterToQuery}
      optionText="name"
      optionValue="id"
    />
  </ReferenceArrayInput>,
  <ReferenceInput
    source="author"
    reference="_User"
    label={"pos.shared.author"}
    fullWidth
    alwaysOn
  >
    <AutocompleteInput
      optionText="lastname"
      optionValue="id"
      filterToQuery={(searchText: string) => ({ lastname: searchText })}
    />
  </ReferenceInput>,
];

export const CustomerList = () => {
  useDefineAppLocation("sales.customers");
  return (
    <List
      filters={customersFilters}
      exporter={false}
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <PhoneField source="phone" />
        <TextField source="fullname" />
        <SelectField source="type" choices={customerTypeChoises} />
        <ReferenceArrayField source="segments" reference="CustomerSegment">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <AuthorField source="author" />
      </Datagrid>
    </List>
  );
};

export default CustomerList;
