import {
  Loading,
  NumberInput,
  minValue,
  required,
  useGetOne,
  useTranslate,
} from "react-admin";

const PriceInput = (props: any) => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetOne("AdditionalService", {
    id: props.item,
  });
  if (isLoading) {
    return <Loading />;
  }

  return (
    <NumberInput
      defaultValue={data.price}
      min={1}
      label={translate("resources.AdditionalService.fields.price")}
      validate={[required(), minValue(1)]}
      {...props}
    />
  );
};

export default PriceInput;
