import {
  ArrayField,
  Datagrid,
  FunctionField,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  TextField,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { CarEquipment, _User } from "../types";
import { Card, CardContent, Grid, Typography, Box } from "@mui/material";
import dayjs from "dayjs";
import { RUBFormat } from "../shared/formatUtils";

const CarMaintenanceExpandShow = () => {
  const record = useRecordContext<CarEquipment>();
  const translate = useTranslate();
  if (!record) return null;
  return (
    <Card sx={{ margin: "auto" }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              {translate("resources.Maintenance.fields.data")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ArrayField source="data">
              <Datagrid bulkActionButtons={false}>
                <ReferenceField
                  source="item"
                  label={translate("resources.MaintenanceItem.fields.name")}
                  reference="MaintenanceItem"
                >
                  <TextField source="name" />
                </ReferenceField>
                <TextField
                  source="shop"
                  label={translate("resources.MaintenanceItem.fields.shop")}
                />
                <NumberField
                  source="amount"
                  label={translate("resources.MaintenanceItem.fields.amount")}
                />
                <NumberField
                  source="price"
                  options={RUBFormat()}
                  label={translate("resources.MaintenanceItem.fields.price")}
                />
              </Datagrid>
            </ArrayField>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CarMaintenanceExpandShow;
