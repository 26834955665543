import {
  Badge,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  Stack,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/PersonAdd";
import { Button, useGetList, useTranslate } from "react-admin";
import NotificationIcon from "@mui/icons-material/Notifications";
import CloseIcon from "@mui/icons-material/Close";
import NotificationItem from "./NotificationItem";
import { UserNotification } from "../../types";
import Scrollbar from "../../components/scrollbar";

interface State {
  newNotificationsCount?: number;
  notifications: UserNotification[];
}

interface NotificationsStats {
  newNotifications: number;
}

export default function NotificationsPopover() {
  const translate = useTranslate();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  const [isOpened, setIsOpened] = useState(false);
  const handleClick = (opened: boolean) => {
    setIsOpened(opened);
  };

  const { data: allNotifications, total: allNotificationsTotal } =
    useGetList<UserNotification>("Notification", {
      sort: { field: "createdAt", order: "DESC" },
      pagination: { page: 1, perPage: 100 },
    });

  const aggregation = useMemo<State>(() => {
    const aggregations = allNotifications
      ?.filter((notification) => notification.isUnRead)
      .reduce(
        (stats: NotificationsStats, notification: any) => {
          stats.newNotifications++;
          return stats;
        },
        { newNotifications: 0 }
      );
    return {
      newNotificationsCount: aggregations?.newNotifications ?? 0,
      notifications: allNotifications ?? [],
    };
  }, [allNotifications]);

  const { notifications, newNotificationsCount } = aggregation;

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {notifications.map((notification: UserNotification) => (
          <NotificationItem key={notification.id} notification={notification} />
        ))}
      </List>
    </Scrollbar>
  );

  return (
    <>
      <IconButton onClick={() => handleClick(!isOpened)}>
        <Badge badgeContent={newNotificationsCount} color="error">
          <NotificationIcon />
        </Badge>
      </IconButton>
      <Box display="flex">
        <Drawer
          variant="temporary"
          open={!!isOpened}
          anchor="right"
          onClose={() => handleClick(false)}
          sx={{ zIndex: 100 }}
          PaperProps={{
            sx: { width: 1, maxWidth: 420 },
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              py: 2,
              pl: 2.5,
              pr: 1,
              minHeight: 68,
              marginTop: isSmall ? 7 : 0,
            }}
          >
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {translate("pos.dashboard.notifications.title")}
            </Typography>
            <IconButton onClick={() => handleClick(false)}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider />
          {renderList}
        </Drawer>
      </Box>
    </>
  );
}
