import {
  Datagrid,
  DateField,
  Edit,
  ReferenceManyField,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  required,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { ReferenceManyInput } from "@react-admin/ra-relationships";

import { AutoBrand } from "src/types";

export const AutoBrandEdit = () => (
  <Edit title={<AutoBrandTitle />} mutationMode="optimistic">
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

const AutoBrandTitle = () => {
  const record = useRecordContext<AutoBrand>();
  const translate = useTranslate();
  return (
    <span>
      {translate("resources.AutoBrand.name", { smart_count: 1 })}{" "}
      {record ? `"${record.name}"` : ""}
    </span>
  );
};

export default AutoBrandEdit;
