import * as React from "react";
import { Avatar, SxProps } from "@mui/material";
import { FieldProps, useRecordContext } from "react-admin";
import { Customer, _User } from "../types";

interface Props extends FieldProps<_User> {
  sx?: SxProps;
  size?: string;
}

const AvatarField = ({ size = "25", sx }: Props) => {
  const record = useRecordContext<_User>();
  const src = record?.avatar?.url();
  if (!record) return null;
  return (
    <Avatar
      src={src}
      style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
      sx={sx}
      alt={`${record.firstname} ${record.lastname}`}
    />
  );
};

export default AvatarField;
