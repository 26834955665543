import * as React from "react";
import {
  Labeled,
  useCreatePath,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { Link } from "react-router-dom";

const DocUrlField = (props: any) => {
  const record = useRecordContext(props);
  const translate = useTranslate();
  const createPath = useCreatePath();

  if (record.payload == null) {
    return null;
  }

  let link = "";
  let value = "";
  let docType = "";
  
  if (record.payload.orderId) {
    link = createPath({
      resource: "Order",
      id: record.payload.orderId,
      type: "edit",
    });
    docType = translate("resources.Order.name", {
      smart_count: 1,
    })
    value = `${docType} ${record.payload.orderId}`;
  }

  return (
    <Labeled label={docType}>
      <Link to={link}>{value}</Link>
    </Labeled>
  );
};

export default DocUrlField;
