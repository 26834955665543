import OrderIcon from '@mui/icons-material/Handshake';

import { DataProvider, GetManyParams, GetManyReferenceParams, GetOneParams } from "react-admin";
import { Order, ParsePointer } from '../types';
import OrderCreate from './OrderCreate';
import OrderList from './OrderLIst';
import OrderEdit from './OrderEdit';

const resource = {
  list: OrderList,
  edit: OrderEdit,
  create: OrderCreate,
  icon: OrderIcon,
  recordRepresentation: "numStr"
};

export const orderLifecycleCallbacks = {
  resource: "Order",

  afterRead: async (record: Order, dataProvider: DataProvider) => {
    return {
      ...record,
      car: record.car?.id,
      manager: record.manager?.id,
      customer: record.customer?.id,
      tariff: record.tariff?.id,
      author: record.author?.id,
    };
  },

  beforeSave: async (data: any, dataProvider: DataProvider) => {
    data.car = new ParsePointer("PartnerCar", data.car);
    data.manager = new ParsePointer("_User", data.manager);
    data.customer = new ParsePointer("Customer", data.customer);
    data.tariff = new ParsePointer('Tariff', data.tariff);
    if (data.start) {
      data.start = new Date(data.start);
    }
    if (data.end) {
      data.end = new Date(data.end);
    }
    
    return data;
  },


};

export default resource;