import {
  AutocompleteInput,
  ReferenceInput,
  ReferenceInputProps,
  required,
} from "react-admin";

type CarInputProps = Omit<ReferenceInputProps, "reference"> & {
  isRequired?: boolean;
  source: string;
};

export const CarInput = (props: CarInputProps) => {
  const { isRequired, source, ...rest } = props;
  return (
    <ReferenceInput
      reference="PartnerCar"
      sort={{ field: "name", order: "ASC" }}
      source={source}
      {...rest}
    >
      <AutocompleteInput
        optionText="name"
        optionValue="id"
        validate={isRequired ? required() : undefined}
        filterToQuery={(searchText: string) => ({
          name: searchText,
        })}
        {...rest}
      />
    </ReferenceInput>
  );
};
