import { CreateInDialogButton } from "@react-admin/ra-form-layout/esm/src";
import {
  SimpleForm,
  DateTimeInput,
  required,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  NumberInput,
  TextInput,
  List,
  Datagrid,
  TextField,
  ReferenceField,
  NumberField,
  useTranslate,
  useRecordContext,
} from "react-admin";
import { TransactionType } from "src/types";
import { AuthorField } from "../AuthorField";
import { RUBFormat } from "../formatUtils";
import { Typography } from "@mui/material";

export const PaymentsEmbededList = (props: any) => {
  const record = useRecordContext();
  const translate = useTranslate();
  return (
    <>
      <Typography variant="h6" gutterBottom>
        {translate("resources.Order.fieldGroups.payments")}
      </Typography>
      <CreateInDialogButton resource="Transaction" fullWidth>
        <SimpleForm
          resource="Transaction"
          defaultValues={{
            payload: { orderId: record.id },
            customer: record.customer,
          }}
        >
          <DateTimeInput source="date" validate={[required()]} />
          <ReferenceInput source="type" reference="TransactionType">
            <AutocompleteInput
              optionText="name"
              validate={required()}
              fullWidth
              groupBy={(option: TransactionType) =>
                translate(`custom.transactionDirection.${option.direction}`)
              }
            />
          </ReferenceInput>
          <ReferenceInput source="account" reference="TransactionAccount">
            <SelectInput optionText="name" validate={required()} fullWidth />
          </ReferenceInput>
          <NumberInput
            source="amount"
            validate={[required()]}
            fullWidth
            min={1}
            max={10000000}
          />
          <TextInput source="description" fullWidth />
        </SimpleForm>
      </CreateInDialogButton>
      <List
        filter={{ "payload.orderId": record.id }}
        exporter={false}
        resource="Transaction"
        hasCreate={false}
        title={" "}
        disableSyncWithLocation
      >
        <Datagrid bulkActionButtons={false} rowClick={"show"}>
          <TextField
            source="numStr"
            label={translate("resources.Transaction.fields.numStr")}
          />
          <ReferenceField source="type" reference="TransactionType">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="account" reference="TransactionAccount">
            <TextField source="name" />
          </ReferenceField>
          <NumberField source="amount" options={RUBFormat()} />
          <AuthorField source="author" />
        </Datagrid>
      </List>
    </>
  );
};
