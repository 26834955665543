import {
  Loading,
  ReferenceInput,
  SelectInput,
  required,
  useGetOne,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { ParsePointer, PartnerCar } from "../types";

export const TariffSelect = (props: any) => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetOne("PartnerCar", {
    id: props.car,
  });
  if (isLoading) {
    return <Loading />;
  }
  return (
    <ReferenceInput
      source="tariff"
      reference="Tariff"
      filter={{ carType: new ParsePointer("CarType", data.carType) }}
    >
      <SelectInput validate={required()} fullWidth {...props}/>
    </ReferenceInput>
  );
};
