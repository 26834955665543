import { Grid, Typography, Box } from "@mui/material";
import {
  TabbedForm,
  SelectInput,
  required,
  TextInput,
  DateInput,
  ArrayInput,
  SimpleFormIterator,
  ImageInput,
  ImageField,
  ReferenceInput,
  NumberInput,
  useTranslate,
  ReferenceArrayInput,
  SelectArrayInput,
  Button,
  Datagrid,
  DeleteButton,
  FileField,
  FileInput,
  ReferenceManyField,
  SimpleForm,
  TextField,
  WithRecord,
  AutocompleteArrayInput,
  AutocompleteInput,
} from "react-admin";
import {
  CustomerTypeEnum,
  GenderEnum,
  ContactTypeEnum,
  DocTypeEnum,
  ExtraDocTypeEnum,
} from "../types";
import { RelatedImageList } from "../shared/RelatedImageList";
import UploadCarImagesButton from "../shared/UploadRelatedImagesButton";
import { CreateInDialogButton } from "@react-admin/ra-form-layout";
import { CarImagesField } from "../cars/CarImagesField";
import { CreateEditProps } from "../interface";
import DocsReferenceInput from "../shared/DocsReferenceInput";
import {
  contactTypeChoises,
  customerTypeChoises,
  extraDocTypeChoises,
  genderChoises,
} from "../shared/select";
import ImageReferenceInput from "../shared/ImageReferenceInput";
import { CreateCarEquipmentItem } from "./CreateEquipmentItem";
import { CarInput } from "../shared/CarInput";

const filterToQuery = (searchText: string) => ({ name: searchText });

export const CreateEdit = (props: CreateEditProps) => {
  const translate = useTranslate();
  return (
    <SimpleForm defaultValues={props.defaultValues}>
      <Grid container width={{ xs: "100%" }} spacing={2}>
        <Grid item xs={12} md={8}>
          <Typography variant="h6" gutterBottom>
            {translate("resources.CarEquipment.fieldGroups.identity")}
          </Typography>
          <DateInput source="date" validate={required()}/>
          <CarInput source="car" isRequired />
          <ArrayInput source="equipment">
            <SimpleFormIterator inline disableClear disableReordering fullWidth>
              <ReferenceInput
                source="item"
                label={translate("resources.CarEquipmentItem.fields.name")}
                reference="CarEquipmentItem"
                sort={{ field: "name", order: "ASC" }}
              >
                <AutocompleteInput
                  optionText="name"
                  optionValue="id"
                  validate={required()}
                  label={translate("resources.CarEquipmentItem.fields.name")}
                  filterToQuery={filterToQuery}
                  sx={{ display: "flex", flex: 1 }}
                  create={<CreateCarEquipmentItem />}
                />
              </ReferenceInput>
              <NumberInput
                source="amount"
                label={translate("resources.CarEquipmentItem.fields.amount")}
                validate={required()}
              />
            </SimpleFormIterator>
          </ArrayInput>

          <Box mb="6em" />
        </Grid>

        {props.edit && (
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              {translate("resources.CarEquipment.fieldGroups.photo")}
            </Typography>
            <ImageReferenceInput
              target="customerId"
              iterator={<CarImagesField />}
            />

            <Box mb="6em" />
          </Grid>
        )}

        <Box mt="2em" />
      </Grid>
    </SimpleForm>
  );
};
