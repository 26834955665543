import { Box, Grid, Typography } from "@mui/material";
import {
  ArrayInput,
  Create,
  Datagrid,
  DateInput,
  Edit,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SelectField,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  required,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { ContactTypeEnum, Partner } from "../types";
import { cityParser, pointerFormatter } from "../data-provider/parsers";
import { ReferenceOneInput } from '@react-admin/ra-relationships';
import { CreateEdit } from "./createEdit";

export const PartnerCreate = () => {
  
  return (
    <Create>
      <CreateEdit edit={false}/>
    </Create>
  );
};

export default PartnerCreate;
