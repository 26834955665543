import {
  List,
  Datagrid,
  TextField,
  SearchInput,
} from "react-admin";

const citiesFilter = [
  <SearchInput source="q" alwaysOn />,
];


export const CityList = () => (
<List exporter={false} filters={citiesFilter}>
  <Datagrid rowClick="edit" bulkActionButtons={false}>
    <TextField source="name" />
  </Datagrid>
</List>
);

export default CityList;