import MaintenanceIcon from "@mui/icons-material/Construction";
import {
  DataProvider,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
} from "react-admin";

import { Maintenance, ParsePointer } from "../types";
import MaintenanceList from "./MaintenanceList";
import MaintenanceEdit from "./MaintenanceEdit";
import MaintenanceCreate from "./MaintenanceCreate";

const resource = {
  list: MaintenanceList,
  edit: MaintenanceEdit,
  create: MaintenanceCreate,
  icon: MaintenanceIcon,
  recordRepresentation: "numStr",
};

export const maintenanceLifecycleCallbacks = {
  resource: "Maintenance",

  beforeGetList: async (params: GetListParams, dataProvider: DataProvider) => {
    if (params.filter.item) {
      return { ...params, meta: "containsItems" };
    }
    return params;
  },

  afterRead: async (record: Maintenance, dataProvider: DataProvider) => {
    return {
      ...record,
      car: record.car?.id,
      technician: record.technician?.id,
      author: record.author?.id,
    };
  },

  beforeSave: async (data: any, dataProvider: DataProvider) => {
    data.car = new ParsePointer("PartnerCar", data.car);
    data.technician = new ParsePointer("_User", data.technician);
    if (data.start) {
      data.start = new Date(data.start);
    }
    if (data.end) {
      data.end = new Date(data.end);
    }
    return data;
  },
};

export default resource;
