import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import { List, Datagrid, TextField, SearchInput, ReferenceField } from "react-admin";
import { AuthorField } from "../shared/AuthorField";

export const CustomerSegmentList = () => {
  useDefineAppLocation("sales.customer_segments");
  return (
    <List exporter={false}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="name" />
        <AuthorField source="author" />
      </Datagrid>
    </List>
  );
};

export default CustomerSegmentList;
