import { Grid, Box } from "@mui/material";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import {
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  NumberInput,
  useRecordContext,
} from "react-admin";
import { CreateEditProps } from "src/interface";
import {
  additionalServiceTypeChoises,
  additionalServiceRewardTypeChoises,
} from "../shared/select";

export const CreateEdit = (props: CreateEditProps) => {
  const record = useRecordContext();
  if (props.edit) {
    useDefineAppLocation("sales.additional_service.edit", { record });
  } else {
    useDefineAppLocation("sales.additional_service.create");
  }
  return (
    <SimpleForm>
      <Grid container width={{ xs: "100%", xl: "100%" }} spacing={2}>
        <Grid item xs={12} md={12}>
          <TextInput source="name" fullWidth validate={required()} />
          <Box display={{ xs: "block", sm: "flex" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <SelectInput
                source="type"
                validate={required()}
                fullWidth
                choices={additionalServiceTypeChoises}
              />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <NumberInput source="price" fullWidth validate={required()} />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <SelectInput
                source="rewardType"
                validate={required()}
                fullWidth
                choices={additionalServiceRewardTypeChoises}
              />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
              <NumberInput
                source="rewardAmount"
                fullWidth
                validate={required()}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
