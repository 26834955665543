import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import {
  Datagrid,
  DateField,
  List,
  NullableBooleanInput,
  NumberField,
  ReferenceField,
  SearchInput,
  TextField,
  TextInput,
} from "react-admin";
import { AuthorField } from "../shared/AuthorField";
import { RUBFormat } from "../shared/formatUtils";
import { TariffInfoField } from "./TariffInfoField";

export const TariffList = () => {
  useDefineAppLocation("sales.tariff");
  return (
    <List exporter={false}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="name" />
        <TariffInfoField />
        <ReferenceField source="city" reference="City">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="carType" reference="CarType">
          <TextField source="name" />
        </ReferenceField>
        <NumberField
          source="deposit"
          options={RUBFormat()}
        />
        <NumberField
          source="overrun"
          options={RUBFormat()}
        />
        <AuthorField source="author" />
      </Datagrid>
    </List>
  );
};

export default TariffList;
