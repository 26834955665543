import { TextMaskCustom } from "./phoneMaskInput";
import {
  useInput,
  FieldTitle,
  required,
  InputProps,
  InputHelperText,
  ResettableTextField,
  CommonInputProps,
  ResettableTextFieldProps,
  sanitizeInputRestProps,
} from "react-admin";
import { TextField, TextFieldProps } from "@mui/material";
import InputMask from "react-input-mask";
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

export const PhoneInput = (props: TextInputProps) => {
  const {
    className,
    defaultValue,
    label,
    format,
    helperText,
    onBlur,
    onChange,
    parse,
    resource,
    source,
    validate,
    ...rest
  } = props;

  const {
    field,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
    id,
    isRequired,
  } = useInput({
    defaultValue,
    format,
    parse,
    resource,
    source,
    type: "text",
    validate,
    onBlur,
    onChange,
    ...rest,
  });

  const renderHelperText =
    helperText !== false || ((isTouched || isSubmitted) && invalid);

  return (
    <InputMask
      mask="+7 (999) 999-99-99"
      maskPlaceholder={null}
      id={id}
      {...field}
      {...sanitizeInputRestProps(rest)}
    >
      <TextField
        type="tel"
        className={clsx("ra-input", `ra-input-${source}`, className)}
        label={
          label !== "" && label !== false ? (
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          ) : null
        }
        error={(isTouched || isSubmitted) && invalid}
        helperText={
          renderHelperText ? (
            <InputHelperText
              touched={isTouched || isSubmitted}
              error={error?.message}
              helperText={helperText}
            />
          ) : null
        }
      />
    </InputMask>
  );
};

PhoneInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.element,
  ]),
  resource: PropTypes.string,
  source: PropTypes.string,
};

export type TextInputProps = CommonInputProps &
  Omit<ResettableTextFieldProps, "label" | "helperText">;
