import { Create, SimpleForm, TextInput, required } from "react-admin";

export const AutoBrandCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <TextInput source="name" validate={[required()]} fullWidth />
    </SimpleForm>
  </Create>
);

export default AutoBrandCreate;