import { Divider, Stack, Typography } from "@mui/material";
import { useRecordContext } from "react-admin";
import { MaintenanceItem, MaintenanceItemTypeEnum } from "../types";

export const MaintenanceItemAutocompleteRenderer = () => {
  const record = useRecordContext<MaintenanceItem>();
  return (
    <span>
      {record.name} {record.sku}
      <Stack direction="row" spacing={2}>
        {record.type === MaintenanceItemTypeEnum.SPARE_PART && record.brandStr && (
          <Typography variant="body2" color="textSecondary">
            Марка: {record.brandStr}
          </Typography>
        )}
        {record.type === MaintenanceItemTypeEnum.SPARE_PART && record.modelStr && (
          <Typography variant="body2" color="textSecondary">
            Модель: {record.modelStr} 
          </Typography>
        )}
        {record.countryStr && (
          <Typography variant="body2" color="textSecondary">
            Страна: {record.countryStr} 
          </Typography>
        )}
        {(record.type === MaintenanceItemTypeEnum.SPARE_PART || record.type === MaintenanceItemTypeEnum.MATERIAL) && record.body && (
          <Typography variant="body2" color="textSecondary">
            Кузов: {record.body}
          </Typography>
        )}
      </Stack>
    </span>
  );
};
