import {
  List,
  Datagrid,
  TextField,
  SearchInput,
  DateField,
} from "react-admin";


export const DocList = () => (
<List exporter={false}>
  <Datagrid rowClick="edit" bulkActionButtons={false}>
    <DateField source="createdAt" />
    <TextField source="title" />
  </Datagrid>
</List>
);

export default DocList;