import CitiesIcon from '@mui/icons-material/LocationCity';

import CitiesList from './CityList';
import CityCreate from './CityCreate';
import CityEdit from './CityEdit';
import { City } from '../types';
import { DataProvider, GetManyReferenceParams } from 'react-admin';

const resource = {
    list: CitiesList,
    create: CityCreate,
    edit: CityEdit,
    icon: CitiesIcon,
};


export default resource;