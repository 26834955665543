import { Create, SimpleForm, TextInput, required } from "react-admin";
import { CreateEdit } from "./CreateEdit";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";

export const CarTypeCreate = () => {
  return (
    <Create redirect="list">
      <CreateEdit edit={false} />
    </Create>
  );
};

export default CarTypeCreate;
