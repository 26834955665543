import { Divider, Typography } from "@mui/material";
import {
  SolarMenu,
  SolarMenuList,
  SolarMenuLocalesItem,
  SolarMenuToggleThemeItem,
  SolarMenuUserProfileItem,
} from "@react-admin/ra-navigation";
import {
  useAuthProvider,
  useLocales,
  useThemesContext,
  useTranslate,
} from "react-admin";
import profile from "../profile";

const CustomBottomToolbar = () => {
  const translate = useTranslate();
  const authProvider = useAuthProvider();
  const { darkTheme } = useThemesContext();
  const locales = useLocales();
  const hasManyLocales = locales && locales.length > 1;
  return (
    <>
      {/* <SearchMenuItem /> */}
      <SolarMenu.LoadingIndicatorItem />
      <SolarMenu.UserItem
        subMenu={
          <SolarMenuList
            // We can't use the classes from this component here because the
            // list is displayed in the secondary sidebar, hence it is not a
            // child of the <SolarMenuUserItem> component
            sx={{
              // marginTop: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6" gutterBottom ml={1}>
              {translate(`pos.menu.profile.title`, {
                smart_count: 1,
              })}
            </Typography>
            <SolarMenu.Item
              name="profile"
              to="/Profile"
              icon={<profile.icon />}
              label={translate(`pos.menu.profile.edit`, {
                smart_count: 2,
              })}
            />
            <SolarMenu.Item
              name="company"
              to="/Company"
              icon={<profile.icon />}
              label={translate(`pos.menu.company.edit`, {
                smart_count: 2,
              })}
            />
            <Divider />
            {/* {hasManyLocales ? <SolarMenuLocalesItem /> : null} */}
            {darkTheme ? <SolarMenuToggleThemeItem /> : null}
            {authProvider != null ? <SolarMenuUserProfileItem /> : null}
          </SolarMenuList>
        }
      />
    </>
  );
};

export default CustomBottomToolbar;
