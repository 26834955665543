import {
  AutocompleteInput,
  Create,
  DateInput,
  Edit,
  FormDataConsumer,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextInput,
  required,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { BodyTypeEnum, FuelTypeEnum, PartnerCar, VehicleTypeEnum } from "../types";
import { Box, Grid, Typography } from "@mui/material";
import { CreateEdit } from "./createEdit";

export const PartnerCarCreate = () => {
  return (
    <Create redirect={'edit'}>
      <CreateEdit edit={false}/>
    </Create>
  );
};

export default PartnerCarCreate;
