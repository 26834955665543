import { Box, Grid, Typography } from "@mui/material";
import {
  ArrayInput,
  Datagrid,
  DateInput,
  Edit,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SelectField,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  required,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { ContactTypeEnum, Partner } from "../types";
import { cityParser, pointerFormatter } from "../data-provider/parsers";
import { ReferenceOneInput } from "@react-admin/ra-relationships";
import { CreateEdit } from "./createEdit";

export const PartnerEdit = () => {
  return (
    <Edit mutationMode="pessimistic" title={<PartnerTitle/>}>
      <CreateEdit edit={true}/>
    </Edit>
  );
};

const PartnerTitle = () => {
  const record = useRecordContext<Partner>();
  const translate = useTranslate();
  return (
    <span>
      {translate("resources.Partner.name", { smart_count: 1 })}{" "}
      {record ? `${record.name}` : ""}
    </span>
  );
};

export default PartnerEdit;
