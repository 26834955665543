import {
  FunctionField,
  ReferenceField,
  TextField,
  useGetIdentity,
  useTranslate,
} from "react-admin";
import { _User } from "../types";

export const AuthorField = (props: any) => {
  const translate = useTranslate();
  const { data: user, isLoading, error } = useGetIdentity();
  return (
    <ReferenceField source="author" reference="_User">
      <FunctionField
        label={translate("resources._User.name")}
        render={(record: _User) => record.id === user?.id ? translate("resources._User.fields.me") : record.position ? `${record.lastname} ${record.firstname} - ${record.position}` : `${record.lastname} ${record.firstname}`}
      />
    </ReferenceField>
  );
};
