import CustomerIcon from "@mui/icons-material/People";

import CustomerList from "./CustomerList";
import CustomerEdit from "./CustomerEdit";
import CustomerCreate from "./CustomerCreate";
import { Customer, ParsePointer } from "../types";
import {
  DataProvider,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
} from "react-admin";
import { ParseDataProvider } from "../data-provider";
import dayjs from "dayjs";

const resource = {
  list: CustomerList,
  edit: CustomerEdit,
  create: CustomerCreate,
  icon: CustomerIcon,
  recordRepresentation: "fullname",
};

export const customersLifecycleCallbacks = {
  resource: "Customer",

  beforeGetList: async (
    params: GetManyParams,
    dataProvider: ParseDataProvider
  ) => {
    return params;
  },

  afterRead: async (record: Customer, dataProvider: ParseDataProvider) => {
    const years = record.drivingLicenseDate
      ? dayjs().diff(record.drivingLicenseDate, "year")
      : 0;
    const months = record.drivingLicenseDate
      ? dayjs().diff(record.drivingLicenseDate, "month") % 12
      : "Не указано";
    let str = "";
    if (years == 0) {
      str = months == 0 ? "менее месяца" : `${months} мес`;
    } else if (years == 1) {
      str = `${years} год ${months} мес`;
    } else if (years > 1 && years < 5) {
      str = `${years} года ${months} мес`;
    } else {
      str = `${years} лет ${months} мес`;
    }
    return {
      ...record,
      passportCountry: record.passportCountry?.id,
      author: record.author?.id,
      drivingExperience: str,
    };
  },

  beforeSave: async (data: any, dataProvider: ParseDataProvider) => {
    delete data.drivingExperience;
    if (data.passportCountry) {
      data.passportCountry = new ParsePointer("Country", data.passportCountry);
    }
    if (data.birthDate) {
      data.birthDate = new Date(data.birthDate);
    }
    if (data.drivingLicenseDate) {
      data.drivingLicenseDate = new Date(data.drivingLicenseDate);
    }
    if (data.drivingLicenseDateUntil) {
      data.drivingLicenseDateUntil = new Date(data.drivingLicenseDateUntil);
    }
    if (data.passportDate) {
      data.passportDate = new Date(data.passportDate);
    }

    return data;
  },
};

export default resource;
