import {
  List,
  Datagrid,
  TextField,
  SearchInput,
  SelectField,
  DateField,
  NumberField,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  required,
  Filter,
  useTranslate,
  SelectInput,
} from "react-admin";
import { transactionDirectionChoises } from "../shared/select";
import { AuthorField } from "../shared/AuthorField";
import { RUBFormat } from "../shared/formatUtils";
import { TransactionType } from "src/types";
import { CustomerReferenceInput } from "../shared/CustomerReferenceInput";

export const TransactionsFilter = ({ ...props }) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <CustomerReferenceInput alwaysOn source={"customer"} />
      <ReferenceInput alwaysOn source="type" reference="TransactionType">
        <AutocompleteInput
          optionText="name"
          fullWidth
          groupBy={(option: TransactionType) =>
            translate(`custom.transactionDirection.${option.direction}`)
          }
        />
      </ReferenceInput>
      <ReferenceInput alwaysOn source="account" reference="TransactionAccount">
        <SelectInput optionText="name" fullWidth />
      </ReferenceInput>
      <ReferenceInput alwaysOn source="author" reference="_User">
        <AutocompleteInput
          optionText="lastname"
          optionValue="id"
          filterToQuery={(searchText: string) => ({ lastname: searchText })}
        />
      </ReferenceInput>
    </Filter>
  );
};

export const TransactionList = () => (
  <List
    exporter={false}
    filters={<TransactionsFilter />}
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <DateField source="date" showTime />
      <SelectField source="direction" choices={transactionDirectionChoises} />
      <ReferenceField source="type" reference="TransactionType">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="account" reference="TransactionAccount">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="amount" options={RUBFormat()} />
      <TextField source="description" />
      <AuthorField source="author" />
    </Datagrid>
  </List>
);

export default TransactionList;
