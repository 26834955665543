import * as React from "react";
import CarIcon from "@mui/icons-material/CarRental";
import { useTranslate } from "react-admin";

import CardWithIcon from "./CardWithIcon";

interface Props {
  value?: number;
}

const OverallCars = (props: Props) => {
  const { value } = props;
  const translate = useTranslate();
  return (
    <CardWithIcon
      to="/PartnerCar?filter={}"
      icon={CarIcon}
      title={translate("pos.dashboard.total_cars")}
      subtitle={value}
    />
  );
};

export default OverallCars;
